import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

const defaultOptions = {} as const;
export const GatsbyImageSharpFixedFragmentDoc = gql`
  fragment GatsbyImageSharpFixed on ImageSharpFixed {
    base64
    width
    height
    src
    srcSet
  }
`;
export const GatsbyImageSharpFixed_TracedSvgFragmentDoc = gql`
  fragment GatsbyImageSharpFixed_tracedSVG on ImageSharpFixed {
    tracedSVG
    width
    height
    src
    srcSet
  }
`;
export const GatsbyImageSharpFixed_WithWebpFragmentDoc = gql`
  fragment GatsbyImageSharpFixed_withWebp on ImageSharpFixed {
    base64
    width
    height
    src
    srcSet
    srcWebp
    srcSetWebp
  }
`;
export const GatsbyImageSharpFixed_WithWebp_TracedSvgFragmentDoc = gql`
  fragment GatsbyImageSharpFixed_withWebp_tracedSVG on ImageSharpFixed {
    tracedSVG
    width
    height
    src
    srcSet
    srcWebp
    srcSetWebp
  }
`;
export const GatsbyImageSharpFixed_NoBase64FragmentDoc = gql`
  fragment GatsbyImageSharpFixed_noBase64 on ImageSharpFixed {
    width
    height
    src
    srcSet
  }
`;
export const GatsbyImageSharpFixed_WithWebp_NoBase64FragmentDoc = gql`
  fragment GatsbyImageSharpFixed_withWebp_noBase64 on ImageSharpFixed {
    width
    height
    src
    srcSet
    srcWebp
    srcSetWebp
  }
`;
export const GatsbyImageSharpFluidFragmentDoc = gql`
  fragment GatsbyImageSharpFluid on ImageSharpFluid {
    base64
    aspectRatio
    src
    srcSet
    sizes
  }
`;
export const GatsbyImageSharpFluidLimitPresentationSizeFragmentDoc = gql`
  fragment GatsbyImageSharpFluidLimitPresentationSize on ImageSharpFluid {
    maxHeight: presentationHeight
    maxWidth: presentationWidth
  }
`;
export const GatsbyImageSharpFluid_TracedSvgFragmentDoc = gql`
  fragment GatsbyImageSharpFluid_tracedSVG on ImageSharpFluid {
    tracedSVG
    aspectRatio
    src
    srcSet
    sizes
  }
`;
export const GatsbyImageSharpFluid_WithWebpFragmentDoc = gql`
  fragment GatsbyImageSharpFluid_withWebp on ImageSharpFluid {
    base64
    aspectRatio
    src
    srcSet
    srcWebp
    srcSetWebp
    sizes
  }
`;
export const GatsbyImageSharpFluid_WithWebp_TracedSvgFragmentDoc = gql`
  fragment GatsbyImageSharpFluid_withWebp_tracedSVG on ImageSharpFluid {
    tracedSVG
    aspectRatio
    src
    srcSet
    srcWebp
    srcSetWebp
    sizes
  }
`;
export const GatsbyImageSharpFluid_NoBase64FragmentDoc = gql`
  fragment GatsbyImageSharpFluid_noBase64 on ImageSharpFluid {
    aspectRatio
    src
    srcSet
    sizes
  }
`;
export const GatsbyImageSharpFluid_WithWebp_NoBase64FragmentDoc = gql`
  fragment GatsbyImageSharpFluid_withWebp_noBase64 on ImageSharpFluid {
    aspectRatio
    src
    srcSet
    srcWebp
    srcSetWebp
    sizes
  }
`;
export const ImageCardPagePickerSmallFragmentDoc = gql`
  fragment ImageCardPagePickerSmall on KFH_UmbracoImageCardPagePickerComponentType {
    page {
      feed {
        featuredImage {
          localFile {
            gatsbyImage(width: 320, height: 216, layout: CONSTRAINED, cropFocus: CENTER)
          }
        }
        shortTitle
      }
      pageName
      relativeUrl
      layout {
        remoteTypeName
      }
    }
  }
`;
export const ImageCardCustomSmallFragmentDoc = gql`
  fragment ImageCardCustomSmall on KFH_UmbracoImageCardCustomRepeaterComponentType {
    tag
    title
    text
    action
    image {
      altText
      localFile {
        gatsbyImage(width: 320, height: 216, layout: CONSTRAINED, cropFocus: CENTER)
      }
    }
    link {
      url
      name
      target
    }
  }
`;
export const ImageCardCustomFragmentDoc = gql`
  fragment ImageCardCustom on KFH_UmbracoImageCardCustomRepeaterComponentType {
    tag
    title
    text
    action
    image {
      altText
      localFile {
        gatsbyImage(width: 520, height: 544, layout: CONSTRAINED, cropFocus: CENTER)
      }
    }
    link {
      url
      name
      target
    }
  }
`;
export const ImageCardPagePickerFragmentDoc = gql`
  fragment ImageCardPagePicker on KFH_UmbracoImageCardPagePickerComponentType {
    page {
      feed {
        featuredImage {
          localFile {
            gatsbyImage(width: 520, height: 544, quality: 30, layout: CONSTRAINED, cropFocus: CENTER)
          }
        }
        shortTitle
        shortDescription
      }
      pageName
      relativeUrl
      layout {
        remoteTypeName
      }
    }
  }
`;
export const ImageCardFragmentDoc = gql`
  fragment ImageCard on KFH_UmbracoImageCardComponent {
    remoteTypeName
    ...ImageCardCustom
    ...ImageCardPagePicker
  }
  ${ImageCardCustomFragmentDoc}
  ${ImageCardPagePickerFragmentDoc}
`;
export const AccordionFragmentDoc = gql`
  fragment Accordion on KFH_UmbracoAccordionComponentType {
    title
    lead
    content
    faqsAccordion
    backgroundColour
    accordionListing {
      title
      content
    }
  }
`;
export const CareersFeedFragmentDoc = gql`
  fragment CareersFeed on KFH_UmbracoCareersFeedComponentType {
    title
    backgroundColour
    jobs {
      name
      relativeUrl
      location
      employmentType
    }
  }
`;
export const CaseStudyFeedFragmentDoc = gql`
  fragment CaseStudyFeed on KFH_UmbracoCaseStudyFeedComponentType {
    title
    backgroundColour
    caseStudies {
      pageName
      relativeUrl
      feed {
        shortTitle
        featuredImage {
          altText
          localFile {
            gatsbyImage(
              width: 452
              height: 340
              layout: CONSTRAINED
              placeholder: NONE
              quality: 80
              backgroundColor: "#F5F5F5"
            )
          }
        }
      }
    }
  }
`;
export const ContactListFragmentDoc = gql`
  fragment ContactList on KFH_UmbracoContactListComponentType {
    divisions {
      name
      id
      content
      divisionAddresses {
        name
        line1
        line2
        town
        postcode
        telephone
        email
        contactName
      }
    }
  }
`;
export const LinkFragmentDoc = gql`
  fragment Link on KFH_UmbracoButtonLinkType {
    link {
      name
      target
      url
      type
    }
  }
`;
export const LinkDownloadFragmentDoc = gql`
  fragment LinkDownload on KFH_UmbracoButtonLinkDownloadType {
    file
    label
  }
`;
export const LinkDivisionFragmentDoc = gql`
  fragment LinkDivision on KFH_UmbracoButtonLinkDivisionType {
    label
    division {
      name
      id
      content
      whatsApp {
        whatsAppEnabled
        whatsAppFullUrl
      }
      divisionAddresses {
        id
        name
        line1
        line2
        town
        postcode
        telephone
        email
        contactName
        contactPosition
      }
    }
  }
`;
export const LinkVideoFragmentDoc = gql`
  fragment LinkVideo on KFH_UmbracoButtonVideoType {
    videoUrl
    label
  }
`;
export const LinkValuationFragmentDoc = gql`
  fragment LinkValuation on KFH_UmbracoButtonLinkValuationType {
    label
    valuationType
  }
`;
export const LinkFormFragmentDoc = gql`
  fragment LinkForm on KFH_UmbracoButtonLinkFormType {
    label
    formType
  }
`;
export const ButtonFragmentDoc = gql`
  fragment Button on KFH_UmbracoButton {
    remoteTypeName
    ...Link
    ...LinkDownload
    ...LinkDivision
    ...LinkVideo
    ...LinkValuation
    ...LinkForm
  }
  ${LinkFragmentDoc}
  ${LinkDownloadFragmentDoc}
  ${LinkDivisionFragmentDoc}
  ${LinkVideoFragmentDoc}
  ${LinkValuationFragmentDoc}
  ${LinkFormFragmentDoc}
`;
export const CtaCardFragmentDoc = gql`
  fragment CtaCard on KFH_UmbracoCtaCardComponentType {
    title
    lead
    backgroundColour
    button {
      ...Button
    }
    secondaryButton {
      ...Button
    }
    image {
      altText
      localFile {
        gatsbyImage(
          width: 192
          height: 192
          layout: CONSTRAINED
          placeholder: NONE
          quality: 80
          backgroundColor: "#F5F5F5"
        )
      }
    }
  }
  ${ButtonFragmentDoc}
`;
export const HtmlFragmentDoc = gql`
  fragment Html on KFH_UmbracoRichTextComponentType {
    text
    narrow
  }
`;
export const ImageCardsFragmentDoc = gql`
  fragment ImageCards on KFH_UmbracoImageCardsComponentType {
    title
    lead
    format
    backgroundColour
    hideLearnMore
    imageCards {
      ...ImageCardPagePicker
      ...ImageCardCustom
    }
  }
  ${ImageCardPagePickerFragmentDoc}
  ${ImageCardCustomFragmentDoc}
`;
export const LocationListFragmentDoc = gql`
  fragment LocationList on KFH_UmbracoLocationListComponentType {
    regions {
      id
      name
      relativeUrl
      shortDescription
      areas {
        edges {
          node {
            name
            relativeUrl
            id
          }
        }
      }
    }
  }
`;
export const ProcessFragmentDoc = gql`
  fragment Process on KFH_UmbracoProcessComponentType {
    steps {
      title
      text
      image {
        altText
        localFile {
          gatsbyImage(
            width: 256
            height: 256
            layout: CONSTRAINED
            cropFocus: CENTER
            placeholder: NONE
            quality: 80
            backgroundColor: "#F5F5F5"
          )
        }
      }
      button {
        ...Button
      }
    }
  }
  ${ButtonFragmentDoc}
`;
export const QuoteFragmentDoc = gql`
  fragment Quote on KFH_UmbracoQuoteComponentType {
    quoteText
    quoteName
    position
    company
  }
`;
export const ReportsFeedFragmentDoc = gql`
  fragment ReportsFeed on KFH_UmbracoReportsFeedComponentType {
    title
    backgroundColour
    marketReports {
      relativeUrl
      layout {
        ... on KFH_ContentMarketReportType {
          title
          year
          season
          coverImage {
            localFile {
              gatsbyImage(
                width: 452
                height: 340
                layout: CONSTRAINED
                placeholder: NONE
                quality: 80
                backgroundColor: "#F5F5F5"
              )
            }
            altText
          }
        }
      }
    }
  }
`;
export const StaffProfileFeedFragmentDoc = gql`
  fragment StaffProfileFeed on KFH_UmbracoStaffProfileFeedComponentType {
    title
    action
    backgroundColour
    division {
      name
      divisionStaffs {
        staff {
          fullName
          position
          bio
          email
          directDialNumber
          keyResponsibilities
          position
          profile
          startDate
          mediaSet {
            media {
              role
              media {
                localFile {
                  gatsbyImage(
                    width: 452
                    height: 340
                    layout: CONSTRAINED
                    placeholder: NONE
                    quality: 80
                    backgroundColor: "#F5F5F5"
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const TickListFragmentDoc = gql`
  fragment TickList on KFH_UmbracoTickListComponentType {
    title
    lead
    content
    items {
      text
    }
  }
`;
export const TrustpilotFragmentDoc = gql`
  fragment Trustpilot on KFH_UmbracoTrustPilotComponentType {
    title
    tag
  }
`;
export const StatsFragmentDoc = gql`
  fragment Stats on KFH_UmbracoStatsComponentType {
    stats {
      text
      headline
      button {
        ...Button
      }
    }
  }
  ${ButtonFragmentDoc}
`;
export const DoubleCtaCardFragmentDoc = gql`
  fragment DoubleCTACard on KFH_UmbracoDoubleCtaCardComponentType {
    ctas {
      title
      lead
      backgroundColour
      button {
        ...Button
      }
      secondaryButton {
        ...Button
      }
      image {
        altText
        localFile {
          gatsbyImage(
            width: 192
            height: 192
            layout: CONSTRAINED
            placeholder: NONE
            quality: 80
            backgroundColor: "#F5F5F5"
          )
        }
      }
    }
  }
  ${ButtonFragmentDoc}
`;
export const LinkListFragmentDoc = gql`
  fragment LinkList on KFH_UmbracoLinkListComponentType {
    title
    smallHeading
    lead
    items {
      link {
        url
        type
        target
        name
      }
    }
  }
`;
export const IssuuEmbedFragmentDoc = gql`
  fragment IssuuEmbed on KFH_UmbracoIssuuComponentType {
    issuuId
  }
`;
export const VideoFragmentDoc = gql`
  fragment Video on KFH_UmbracoVideoComponentType {
    caption
    videoUrl
    backgroundColour
  }
`;
export const ImageFragmentDoc = gql`
  fragment Image on KFH_UmbracoImageComponentType {
    caption
    image {
      altText
      localFile {
        gatsbyImage(width: 736, layout: FULL_WIDTH, placeholder: NONE, quality: 80, backgroundColor: "#F5F5F5")
      }
    }
  }
`;
export const LogosFragmentDoc = gql`
  fragment Logos on KFH_UmbracoLogoComponentType {
    title
    lead
    content
    listing {
      link
      image {
        altText
        localFile {
          gatsbyImage(
            height: 256
            width: 320
            layout: CONSTRAINED
            placeholder: NONE
            quality: 90
            backgroundColor: "#FFFFFF"
          )
        }
      }
    }
  }
`;
export const CardNavigationFragmentDoc = gql`
  fragment CardNavigation on KFH_UmbracoCardNavigationComponentType {
    cards {
      remoteTypeName
      ... on KFH_UmbracoCardNavigationCustomType {
        title
        text
        link {
          name
          url
          target
        }
        image {
          altText
          localFile {
            gatsbyImage(
              width: 512
              height: 384
              layout: CONSTRAINED
              cropFocus: CENTER
              placeholder: NONE
              quality: 80
              backgroundColor: "#F5F5F5"
            )
          }
        }
      }
      ... on KFH_UmbracoCardNavigationPagePickerType {
        label
        page {
          pageName
          relativeUrl
          feed {
            shortTitle
            shortDescription
            featuredImage {
              altText
              localFile {
                gatsbyImage(
                  width: 512
                  height: 384
                  layout: CONSTRAINED
                  cropFocus: CENTER
                  placeholder: NONE
                  quality: 80
                  backgroundColor: "#F5F5F5"
                )
              }
            }
          }
        }
      }
    }
  }
`;
export const CardCarouselFragmentDoc = gql`
  fragment CardCarousel on KFH_UmbracoCardCarouselComponentType {
    title
    button {
      ...Button
    }
    items {
      heading
      text
      image {
        altText
        localFile {
          gatsbyImage(width: 512, layout: CONSTRAINED, placeholder: NONE, quality: 80, backgroundColor: "#F5F5F5")
        }
      }
    }
  }
  ${ButtonFragmentDoc}
`;
export const PropertyGallerySmallImageFragmentDoc = gql`
  fragment PropertyGallerySmallImage on KFH_ArtirixImageType {
    caption
    largestImagePath
    width
    height
  }
`;
export const DevelopmentFeedFragmentDoc = gql`
  fragment DevelopmentFeed on KFH_UmbracoDevelopmentFeedComponentType {
    title
    backgroundColour
    developments {
      properties {
        edges {
          node {
            __typename
            relativeUrl
            address {
              streetname
              town
              outwardCode
            }
            ... on KFH_DevelopmentResalePropertyType {
              developmentName
              developmentMaxPrice
              developmentMinPrice
              plots {
                plotNumber
                propertyType
                bedrooms
              }
              images {
                developmentImages {
                  imagePath
                  largestImagePath
                  width
                  height
                  filename
                  mimeType
                  ...PropertyGallerySmallImage
                }
              }
            }
          }
        }
      }
    }
  }
  ${PropertyGallerySmallImageFragmentDoc}
`;
export const CalculatorsFragmentDoc = gql`
  fragment Calculators on KFH_UmbracoCalculatorsComponentType {
    stampDutyCalculator
    mortgageEstimatorCalculator
    leaseExtensionCalculator
    backgroundColour
  }
`;
export const CalculatorsAndImageCardFragmentDoc = gql`
  fragment CalculatorsAndImageCard on KFH_UmbracoCalculatorImageCardComponentType {
    imageCard {
      ...ImageCardPagePicker
      ...ImageCardCustom
    }
    backgroundColour
  }
  ${ImageCardPagePickerFragmentDoc}
  ${ImageCardCustomFragmentDoc}
`;
export const StandaloneButtonFragmentDoc = gql`
  fragment StandaloneButton on KFH_UmbracoStandaloneButtonComponentType {
    button {
      ...Button
    }
  }
  ${ButtonFragmentDoc}
`;
export const FormFragmentDoc = gql`
  fragment Form on KFH_UmbracoFormComponentType {
    formType
  }
`;
export const CareersNewsFeedFragmentDoc = gql`
  fragment CareersNewsFeed on KFH_UmbracoCareersNewsFeedComponentType {
    title
    backgroundColour
    careersNews {
      pageName
      relativeUrl
      publishDate
      feed {
        featuredImage {
          localFile {
            gatsbyImage(
              width: 452
              height: 340
              layout: CONSTRAINED
              placeholder: NONE
              quality: 80
              backgroundColor: "#F5F5F5"
            )
          }
        }
        shortTitle
      }
    }
  }
`;
export const NewsArticleFeedFragmentDoc = gql`
  fragment NewsArticleFeed on KFH_UmbracoNewsArticleFeedComponentType {
    title
    backgroundColour
    newsArticles {
      pageName
      relativeUrl
      publishDate
      feed {
        featuredImage {
          localFile {
            gatsbyImage(
              width: 452
              height: 340
              layout: CONSTRAINED
              placeholder: NONE
              quality: 80
              backgroundColor: "#F5F5F5"
            )
          }
        }
        shortTitle
      }
    }
  }
`;
export const PropertyListingPropertiesFragmentDoc = gql`
  fragment PropertyListingProperties on KFH_PropertyTypeInterface {
    __typename
    seo {
      h1
    }
    available
    id
    uri
    relativeUrl
    remoteId
    businessId
    primaryCategory
    aliasUrl
    commercial
    rentalPrice
    salesPrice
    createdDate
    sellingState
    pricePrefix
    rentalPricePerWeek
    propertyType
    propertySubType
    bedrooms
    propertyStyle
    description
    tenure
    furnished
    bathrooms
    receptions
    roomMetrics
    roomImperials
    summary
    riverside
    managed
    zeroDeposit
    helpToBuy
    propertyAge
    sharedOwnership
    availableLongLet
    availableShortLet
    forSaleAndToLet
    openDay
    virtualViewings
    branch {
      remoteId
      businessProfile {
        businessName
      }
    }
    address {
      streetname
      town
      outwardCode
    }
    images {
      photographs {
        ...PropertyGallerySmallImage
      }
    }
  }
  ${PropertyGallerySmallImageFragmentDoc}
`;
export const NewHomesFeedFragmentDoc = gql`
  fragment NewHomesFeed on KFH_UmbracoNewHomesFeedComponentType {
    title
    backgroundColour
    newHomes {
      ...PropertyListingProperties
    }
  }
  ${PropertyListingPropertiesFragmentDoc}
`;
export const EditoralImageBlockFragmentDoc = gql`
  fragment EditoralImageBlock on KFH_UmbracoEditoralImageBlockComponentType {
    title
    lead
    html
    stickyImage
    linkLists {
      ...LinkList
    }
    button {
      ...Button
    }
    image {
      altText
      width
      height
      mimeType
      filename
      url
      localFile {
        gatsbyImage(
          width: 544
          height: 720
          layout: CONSTRAINED
          cropFocus: CENTER
          placeholder: NONE
          quality: 80
          backgroundColor: "#F5F5F5"
        )
      }
    }
    alignment
    imageRight
  }
  ${LinkListFragmentDoc}
  ${ButtonFragmentDoc}
`;
export const EditoralCtaBlockFragmentDoc = gql`
  fragment EditoralCTABlock on KFH_UmbracoEditoralCtaBlockComponentType {
    title
    lead
    html
    linkLists {
      ...LinkList
    }
    button {
      ...Button
    }
    imageCards {
      ...ImageCardPagePicker
      ...ImageCardCustom
    }
    alignment
  }
  ${LinkListFragmentDoc}
  ${ButtonFragmentDoc}
  ${ImageCardPagePickerFragmentDoc}
  ${ImageCardCustomFragmentDoc}
`;
export const PortfolioFeedFragmentDoc = gql`
  fragment PortfolioFeed on KFH_UmbracoPortfolioFeedComponentType {
    title
    backgroundColour
    portfolios {
      relativeUrl
      pageName
      publishDate
      feed {
        featuredImage {
          localFile {
            gatsbyImage(
              width: 452
              height: 340
              layout: CONSTRAINED
              placeholder: NONE
              quality: 80
              backgroundColor: "#F5F5F5"
            )
          }
        }
        shortTitle
        shortDescription
      }
    }
  }
`;
export const PropertySearchFragmentDoc = gql`
  fragment PropertySearch on KFH_UmbracoPropertySearchComponentType {
    searchType
    ctaCard {
      ...CtaCard
    }
  }
  ${CtaCardFragmentDoc}
`;
export const TickListWithDescriptionFragmentDoc = gql`
  fragment TickListWithDescription on KFH_UmbracoTickListWithDescriptionComponentType {
    tickListListing {
      text
      headline
      button {
        ...Button
      }
    }
  }
  ${ButtonFragmentDoc}
`;
export const PromoBannerFragmentDoc = gql`
  fragment PromoBanner on KFH_UmbracoPromoBannerComponentType {
    title
    description
    link {
      url
      target
      type
      name
      page {
        relativeUrl
        pageName
      }
    }
    smallImage: backgroundImage {
      altText
      localFile {
        gatsbyImage(
          width: 800
          height: 800
          layout: FULL_WIDTH
          placeholder: NONE
          quality: 90
          backgroundColor: "#F5F5F5"
        )
      }
    }
    largeImage: backgroundImage {
      altText
      localFile {
        gatsbyImage(
          width: 1120
          height: 280
          layout: FULL_WIDTH
          placeholder: NONE
          quality: 90
          backgroundColor: "#F5F5F5"
        )
      }
    }
  }
`;
export const TestimonialFragmentDoc = gql`
  fragment Testimonial on KFH_UmbracoTestimonialComponentType {
    backgroundColour
    title
    items {
      name
      text
      audience
    }
  }
`;
export const ComponentRegionsFragmentDoc = gql`
  fragment ComponentRegions on KFH_ContentRegionType {
    regionType
    components {
      elementAlias
      elementType
      ...Accordion
      ...CareersFeed
      ...CaseStudyFeed
      ...ContactList
      ...CtaCard
      ...Html
      ...ImageCards
      ...LocationList
      ...Process
      ...Quote
      ...ReportsFeed
      ...StaffProfileFeed
      ...TickList
      ...Trustpilot
      ...Stats
      ...DoubleCTACard
      ...LinkList
      ...IssuuEmbed
      ...Video
      ...Image
      ...Logos
      ...CardNavigation
      ...CardCarousel
      ...DevelopmentFeed
      ...Calculators
      ...CalculatorsAndImageCard
      ...StandaloneButton
      ...Form
      ...CareersNewsFeed
      ...NewsArticleFeed
      ...NewHomesFeed
      ...EditoralImageBlock
      ...EditoralCTABlock
      ...PortfolioFeed
      ...PropertySearch
      ...TickListWithDescription
      ...PromoBanner
      ...Testimonial
    }
  }
  ${AccordionFragmentDoc}
  ${CareersFeedFragmentDoc}
  ${CaseStudyFeedFragmentDoc}
  ${ContactListFragmentDoc}
  ${CtaCardFragmentDoc}
  ${HtmlFragmentDoc}
  ${ImageCardsFragmentDoc}
  ${LocationListFragmentDoc}
  ${ProcessFragmentDoc}
  ${QuoteFragmentDoc}
  ${ReportsFeedFragmentDoc}
  ${StaffProfileFeedFragmentDoc}
  ${TickListFragmentDoc}
  ${TrustpilotFragmentDoc}
  ${StatsFragmentDoc}
  ${DoubleCtaCardFragmentDoc}
  ${LinkListFragmentDoc}
  ${IssuuEmbedFragmentDoc}
  ${VideoFragmentDoc}
  ${ImageFragmentDoc}
  ${LogosFragmentDoc}
  ${CardNavigationFragmentDoc}
  ${CardCarouselFragmentDoc}
  ${DevelopmentFeedFragmentDoc}
  ${CalculatorsFragmentDoc}
  ${CalculatorsAndImageCardFragmentDoc}
  ${StandaloneButtonFragmentDoc}
  ${FormFragmentDoc}
  ${CareersNewsFeedFragmentDoc}
  ${NewsArticleFeedFragmentDoc}
  ${NewHomesFeedFragmentDoc}
  ${EditoralImageBlockFragmentDoc}
  ${EditoralCtaBlockFragmentDoc}
  ${PortfolioFeedFragmentDoc}
  ${PropertySearchFragmentDoc}
  ${TickListWithDescriptionFragmentDoc}
  ${PromoBannerFragmentDoc}
  ${TestimonialFragmentDoc}
`;
export const HeroImageFragmentDoc = gql`
  fragment HeroImage on KFH_ContentImageUmbracoType {
    localFile {
      gatsbyImage(
        width: 1376
        height: 496
        layout: CONSTRAINED
        cropFocus: CENTER
        placeholder: NONE
        quality: 70
        outputPixelDensities: 1.5
        backgroundColor: "#F5F5F5"
      )
    }
  }
`;
export const LocationSearchHeroFragmentDoc = gql`
  fragment LocationSearchHero on KFH_UmbracoLocationSearchHeroComponentType {
    title
    lead
  }
`;
export const MyKfhLoginFragmentDoc = gql`
  fragment MyKfhLogin on KFH_UmbracoMyKfhLoginComponentType {
    registerHeading
    registerLead
    registrationBenefits {
      text
    }
  }
`;
export const HomepageFragmentDoc = gql`
  fragment Homepage on KFH_UmbracoHomepageComponentType {
    valuationHeading
    valuationLeadText
    button {
      ...Button
    }
  }
  ${ButtonFragmentDoc}
`;
export const HeroComponentFragmentDoc = gql`
  fragment HeroComponent on KFH_UmbracoComponent {
    remoteTypeName
    ...CtaCard
    ...LocationSearchHero
    ...MyKfhLogin
    ...DoubleCTACard
    ...Homepage
  }
  ${CtaCardFragmentDoc}
  ${LocationSearchHeroFragmentDoc}
  ${MyKfhLoginFragmentDoc}
  ${DoubleCtaCardFragmentDoc}
  ${HomepageFragmentDoc}
`;
export const HeroFragmentDoc = gql`
  fragment Hero on KFH_UmbracoHeroType {
    title
    lead
    trustpilot
    image {
      ...HeroImage
    }
    component {
      ...HeroComponent
    }
  }
  ${HeroImageFragmentDoc}
  ${HeroComponentFragmentDoc}
`;
export const SimplePageHeaderFragmentDoc = gql`
  fragment SimplePageHeader on KFH_UmbracoBasePageHeaderType {
    title
    lead
  }
`;
export const PageHeaderFragmentDoc = gql`
  fragment PageHeader on KFH_UmbracoPageHeader {
    remoteTypeName
    ...Hero
    ...SimplePageHeader
  }
  ${HeroFragmentDoc}
  ${SimplePageHeaderFragmentDoc}
`;
export const BranchContactDetailsCfFragmentDoc = gql`
  fragment BranchContactDetailsCF on ContactDetailsType {
    manager
    managerTitle
    photo {
      imagePath
    }
    telephone
    fax
    email
    unit
    streetname
    city
    state
    country
    postcode
    latitude
    longitude
  }
`;
export const BranchImageFragmentDoc = gql`
  fragment BranchImage on KFH_ContentMediaItemType {
    localFile {
      gatsbyImage(
        width: 1376
        height: 496
        layout: CONSTRAINED
        cropFocus: CENTER
        placeholder: NONE
        quality: 70
        backgroundColor: "#F5F5F5"
      )
    }
    alternativeText
  }
`;
export const ImageCardVerticalFragmentDoc = gql`
  fragment ImageCardVertical on KFH_ContentMediaItemType {
    localFile {
      gatsbyImage(
        width: 400
        height: 550
        layout: CONSTRAINED
        cropFocus: CENTER
        placeholder: NONE
        quality: 80
        backgroundColor: "#F5F5F5"
      )
    }
  }
`;
export const ContactDetailsFragmentDoc = gql`
  fragment ContactDetails on KFH_ContactDetailsType {
    unit
    telephone
    streetname
    state
    postcode
    managerTitle
    manager
    longitude
    latitude
    fax
    email
    country
    city
    photo {
      caption
      imagePath
      width
      height
      filename
      mimeType
    }
  }
`;
export const StaffProfileImageFragmentDoc = gql`
  fragment StaffProfileImage on KFH_ContentMediaItemType {
    localFile {
      gatsbyImage(
        width: 352
        height: 264
        layout: CONSTRAINED
        cropFocus: CENTER
        placeholder: NONE
        quality: 80
        backgroundColor: "#F5F5F5"
      )
    }
    alternativeText
  }
`;
export const StaffFragmentDoc = gql`
  fragment Staff on KFH_StaffType {
    bio
    description
    directDialNumber
    email
    excerpt
    forename
    fullDescription
    fullName
    id
    keyResponsibilities
    location
    position
    profile
    remoteId
    startDate
    surname
    username
    mediaSet {
      media {
        media {
          ...StaffProfileImage
        }
        role
      }
    }
  }
  ${StaffProfileImageFragmentDoc}
`;
export const SeoFragmentDoc = gql`
  fragment SEO on KFH_ContentSeoType {
    h1
    htmlDescription
    metaAuthor
    metaCopyright
    metaDescription
    metaKeywords
    noIndex
    noFollow
    onSiteMap
    priority
    remoteTypeName
    slug
    title
  }
`;
export const BranchDetailsFragmentDoc = gql`
  fragment BranchDetails on KFH_BranchType {
    id
    name
    trustpilotTag
    relativeUrl
    isSales
    isLettings
    salesLat
    salesLon
    lettingsLat
    lettingsLon
    openingHours
    googleMapLinkRental
    googleMapLinkResales
    shortDescription
    isLettingsOverride
    lettingsOverrideArea {
      relativeUrl
    }
    mediaSet {
      media {
        media {
          ...BranchImage
        }
        role
      }
    }
    area {
      name
      relativeUrl
      artirixMultiSearch
      artirixDisplayName
      region {
        name
        artirixMultiSearch
        artirixDisplayName
      }
      mediaSet {
        media {
          media {
            ...ImageCardVertical
          }
        }
      }
    }
    businessProfile {
      latLon
      isSales
      isLettings
      latLonLettings
      mondayOpening
      tuesdayOpening
      description
      wednesdayOpening
      thursdayOpening
      fridayOpening
      saturdayOpening
      sundayOpening
      bankHolidayOpening
      additionalOpening
      lettingsAdditionalOpening
      lettingsName
      businessId
      businessName
      salesContactDetails {
        ...ContactDetails
      }
      lettingsContactDetails {
        ...ContactDetails
      }
    }
    lettingsBranchImage {
      ...BranchImage
    }
    salesBranchImage {
      ...BranchImage
    }
    salesManager {
      ...Staff
    }
    lettingsManager {
      ...Staff
    }
    seo {
      ...SEO
    }
  }
  ${BranchImageFragmentDoc}
  ${ImageCardVerticalFragmentDoc}
  ${ContactDetailsFragmentDoc}
  ${StaffFragmentDoc}
  ${SeoFragmentDoc}
`;
export const BranchImageSmallFragmentDoc = gql`
  fragment BranchImageSmall on KFH_ContentMediaItemType {
    localFile {
      gatsbyImage(
        width: 416
        height: 256
        layout: CONSTRAINED
        cropFocus: CENTER
        placeholder: NONE
        quality: 80
        backgroundColor: "#F5F5F5"
      )
    }
    alternativeText
  }
`;
export const BranchListingDetailsFragmentDoc = gql`
  fragment BranchListingDetails on KFH_BranchType {
    id
    remoteId
    name
    relativeUrl
    isSales
    isLettings
    isRiverside
    isCentralLondon
    salesLat
    salesLon
    lettingsLat
    lettingsLon
    openingHours
    googleMapLinkRental
    googleMapLinkResales
    mediaSet {
      media {
        media {
          ...BranchImageSmall
        }
        role
      }
    }
    businessProfile {
      description
      branchArea
      businessName
      businessId
      latLon
      isSales
      isLettings
      latLonLettings
      mondayOpening
      tuesdayOpening
      wednesdayOpening
      thursdayOpening
      fridayOpening
      saturdayOpening
      sundayOpening
      bankHolidayOpening
      additionalOpening
      lettingsAdditionalOpening
      lettingsName
      uri
      salesContactDetails {
        ...ContactDetails
      }
      lettingsContactDetails {
        ...ContactDetails
      }
    }
  }
  ${BranchImageSmallFragmentDoc}
  ${ContactDetailsFragmentDoc}
`;
export const ContactDetailsLiveFragmentDoc = gql`
  fragment ContactDetailsLive on ContactDetailsType {
    unit
    telephone
    streetname
    state
    postcode
    managerTitle
    manager
    longitude
    latitude
    fax
    email
    country
    city
    photo {
      caption
      imagePath
      width
      height
      filename
      mimeType
    }
  }
`;
export const LocationGalleryImageFragmentDoc = gql`
  fragment LocationGalleryImage on KFH_ContentMediaItemType {
    localFile {
      gatsbyImage(
        width: 736
        height: 552
        layout: CONSTRAINED
        cropFocus: CENTER
        placeholder: NONE
        quality: 80
        backgroundColor: "#F5F5F5"
      )
    }
    alternativeText
  }
`;
export const PageSocialImageFragmentDoc = gql`
  fragment PageSocialImage on KFH_ContentImageUmbracoType {
    localFile {
      resize(width: 1200, height: 630, cropFocus: CENTER, quality: 80) {
        src
      }
    }
  }
`;
export const LocationStaticMapFragmentDoc = gql`
  fragment LocationStaticMap on KFH_RemoteFile {
    gatsbyImage(width: 362, layout: CONSTRAINED, placeholder: NONE, quality: 80, backgroundColor: "#F5F5F5")
  }
`;
export const AudienceDataFragmentDoc = gql`
  fragment AudienceData on KFH_ContentGlobalSettingsUmbracoType {
    rental {
      heading
      lead
      linkLists {
        items {
          link {
            name
            url
          }
        }
        smallHeading
      }
    }
    forSale {
      heading
      lead
      linkLists {
        items {
          link {
            name
            url
          }
        }
        smallHeading
      }
    }
  }
`;
export const PropertySocialImageFragmentDoc = gql`
  fragment PropertySocialImage on KFH_ArtirixImageType {
    largestImagePath
    width
    height
  }
`;
export const PropertyGalleryImageSmallFragmentDoc = gql`
  fragment PropertyGalleryImageSmall on KFH_ArtirixImageType {
    largestImagePath
    width
    height
  }
`;
export const PropertyGalleryImageFragmentDoc = gql`
  fragment PropertyGalleryImage on KFH_ArtirixImageType {
    largestImagePath
    width
    height
  }
`;
export const PropertyEpcImageFragmentDoc = gql`
  fragment PropertyEPCImage on KFH_ArtirixImageType {
    largestImagePath
    width
    height
  }
`;
export const PropertyFloorplanImageFragmentDoc = gql`
  fragment PropertyFloorplanImage on KFH_ArtirixImageType {
    largestImagePath
    width
    height
  }
`;
export const PropertyFloorplanSmallImageFragmentDoc = gql`
  fragment PropertyFloorplanSmallImage on KFH_ArtirixImageType {
    largestImagePath
    width
    height
  }
`;
export const DevelopmentPropertyGalleryImageFragmentDoc = gql`
  fragment DevelopmentPropertyGalleryImage on KFH_ArtirixImageType {
    largestImagePath
    width
    height
  }
`;
export const PropertyDetailsFragmentDoc = gql`
  fragment PropertyDetails on KFH_PropertyTypeInterface {
    __typename
    relativeUrl
    uri
    available
    id
    remoteId
    businessId
    createdDate
    lastUpdated
    primaryCategory
    aliasUrl
    address {
      streetname
      town
      outwardCode
    }
    latitude
    longitude
    staticMap {
      gatsbyImage(
        width: 768
        height: 360
        layout: CONSTRAINED
        placeholder: NONE
        quality: 90
        backgroundColor: "#F5F5F5"
        cropFocus: CENTER
      )
    }
    commercial
    roomImperials
    roomMetrics
    rentDetails
    rentalPricePerWeek
    rentalPrice
    salesPrice
    pricePrefix
    sellingState
    openDay
    openDayDateEnd
    openDayDateStart
    furnished
    propertyAge
    forSaleAndToLet
    availableLongLet
    availableShortLet
    managed
    zeroDeposit
    helpToBuy
    sharedOwnership
    propertyType
    propertySubType
    riverside
    tenure
    bedrooms
    receptions
    bathrooms
    description
    salesDetails
    termsAndConditions
    businessRates
    location
    propertyStyle
    additionalDescription
    features {
      description
    }
    propertyBrochure
    virtualViewings
    virtualViewingsUrl
    deposit
    minimumTerm
    councilTaxInc
    councilTaxExempt
    sharedOwnership
    sharedOwnershipPercentage
    sharedOwnershipRent
    sharedOwnershipRentFrequency
    annualGroundRent
    groundRentReviewPeriodYears
    groundRentPercentageIncrease
    annualServiceCharge
    firstImage {
      ...PropertySocialImage
    }
    images {
      photographsSmall: photographs {
        ...PropertyGalleryImageSmall
      }
      photographs: photographs {
        ...PropertyGalleryImage
      }
      epc {
        ...PropertyEPCImage
      }
      floorplans {
        ...PropertyFloorplanImage
      }
      floorplansSmall: floorplans {
        ...PropertyFloorplanSmallImage
      }
    }
    stations {
      name
      trainStationType
      distance
    }
    specifications {
      detail
      category
    }
    area {
      id
      guideExcerpt
      relativeUrl
      name
      region {
        name
        mediaSet {
          media {
            media {
              ...ImageCardVertical
            }
          }
        }
      }
      mediaSet {
        media {
          media {
            ...ImageCardVertical
          }
        }
      }
    }
    branch {
      id
      name
      remoteId
      relativeUrl
      isSales
      isLettings
      salesLat
      salesLon
      lettingsLat
      lettingsLon
      openingHours
      googleMapLinkRental
      googleMapLinkResales
      mediaSet {
        media {
          media {
            ...BranchImageSmall
          }
          role
        }
      }
      businessProfile {
        businessName
        salesContactDetails {
          ...ContactDetails
        }
        lettingsContactDetails {
          ...ContactDetails
        }
      }
      lettingsBranchImage {
        ...BranchImageSmall
      }
      salesBranchImage {
        ...BranchImageSmall
      }
    }
    ... on KFH_ResidentialRentalPropertyType {
      whatsApp {
        whatsAppEnabled
        whatsAppFullUrl
      }
    }
    ... on KFH_DevelopmentResalePropertyType {
      developmentName
      developmentMaxPrice
      developmentMinPrice
      plots {
        plotNumber
        floorLevel
        propertyType
        sellingState
        salesPrice
        bedrooms
        relativeUrl
        available
      }
      images {
        developmentImages {
          ...DevelopmentPropertyGalleryImage
        }
      }
    }
    ... on KFH_PlotResalePropertyType {
      development {
        relativeUrl
        developmentName
        description
      }
      relatedPlots {
        plotNumber
        floorLevel
        propertyType
        sellingState
        salesPrice
        bedrooms
        relativeUrl
        available
      }
    }
    seo {
      ...SEO
    }
  }
  ${PropertySocialImageFragmentDoc}
  ${PropertyGalleryImageSmallFragmentDoc}
  ${PropertyGalleryImageFragmentDoc}
  ${PropertyEpcImageFragmentDoc}
  ${PropertyFloorplanImageFragmentDoc}
  ${PropertyFloorplanSmallImageFragmentDoc}
  ${ImageCardVerticalFragmentDoc}
  ${BranchImageSmallFragmentDoc}
  ${ContactDetailsFragmentDoc}
  ${DevelopmentPropertyGalleryImageFragmentDoc}
  ${SeoFragmentDoc}
`;
export const PropertyListingBannerFragmentDoc = gql`
  fragment PropertyListingBanner on KFH_PropertyBannerType {
    banner
    id
    position
  }
`;
export const SearchFacetsFragmentDoc = gql`
  fragment SearchFacets on KFH_SearchFacetType {
    areas {
      facet
      value
      count
    }
    propertyType {
      facet
      value
      count
    }
    propertyStyle {
      facet
      value
      count
    }
    bedrooms {
      facet
      value
      count
    }
    bathrooms {
      facet
      value
      count
    }
    receptions {
      facet
      value
      count
    }
    riverside {
      facet
      value
      count
    }
    tenure {
      facet
      value
      count
    }
    furnishState {
      facet
      value
      count
    }
    class {
      facet
      value
      count
    }
  }
`;
export const DevelopmentPropertiesListingPropertiesFragmentDoc = gql`
  fragment DevelopmentPropertiesListingProperties on KFH_PropertyTypeInterface {
    __typename
    id
    relativeUrl
    address {
      streetname
      town
      outwardCode
    }
    ... on KFH_DevelopmentResalePropertyType {
      developmentName
      developmentMaxPrice
      developmentMinPrice
      plots {
        plotNumber
        propertyType
        bedrooms
      }
      images {
        developmentImages {
          ...PropertyGallerySmallImage
        }
      }
    }
  }
  ${PropertyGallerySmallImageFragmentDoc}
`;
export const SeoLiveFragmentDoc = gql`
  fragment SEOLive on ContentSeoType {
    h1
    htmlDescription
    metaAuthor
    metaCopyright
    metaDescription
    metaKeywords
    noIndex
    noFollow
    onSiteMap
    priority
    remoteTypeName: __typename
    slug
    title
  }
`;
export const PropertySearchMetaParametersFragmentDoc = gql`
  fragment PropertySearchMetaParameters on KFH_PropertySearchMetaType {
    bedroomsMaximum {
      id
      name
      selected
      value
    }
    bedroomsMinimum {
      id
      name
      selected
      value
    }
    commercialMaximumSize {
      id
      name
      selected
      value
    }
    commercialMinimumSize {
      id
      name
      selected
      value
    }
    furnishState {
      id
      name
      selected
      value
    }
    perPage {
      id
      name
      selected
      value
    }
    radius {
      id
      name
      selected
      value
    }
    rentalHighestPriceDollar {
      id
      name
      selected
      value
    }
    rentalHighestPriceEuro {
      id
      name
      selected
      value
    }
    rentalHighestPriceSterling {
      id
      name
      selected
      value
    }
    rentalHighestWeeklyPriceDollar {
      id
      name
      selected
      value
    }
    rentalHighestWeeklyPriceEuro {
      id
      name
      selected
      value
    }
    rentalHighestWeeklyPriceSterling {
      id
      name
      selected
      value
    }
    rentalLowestPriceDollar {
      id
      name
      selected
      value
    }
    rentalLowestPriceEuro {
      id
      name
      selected
      value
    }
    rentalLowestPriceSterling {
      id
      name
      selected
      value
    }
    rentalLowestWeeklyPriceDollar {
      id
      name
      selected
      value
    }
    rentalLowestWeeklyPriceEuro {
      id
      name
      selected
      value
    }
    rentalLowestWeeklyPriceSterling {
      id
      name
      selected
      value
    }
    resaleHighestPriceDollar {
      id
      name
      selected
      value
    }
    resaleHighestPriceEuro {
      id
      name
      selected
      value
    }
    resaleHighestPriceSterling {
      id
      name
      selected
      value
    }
    resaleLowestPriceDollar {
      id
      name
      selected
      value
    }
    resaleLowestPriceEuro {
      id
      name
      selected
      value
    }
    resaleLowestPriceSterling {
      id
      name
      selected
      value
    }
    sortbyOptions {
      id
      name
      selected
      value
    }
  }
`;
export const SocialSettingsFragmentDoc = gql`
  fragment SocialSettings on KFH_SocialSettingsType {
    id
    facebookAppId
    facebookServiceUrl
    facebookUsernameOrId
    gravatarServiceUrl
    linkedInServiceUrl
    linkedInUsername
    openGraphArticleAuthorEnabled
    openGraphEnabled
    openGraphPublisher
    openGraphSiteName
    pintrestScreenName
    siteId
    slideShareName
    twitterScreenName
    twitterServiceUrl
  }
`;
export const ClientSettingsFragmentDoc = gql`
  fragment ClientSettings on KfhSettingsType {
    addressLine1
    addressLine2
    addressLine3
    advertBanner
    advertBanner_2
    advertEnabled
    advertEnabled_2
    advertLocationLat
    advertLocationLat_2
    advertLocationLong
    advertLocationLong_2
    advertRadius
    advertRadius_2
    advertSearch
    advertSearch_2
    arrangeViewingCTASendBeeApi
    branchAutocompleteIdentifier
    branchBlanketMessage
    branchBlanketMessageEnd
    branchBlanketMessageStart
    branchLettingsJobTitle
    branchSalesJobTitle
    commercialEmail
    commercialPhone
    commercialTeamName
    companyNameLimited
    companyNumber
    conveyancingQuoteEmail
    cookiePopupMessage
    county
    companyName
    coronavirusInLockdown
    currencyDisclaimer
    helpToBuyEnabled
    defaultCurrencyId
    internalCookieStateIps
    lettingsDisclaimer
    postcode
    propetyValuationPositionInSearch
    propetyValuationPositionInSearch_2
    siteId
    tradingName
    vatRegistrationNumber
    whereRegistered
  }
`;
export const PropertyListingDynamicFragmentDoc = gql`
  fragment PropertyListingDynamic on PropertyTypeInterface {
    uri
    available
    id
    fullAddress
    relativeUrl
    remoteId: id
    businessId
    primaryCategory
    aliasUrl
    commercial
    rentalPrice
    salesPrice
    sellingState
    pricePrefix
    rentalPricePerWeek
    propertyType
    propertySubType
    bedrooms
    tenure
    furnished
    bathrooms
    receptions
    roomMetrics
    roomImperials
    description
    riverside
    managed
    zeroDeposit
    helpToBuy
    propertyAge
    sharedOwnership
    availableLongLet
    availableShortLet
    forSaleAndToLet
    openDay
    virtualViewings
    branch {
      id
      remoteId: id
      businessProfile {
        businessName
      }
    }
    address {
      streetname
      town
      outwardCode
    }
    ... on DevelopmentResalePropertyType {
      developmentName
      developmentMaxPrice
      developmentMinPrice
      plots {
        plotNumber
        propertyType
      }
      images {
        photographs {
          imagePath
          largestImagePath
          width
          height
          filename
          mimeType
        }
      }
    }
    images {
      photographs {
        imagePath
        largestImagePath
        width
        height
        filename
        mimeType
      }
    }
  }
`;
export const BranchContactDetailsFragmentDoc = gql`
  fragment BranchContactDetails on ContactDetailsType {
    manager
    managerTitle
    photo {
      imagePath
    }
    telephone
    telephoneFixed
    fax
    email
    unit
    streetname
    city
    state
    country
    postcode
    latitude
    longitude
  }
`;
export const PagesQueryDocument = gql`
  query PagesQuery {
    allSiteFunction {
      nodes {
        functionRoute
      }
    }
    allSitePage(filter: { path: { regex: "/^(?!/dev-404-page).+$/" } }) {
      nodes {
        path
      }
    }
  }
`;

/**
 * __usePagesQueryQuery__
 *
 * To run a query within a React component, call `usePagesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function usePagesQueryQuery(baseOptions?: Apollo.QueryHookOptions<PagesQueryQuery, PagesQueryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PagesQueryQuery, PagesQueryQueryVariables>(PagesQueryDocument, options);
}
export function usePagesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PagesQueryQuery, PagesQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PagesQueryQuery, PagesQueryQueryVariables>(PagesQueryDocument, options);
}
export type PagesQueryQueryHookResult = ReturnType<typeof usePagesQueryQuery>;
export type PagesQueryLazyQueryHookResult = ReturnType<typeof usePagesQueryLazyQuery>;
export type PagesQueryQueryResult = Apollo.QueryResult<PagesQueryQuery, PagesQueryQueryVariables>;
export const BranchesQueryDocument = gql`
  query branchesQuery {
    branchList: allKfhBranchType(sort: { name: ASC }) {
      nodes {
        ...BranchListingDetails
      }
    }
  }
  ${BranchListingDetailsFragmentDoc}
`;

/**
 * __useBranchesQueryQuery__
 *
 * To run a query within a React component, call `useBranchesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useBranchesQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<BranchesQueryQuery, BranchesQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BranchesQueryQuery, BranchesQueryQueryVariables>(BranchesQueryDocument, options);
}
export function useBranchesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BranchesQueryQuery, BranchesQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BranchesQueryQuery, BranchesQueryQueryVariables>(BranchesQueryDocument, options);
}
export type BranchesQueryQueryHookResult = ReturnType<typeof useBranchesQueryQuery>;
export type BranchesQueryLazyQueryHookResult = ReturnType<typeof useBranchesQueryLazyQuery>;
export type BranchesQueryQueryResult = Apollo.QueryResult<BranchesQueryQuery, BranchesQueryQueryVariables>;
export const InternalIpsQueryDocument = gql`
  query internalIpsQuery {
    kfhKfhSettingsType {
      internalIps: internalCookieStateIps
    }
  }
`;

/**
 * __useInternalIpsQueryQuery__
 *
 * To run a query within a React component, call `useInternalIpsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalIpsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalIpsQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useInternalIpsQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<InternalIpsQueryQuery, InternalIpsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InternalIpsQueryQuery, InternalIpsQueryQueryVariables>(InternalIpsQueryDocument, options);
}
export function useInternalIpsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InternalIpsQueryQuery, InternalIpsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InternalIpsQueryQuery, InternalIpsQueryQueryVariables>(InternalIpsQueryDocument, options);
}
export type InternalIpsQueryQueryHookResult = ReturnType<typeof useInternalIpsQueryQuery>;
export type InternalIpsQueryLazyQueryHookResult = ReturnType<typeof useInternalIpsQueryLazyQuery>;
export type InternalIpsQueryQueryResult = Apollo.QueryResult<InternalIpsQueryQuery, InternalIpsQueryQueryVariables>;
export const DevelopmentPropertyQueryDocument = gql`
  query developmentPropertyQuery {
    developmentPropertyData: allKfhDevelopmentResalePropertyType {
      totalCount
    }
  }
`;

/**
 * __useDevelopmentPropertyQueryQuery__
 *
 * To run a query within a React component, call `useDevelopmentPropertyQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevelopmentPropertyQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevelopmentPropertyQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useDevelopmentPropertyQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<DevelopmentPropertyQueryQuery, DevelopmentPropertyQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DevelopmentPropertyQueryQuery, DevelopmentPropertyQueryQueryVariables>(
    DevelopmentPropertyQueryDocument,
    options
  );
}
export function useDevelopmentPropertyQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DevelopmentPropertyQueryQuery, DevelopmentPropertyQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DevelopmentPropertyQueryQuery, DevelopmentPropertyQueryQueryVariables>(
    DevelopmentPropertyQueryDocument,
    options
  );
}
export type DevelopmentPropertyQueryQueryHookResult = ReturnType<typeof useDevelopmentPropertyQueryQuery>;
export type DevelopmentPropertyQueryLazyQueryHookResult = ReturnType<typeof useDevelopmentPropertyQueryLazyQuery>;
export type DevelopmentPropertyQueryQueryResult = Apollo.QueryResult<
  DevelopmentPropertyQueryQuery,
  DevelopmentPropertyQueryQueryVariables
>;
export const FooterQueryDocument = gql`
  query footerQuery {
    footerMenuData: kfhContentGlobalFooterUmbracoType {
      footerMenu {
        links {
          name
          url
        }
        title
      }
      footerSmallMenu {
        links {
          name
          url
        }
      }
      smallPrint
    }
    socialLinksData: kfhContentGlobalSettingsUmbracoType {
      socialMedia {
        faceBook
        instagram
        linkedIn
        twitter
      }
    }
  }
`;

/**
 * __useFooterQueryQuery__
 *
 * To run a query within a React component, call `useFooterQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFooterQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFooterQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFooterQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<FooterQueryQuery, FooterQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FooterQueryQuery, FooterQueryQueryVariables>(FooterQueryDocument, options);
}
export function useFooterQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FooterQueryQuery, FooterQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FooterQueryQuery, FooterQueryQueryVariables>(FooterQueryDocument, options);
}
export type FooterQueryQueryHookResult = ReturnType<typeof useFooterQueryQuery>;
export type FooterQueryLazyQueryHookResult = ReturnType<typeof useFooterQueryLazyQuery>;
export type FooterQueryQueryResult = Apollo.QueryResult<FooterQueryQuery, FooterQueryQueryVariables>;
export const HeaderQueryDocument = gql`
  query headerQuery {
    headerData: kfhContentGlobalHeaderUmbracoType {
      topMenu {
        name
        url
      }
      mainMenu {
        imageCards {
          ...ImageCardCustomSmall
          ...ImageCardPagePickerSmall
        }
        link {
          name
          url
        }
        viewAllLink {
          url
          name
        }
        menuTitle
        menuItems {
          name
          url
        }
      }
    }
  }
  ${ImageCardCustomSmallFragmentDoc}
  ${ImageCardPagePickerSmallFragmentDoc}
`;

/**
 * __useHeaderQueryQuery__
 *
 * To run a query within a React component, call `useHeaderQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeaderQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeaderQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useHeaderQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<HeaderQueryQuery, HeaderQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeaderQueryQuery, HeaderQueryQueryVariables>(HeaderQueryDocument, options);
}
export function useHeaderQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HeaderQueryQuery, HeaderQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeaderQueryQuery, HeaderQueryQueryVariables>(HeaderQueryDocument, options);
}
export type HeaderQueryQueryHookResult = ReturnType<typeof useHeaderQueryQuery>;
export type HeaderQueryLazyQueryHookResult = ReturnType<typeof useHeaderQueryLazyQuery>;
export type HeaderQueryQueryResult = Apollo.QueryResult<HeaderQueryQuery, HeaderQueryQueryVariables>;
export const NewHomePropertyQueryDocument = gql`
  query newHomePropertyQuery {
    newHomePropertyData: allKfhPropertyTypeInterface(
      filter: { propertyAge: { eq: "New home" }, available: { eq: true } }
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

/**
 * __useNewHomePropertyQueryQuery__
 *
 * To run a query within a React component, call `useNewHomePropertyQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewHomePropertyQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewHomePropertyQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewHomePropertyQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<NewHomePropertyQueryQuery, NewHomePropertyQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewHomePropertyQueryQuery, NewHomePropertyQueryQueryVariables>(
    NewHomePropertyQueryDocument,
    options
  );
}
export function useNewHomePropertyQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NewHomePropertyQueryQuery, NewHomePropertyQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewHomePropertyQueryQuery, NewHomePropertyQueryQueryVariables>(
    NewHomePropertyQueryDocument,
    options
  );
}
export type NewHomePropertyQueryQueryHookResult = ReturnType<typeof useNewHomePropertyQueryQuery>;
export type NewHomePropertyQueryLazyQueryHookResult = ReturnType<typeof useNewHomePropertyQueryLazyQuery>;
export type NewHomePropertyQueryQueryResult = Apollo.QueryResult<
  NewHomePropertyQueryQuery,
  NewHomePropertyQueryQueryVariables
>;
export const BlockManagementDivisionQueryDocument = gql`
  query blockManagementDivisionQuery {
    blockManagementDivisionData: kfhDivisionType(remoteId: { eq: "1005" }) {
      whatsApp {
        whatsAppEnabled
        whatsAppFullUrl
      }
    }
  }
`;

/**
 * __useBlockManagementDivisionQueryQuery__
 *
 * To run a query within a React component, call `useBlockManagementDivisionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockManagementDivisionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockManagementDivisionQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlockManagementDivisionQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<BlockManagementDivisionQueryQuery, BlockManagementDivisionQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BlockManagementDivisionQueryQuery, BlockManagementDivisionQueryQueryVariables>(
    BlockManagementDivisionQueryDocument,
    options
  );
}
export function useBlockManagementDivisionQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BlockManagementDivisionQueryQuery,
    BlockManagementDivisionQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BlockManagementDivisionQueryQuery, BlockManagementDivisionQueryQueryVariables>(
    BlockManagementDivisionQueryDocument,
    options
  );
}
export type BlockManagementDivisionQueryQueryHookResult = ReturnType<typeof useBlockManagementDivisionQueryQuery>;
export type BlockManagementDivisionQueryLazyQueryHookResult = ReturnType<
  typeof useBlockManagementDivisionQueryLazyQuery
>;
export type BlockManagementDivisionQueryQueryResult = Apollo.QueryResult<
  BlockManagementDivisionQueryQuery,
  BlockManagementDivisionQueryQueryVariables
>;
export const BuildToRendContactQueryDocument = gql`
  query buildToRendContactQuery {
    buildToRendContactData: kfhDivisionType(id: { eq: "DivisionType:1013" }) {
      name
      divisionAddresses {
        line1
        line2
        town
        postcode
        contactName
        contactPosition
        telephone
        email
      }
    }
  }
`;

/**
 * __useBuildToRendContactQueryQuery__
 *
 * To run a query within a React component, call `useBuildToRendContactQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildToRendContactQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildToRendContactQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useBuildToRendContactQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<BuildToRendContactQueryQuery, BuildToRendContactQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BuildToRendContactQueryQuery, BuildToRendContactQueryQueryVariables>(
    BuildToRendContactQueryDocument,
    options
  );
}
export function useBuildToRendContactQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BuildToRendContactQueryQuery, BuildToRendContactQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BuildToRendContactQueryQuery, BuildToRendContactQueryQueryVariables>(
    BuildToRendContactQueryDocument,
    options
  );
}
export type BuildToRendContactQueryQueryHookResult = ReturnType<typeof useBuildToRendContactQueryQuery>;
export type BuildToRendContactQueryLazyQueryHookResult = ReturnType<typeof useBuildToRendContactQueryLazyQuery>;
export type BuildToRendContactQueryQueryResult = Apollo.QueryResult<
  BuildToRendContactQueryQuery,
  BuildToRendContactQueryQueryVariables
>;
export const VacancyTypesQueryDocument = gql`
  query vacancyTypesQuery {
    vacancyTypes: allKfhVacancyTypeType {
      edges {
        node {
          name
        }
      }
    }
  }
`;

/**
 * __useVacancyTypesQueryQuery__
 *
 * To run a query within a React component, call `useVacancyTypesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useVacancyTypesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVacancyTypesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useVacancyTypesQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<VacancyTypesQueryQuery, VacancyTypesQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VacancyTypesQueryQuery, VacancyTypesQueryQueryVariables>(VacancyTypesQueryDocument, options);
}
export function useVacancyTypesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VacancyTypesQueryQuery, VacancyTypesQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VacancyTypesQueryQuery, VacancyTypesQueryQueryVariables>(
    VacancyTypesQueryDocument,
    options
  );
}
export type VacancyTypesQueryQueryHookResult = ReturnType<typeof useVacancyTypesQueryQuery>;
export type VacancyTypesQueryLazyQueryHookResult = ReturnType<typeof useVacancyTypesQueryLazyQuery>;
export type VacancyTypesQueryQueryResult = Apollo.QueryResult<VacancyTypesQueryQuery, VacancyTypesQueryQueryVariables>;
export const CorporateRelocationServicesDivisionQueryDocument = gql`
  query corporateRelocationServicesDivisionQuery {
    corporateRelocationServicesDivisionData: kfhDivisionType(remoteId: { eq: "1009" }) {
      divisionAddresses {
        line1
        line2
        town
        postcode
        contactName
        contactPosition
        telephone
        email
      }
      whatsApp {
        whatsAppEnabled
        whatsAppFullUrl
      }
    }
  }
`;

/**
 * __useCorporateRelocationServicesDivisionQueryQuery__
 *
 * To run a query within a React component, call `useCorporateRelocationServicesDivisionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorporateRelocationServicesDivisionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorporateRelocationServicesDivisionQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCorporateRelocationServicesDivisionQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CorporateRelocationServicesDivisionQueryQuery,
    CorporateRelocationServicesDivisionQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CorporateRelocationServicesDivisionQueryQuery,
    CorporateRelocationServicesDivisionQueryQueryVariables
  >(CorporateRelocationServicesDivisionQueryDocument, options);
}
export function useCorporateRelocationServicesDivisionQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CorporateRelocationServicesDivisionQueryQuery,
    CorporateRelocationServicesDivisionQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CorporateRelocationServicesDivisionQueryQuery,
    CorporateRelocationServicesDivisionQueryQueryVariables
  >(CorporateRelocationServicesDivisionQueryDocument, options);
}
export type CorporateRelocationServicesDivisionQueryQueryHookResult = ReturnType<
  typeof useCorporateRelocationServicesDivisionQueryQuery
>;
export type CorporateRelocationServicesDivisionQueryLazyQueryHookResult = ReturnType<
  typeof useCorporateRelocationServicesDivisionQueryLazyQuery
>;
export type CorporateRelocationServicesDivisionQueryQueryResult = Apollo.QueryResult<
  CorporateRelocationServicesDivisionQueryQuery,
  CorporateRelocationServicesDivisionQueryQueryVariables
>;
export const FinancialServicesContactQueryDocument = gql`
  query financialServicesContactQuery {
    financialServicesContactData: kfhDivisionType(remoteId: { eq: "2" }) {
      divisionAddresses {
        line1
        line2
        town
        postcode
        contactName
        contactPosition
        telephone
        email
      }
      whatsApp {
        whatsAppEnabled
        whatsAppFullUrl
      }
    }
  }
`;

/**
 * __useFinancialServicesContactQueryQuery__
 *
 * To run a query within a React component, call `useFinancialServicesContactQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancialServicesContactQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancialServicesContactQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFinancialServicesContactQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<FinancialServicesContactQueryQuery, FinancialServicesContactQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FinancialServicesContactQueryQuery, FinancialServicesContactQueryQueryVariables>(
    FinancialServicesContactQueryDocument,
    options
  );
}
export function useFinancialServicesContactQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinancialServicesContactQueryQuery,
    FinancialServicesContactQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FinancialServicesContactQueryQuery, FinancialServicesContactQueryQueryVariables>(
    FinancialServicesContactQueryDocument,
    options
  );
}
export type FinancialServicesContactQueryQueryHookResult = ReturnType<typeof useFinancialServicesContactQueryQuery>;
export type FinancialServicesContactQueryLazyQueryHookResult = ReturnType<
  typeof useFinancialServicesContactQueryLazyQuery
>;
export type FinancialServicesContactQueryQueryResult = Apollo.QueryResult<
  FinancialServicesContactQueryQuery,
  FinancialServicesContactQueryQueryVariables
>;
export const LeaseExtensionQueryDocument = gql`
  query leaseExtensionQuery {
    leaseExtension: kfhDivisionType(remoteId: { eq: "1020" }) {
      divisionAddresses {
        line1
        line2
        town
        postcode
        contactName
        contactPosition
        telephone
        email
      }
      whatsApp {
        whatsAppEnabled
        whatsAppFullUrl
      }
    }
  }
`;

/**
 * __useLeaseExtensionQueryQuery__
 *
 * To run a query within a React component, call `useLeaseExtensionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaseExtensionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaseExtensionQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useLeaseExtensionQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<LeaseExtensionQueryQuery, LeaseExtensionQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeaseExtensionQueryQuery, LeaseExtensionQueryQueryVariables>(
    LeaseExtensionQueryDocument,
    options
  );
}
export function useLeaseExtensionQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LeaseExtensionQueryQuery, LeaseExtensionQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LeaseExtensionQueryQuery, LeaseExtensionQueryQueryVariables>(
    LeaseExtensionQueryDocument,
    options
  );
}
export type LeaseExtensionQueryQueryHookResult = ReturnType<typeof useLeaseExtensionQueryQuery>;
export type LeaseExtensionQueryLazyQueryHookResult = ReturnType<typeof useLeaseExtensionQueryLazyQuery>;
export type LeaseExtensionQueryQueryResult = Apollo.QueryResult<
  LeaseExtensionQueryQuery,
  LeaseExtensionQueryQueryVariables
>;
export const NewHomesContactQueryDocument = gql`
  query newHomesContactQuery {
    newHomesContactData: kfhDivisionType(remoteId: { eq: "1" }) {
      divisionAddresses {
        line1
        line2
        town
        postcode
        contactName
        contactPosition
        telephone
        email
      }
      whatsApp {
        whatsAppEnabled
        whatsAppFullUrl
      }
    }
  }
`;

/**
 * __useNewHomesContactQueryQuery__
 *
 * To run a query within a React component, call `useNewHomesContactQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewHomesContactQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewHomesContactQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewHomesContactQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<NewHomesContactQueryQuery, NewHomesContactQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewHomesContactQueryQuery, NewHomesContactQueryQueryVariables>(
    NewHomesContactQueryDocument,
    options
  );
}
export function useNewHomesContactQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NewHomesContactQueryQuery, NewHomesContactQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewHomesContactQueryQuery, NewHomesContactQueryQueryVariables>(
    NewHomesContactQueryDocument,
    options
  );
}
export type NewHomesContactQueryQueryHookResult = ReturnType<typeof useNewHomesContactQueryQuery>;
export type NewHomesContactQueryLazyQueryHookResult = ReturnType<typeof useNewHomesContactQueryLazyQuery>;
export type NewHomesContactQueryQueryResult = Apollo.QueryResult<
  NewHomesContactQueryQuery,
  NewHomesContactQueryQueryVariables
>;
export const PropertySolicitorsDivisionDataQueryDocument = gql`
  query propertySolicitorsDivisionDataQuery {
    propertySolicitorsDivisionData: kfhDivisionType(remoteId: { eq: "1007" }) {
      divisionAddresses {
        line1
        line2
        town
        postcode
        contactName
        contactPosition
        telephone
        email
      }
      whatsApp {
        whatsAppEnabled
        whatsAppFullUrl
      }
    }
  }
`;

/**
 * __usePropertySolicitorsDivisionDataQueryQuery__
 *
 * To run a query within a React component, call `usePropertySolicitorsDivisionDataQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertySolicitorsDivisionDataQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertySolicitorsDivisionDataQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function usePropertySolicitorsDivisionDataQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PropertySolicitorsDivisionDataQueryQuery,
    PropertySolicitorsDivisionDataQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PropertySolicitorsDivisionDataQueryQuery, PropertySolicitorsDivisionDataQueryQueryVariables>(
    PropertySolicitorsDivisionDataQueryDocument,
    options
  );
}
export function usePropertySolicitorsDivisionDataQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertySolicitorsDivisionDataQueryQuery,
    PropertySolicitorsDivisionDataQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PropertySolicitorsDivisionDataQueryQuery,
    PropertySolicitorsDivisionDataQueryQueryVariables
  >(PropertySolicitorsDivisionDataQueryDocument, options);
}
export type PropertySolicitorsDivisionDataQueryQueryHookResult = ReturnType<
  typeof usePropertySolicitorsDivisionDataQueryQuery
>;
export type PropertySolicitorsDivisionDataQueryLazyQueryHookResult = ReturnType<
  typeof usePropertySolicitorsDivisionDataQueryLazyQuery
>;
export type PropertySolicitorsDivisionDataQueryQueryResult = Apollo.QueryResult<
  PropertySolicitorsDivisionDataQueryQuery,
  PropertySolicitorsDivisionDataQueryQueryVariables
>;
export const SurveyingContactDataQueryDocument = gql`
  query surveyingContactDataQuery {
    surveyingContactData: kfhDivisionType(remoteId: { eq: "1006" }) {
      divisionAddresses {
        line1
        line2
        town
        postcode
        contactName
        contactPosition
        telephone
        email
      }
      whatsApp {
        whatsAppEnabled
        whatsAppFullUrl
      }
    }
  }
`;

/**
 * __useSurveyingContactDataQueryQuery__
 *
 * To run a query within a React component, call `useSurveyingContactDataQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyingContactDataQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyingContactDataQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useSurveyingContactDataQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<SurveyingContactDataQueryQuery, SurveyingContactDataQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SurveyingContactDataQueryQuery, SurveyingContactDataQueryQueryVariables>(
    SurveyingContactDataQueryDocument,
    options
  );
}
export function useSurveyingContactDataQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SurveyingContactDataQueryQuery, SurveyingContactDataQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SurveyingContactDataQueryQuery, SurveyingContactDataQueryQueryVariables>(
    SurveyingContactDataQueryDocument,
    options
  );
}
export type SurveyingContactDataQueryQueryHookResult = ReturnType<typeof useSurveyingContactDataQueryQuery>;
export type SurveyingContactDataQueryLazyQueryHookResult = ReturnType<typeof useSurveyingContactDataQueryLazyQuery>;
export type SurveyingContactDataQueryQueryResult = Apollo.QueryResult<
  SurveyingContactDataQueryQuery,
  SurveyingContactDataQueryQueryVariables
>;
export const BranchesQueryLocDocument = gql`
  query branchesQueryLoc {
    branchList: allKfhBranchType(sort: { name: ASC }) {
      nodes {
        ...BranchListingDetails
      }
    }
  }
  ${BranchListingDetailsFragmentDoc}
`;

/**
 * __useBranchesQueryLocQuery__
 *
 * To run a query within a React component, call `useBranchesQueryLocQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchesQueryLocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchesQueryLocQuery({
 *   variables: {
 *   },
 * });
 */
export function useBranchesQueryLocQuery(
  baseOptions?: Apollo.QueryHookOptions<BranchesQueryLocQuery, BranchesQueryLocQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BranchesQueryLocQuery, BranchesQueryLocQueryVariables>(BranchesQueryLocDocument, options);
}
export function useBranchesQueryLocLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BranchesQueryLocQuery, BranchesQueryLocQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BranchesQueryLocQuery, BranchesQueryLocQueryVariables>(BranchesQueryLocDocument, options);
}
export type BranchesQueryLocQueryHookResult = ReturnType<typeof useBranchesQueryLocQuery>;
export type BranchesQueryLocLazyQueryHookResult = ReturnType<typeof useBranchesQueryLocLazyQuery>;
export type BranchesQueryLocQueryResult = Apollo.QueryResult<BranchesQueryLocQuery, BranchesQueryLocQueryVariables>;
export const AudienceContentQueryDocument = gql`
  query audienceContentQuery {
    audienceContent: kfhContentGlobalSettingsUmbracoType {
      myKfhAudiences {
        audienceType
        imageBlock {
          ...EditoralImageBlock
        }
        imageCard {
          ...ImageCard
        }
      }
    }
  }
  ${EditoralImageBlockFragmentDoc}
  ${ImageCardFragmentDoc}
`;

/**
 * __useAudienceContentQueryQuery__
 *
 * To run a query within a React component, call `useAudienceContentQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudienceContentQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudienceContentQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useAudienceContentQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<AudienceContentQueryQuery, AudienceContentQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AudienceContentQueryQuery, AudienceContentQueryQueryVariables>(
    AudienceContentQueryDocument,
    options
  );
}
export function useAudienceContentQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AudienceContentQueryQuery, AudienceContentQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AudienceContentQueryQuery, AudienceContentQueryQueryVariables>(
    AudienceContentQueryDocument,
    options
  );
}
export type AudienceContentQueryQueryHookResult = ReturnType<typeof useAudienceContentQueryQuery>;
export type AudienceContentQueryLazyQueryHookResult = ReturnType<typeof useAudienceContentQueryLazyQuery>;
export type AudienceContentQueryQueryResult = Apollo.QueryResult<
  AudienceContentQueryQuery,
  AudienceContentQueryQueryVariables
>;
export const Create_Block_Management_QuoteDocument = gql`
  mutation CREATE_BLOCK_MANAGEMENT_QUOTE($formSubmission: FormSubmissionInput!) {
    form {
      createFormSubmission(formSubmission: $formSubmission) {
        errorMessage
        success
      }
    }
  }
`;
export type Create_Block_Management_QuoteMutationFn = Apollo.MutationFunction<
  Create_Block_Management_QuoteMutation,
  Create_Block_Management_QuoteMutationVariables
>;

/**
 * __useCreate_Block_Management_QuoteMutation__
 *
 * To run a mutation, you first call `useCreate_Block_Management_QuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Block_Management_QuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlockManagementQuoteMutation, { data, loading, error }] = useCreate_Block_Management_QuoteMutation({
 *   variables: {
 *      formSubmission: // value for 'formSubmission'
 *   },
 * });
 */
export function useCreate_Block_Management_QuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Create_Block_Management_QuoteMutation,
    Create_Block_Management_QuoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Create_Block_Management_QuoteMutation, Create_Block_Management_QuoteMutationVariables>(
    Create_Block_Management_QuoteDocument,
    options
  );
}
export type Create_Block_Management_QuoteMutationHookResult = ReturnType<
  typeof useCreate_Block_Management_QuoteMutation
>;
export type Create_Block_Management_QuoteMutationResult = Apollo.MutationResult<Create_Block_Management_QuoteMutation>;
export type Create_Block_Management_QuoteMutationOptions = Apollo.BaseMutationOptions<
  Create_Block_Management_QuoteMutation,
  Create_Block_Management_QuoteMutationVariables
>;
export const Create_Block_Management_IssueDocument = gql`
  mutation CREATE_BLOCK_MANAGEMENT_ISSUE($formSubmission: FormSubmissionInput!) {
    form {
      createFormSubmission(formSubmission: $formSubmission) {
        errorMessage
        success
      }
    }
  }
`;
export type Create_Block_Management_IssueMutationFn = Apollo.MutationFunction<
  Create_Block_Management_IssueMutation,
  Create_Block_Management_IssueMutationVariables
>;

/**
 * __useCreate_Block_Management_IssueMutation__
 *
 * To run a mutation, you first call `useCreate_Block_Management_IssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Block_Management_IssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlockManagementIssueMutation, { data, loading, error }] = useCreate_Block_Management_IssueMutation({
 *   variables: {
 *      formSubmission: // value for 'formSubmission'
 *   },
 * });
 */
export function useCreate_Block_Management_IssueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Create_Block_Management_IssueMutation,
    Create_Block_Management_IssueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Create_Block_Management_IssueMutation, Create_Block_Management_IssueMutationVariables>(
    Create_Block_Management_IssueDocument,
    options
  );
}
export type Create_Block_Management_IssueMutationHookResult = ReturnType<
  typeof useCreate_Block_Management_IssueMutation
>;
export type Create_Block_Management_IssueMutationResult = Apollo.MutationResult<Create_Block_Management_IssueMutation>;
export type Create_Block_Management_IssueMutationOptions = Apollo.BaseMutationOptions<
  Create_Block_Management_IssueMutation,
  Create_Block_Management_IssueMutationVariables
>;
export const Create_Block_Management_Tenant_RegistrationDocument = gql`
  mutation CREATE_BLOCK_MANAGEMENT_TENANT_REGISTRATION($formSubmission: FormSubmissionInput!) {
    form {
      createFormSubmission(formSubmission: $formSubmission) {
        errorMessage
        success
      }
    }
  }
`;
export type Create_Block_Management_Tenant_RegistrationMutationFn = Apollo.MutationFunction<
  Create_Block_Management_Tenant_RegistrationMutation,
  Create_Block_Management_Tenant_RegistrationMutationVariables
>;

/**
 * __useCreate_Block_Management_Tenant_RegistrationMutation__
 *
 * To run a mutation, you first call `useCreate_Block_Management_Tenant_RegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Block_Management_Tenant_RegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlockManagementTenantRegistrationMutation, { data, loading, error }] = useCreate_Block_Management_Tenant_RegistrationMutation({
 *   variables: {
 *      formSubmission: // value for 'formSubmission'
 *   },
 * });
 */
export function useCreate_Block_Management_Tenant_RegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Create_Block_Management_Tenant_RegistrationMutation,
    Create_Block_Management_Tenant_RegistrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Create_Block_Management_Tenant_RegistrationMutation,
    Create_Block_Management_Tenant_RegistrationMutationVariables
  >(Create_Block_Management_Tenant_RegistrationDocument, options);
}
export type Create_Block_Management_Tenant_RegistrationMutationHookResult = ReturnType<
  typeof useCreate_Block_Management_Tenant_RegistrationMutation
>;
export type Create_Block_Management_Tenant_RegistrationMutationResult =
  Apollo.MutationResult<Create_Block_Management_Tenant_RegistrationMutation>;
export type Create_Block_Management_Tenant_RegistrationMutationOptions = Apollo.BaseMutationOptions<
  Create_Block_Management_Tenant_RegistrationMutation,
  Create_Block_Management_Tenant_RegistrationMutationVariables
>;
export const Create_Branch_ContactDocument = gql`
  mutation CREATE_BRANCH_CONTACT($branchContact: BranchContactInput!) {
    lead {
      createBranchContact(branchContact: $branchContact) {
        title
        forename
        surname
        success
        errorMessage
      }
    }
  }
`;
export type Create_Branch_ContactMutationFn = Apollo.MutationFunction<
  Create_Branch_ContactMutation,
  Create_Branch_ContactMutationVariables
>;

/**
 * __useCreate_Branch_ContactMutation__
 *
 * To run a mutation, you first call `useCreate_Branch_ContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Branch_ContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBranchContactMutation, { data, loading, error }] = useCreate_Branch_ContactMutation({
 *   variables: {
 *      branchContact: // value for 'branchContact'
 *   },
 * });
 */
export function useCreate_Branch_ContactMutation(
  baseOptions?: Apollo.MutationHookOptions<Create_Branch_ContactMutation, Create_Branch_ContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Create_Branch_ContactMutation, Create_Branch_ContactMutationVariables>(
    Create_Branch_ContactDocument,
    options
  );
}
export type Create_Branch_ContactMutationHookResult = ReturnType<typeof useCreate_Branch_ContactMutation>;
export type Create_Branch_ContactMutationResult = Apollo.MutationResult<Create_Branch_ContactMutation>;
export type Create_Branch_ContactMutationOptions = Apollo.BaseMutationOptions<
  Create_Branch_ContactMutation,
  Create_Branch_ContactMutationVariables
>;
export const Get_BranchDocument = gql`
  query GET_BRANCH($location: String) {
    branch {
      branchFinder(location: $location) {
        id
      }
    }
  }
`;

/**
 * __useGet_BranchQuery__
 *
 * To run a query within a React component, call `useGet_BranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_BranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_BranchQuery({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useGet_BranchQuery(baseOptions?: Apollo.QueryHookOptions<Get_BranchQuery, Get_BranchQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_BranchQuery, Get_BranchQueryVariables>(Get_BranchDocument, options);
}
export function useGet_BranchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_BranchQuery, Get_BranchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_BranchQuery, Get_BranchQueryVariables>(Get_BranchDocument, options);
}
export type Get_BranchQueryHookResult = ReturnType<typeof useGet_BranchQuery>;
export type Get_BranchLazyQueryHookResult = ReturnType<typeof useGet_BranchLazyQuery>;
export type Get_BranchQueryResult = Apollo.QueryResult<Get_BranchQuery, Get_BranchQueryVariables>;
export const GetBranchByIdDocument = gql`
  query GetBranchById($id: ID!) {
    branch {
      branch(id: $id) {
        id
        name
        relativeUrl
        isSales
        isLettings
        isRiverside
        isCentralLondon
        salesLat
        salesLon
        lettingsLat
        lettingsLon
        openingHours
        mediaSet {
          media {
            media {
              filePath
              width
              height
              filename
              mimeType
              alternativeText
            }
            role
          }
        }
        businessProfile {
          description
          branchArea
          businessName
          businessId
          latLon
          isSales
          isLettings
          latLonLettings
          mondayOpening
          tuesdayOpening
          wednesdayOpening
          thursdayOpening
          fridayOpening
          saturdayOpening
          sundayOpening
          bankHolidayOpening
          additionalOpening
          lettingsAdditionalOpening
          lettingsName
          uri
          salesContactDetails {
            unit
            telephone
            streetname
            state
            postcode
            managerTitle
            manager
            longitude
            latitude
            fax
            email
            country
            city
            photo {
              caption
              imagePath
              largestImagePath
              width
              height
              filename
              mimeType
            }
          }
          lettingsContactDetails {
            unit
            telephone
            streetname
            state
            postcode
            managerTitle
            manager
            longitude
            latitude
            fax
            email
            country
            city
            photo {
              caption
              imagePath
              largestImagePath
              width
              height
              filename
              mimeType
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetBranchByIdQuery__
 *
 * To run a query within a React component, call `useGetBranchByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBranchByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetBranchByIdQuery, GetBranchByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBranchByIdQuery, GetBranchByIdQueryVariables>(GetBranchByIdDocument, options);
}
export function useGetBranchByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBranchByIdQuery, GetBranchByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBranchByIdQuery, GetBranchByIdQueryVariables>(GetBranchByIdDocument, options);
}
export type GetBranchByIdQueryHookResult = ReturnType<typeof useGetBranchByIdQuery>;
export type GetBranchByIdLazyQueryHookResult = ReturnType<typeof useGetBranchByIdLazyQuery>;
export type GetBranchByIdQueryResult = Apollo.QueryResult<GetBranchByIdQuery, GetBranchByIdQueryVariables>;
export const Create_Build_To_RentDocument = gql`
  mutation CREATE_BUILD_TO_RENT($formSubmission: FormSubmissionInput!) {
    form {
      createFormSubmission(formSubmission: $formSubmission) {
        errorMessage
        success
      }
    }
  }
`;
export type Create_Build_To_RentMutationFn = Apollo.MutationFunction<
  Create_Build_To_RentMutation,
  Create_Build_To_RentMutationVariables
>;

/**
 * __useCreate_Build_To_RentMutation__
 *
 * To run a mutation, you first call `useCreate_Build_To_RentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Build_To_RentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBuildToRentMutation, { data, loading, error }] = useCreate_Build_To_RentMutation({
 *   variables: {
 *      formSubmission: // value for 'formSubmission'
 *   },
 * });
 */
export function useCreate_Build_To_RentMutation(
  baseOptions?: Apollo.MutationHookOptions<Create_Build_To_RentMutation, Create_Build_To_RentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Create_Build_To_RentMutation, Create_Build_To_RentMutationVariables>(
    Create_Build_To_RentDocument,
    options
  );
}
export type Create_Build_To_RentMutationHookResult = ReturnType<typeof useCreate_Build_To_RentMutation>;
export type Create_Build_To_RentMutationResult = Apollo.MutationResult<Create_Build_To_RentMutation>;
export type Create_Build_To_RentMutationOptions = Apollo.BaseMutationOptions<
  Create_Build_To_RentMutation,
  Create_Build_To_RentMutationVariables
>;
export const Create_Careers_ContactDocument = gql`
  mutation CREATE_CAREERS_CONTACT($formSubmission: FormSubmissionInput!) {
    form {
      createFormSubmission(formSubmission: $formSubmission) {
        errorMessage
        success
      }
    }
  }
`;
export type Create_Careers_ContactMutationFn = Apollo.MutationFunction<
  Create_Careers_ContactMutation,
  Create_Careers_ContactMutationVariables
>;

/**
 * __useCreate_Careers_ContactMutation__
 *
 * To run a mutation, you first call `useCreate_Careers_ContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Careers_ContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCareersContactMutation, { data, loading, error }] = useCreate_Careers_ContactMutation({
 *   variables: {
 *      formSubmission: // value for 'formSubmission'
 *   },
 * });
 */
export function useCreate_Careers_ContactMutation(
  baseOptions?: Apollo.MutationHookOptions<Create_Careers_ContactMutation, Create_Careers_ContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Create_Careers_ContactMutation, Create_Careers_ContactMutationVariables>(
    Create_Careers_ContactDocument,
    options
  );
}
export type Create_Careers_ContactMutationHookResult = ReturnType<typeof useCreate_Careers_ContactMutation>;
export type Create_Careers_ContactMutationResult = Apollo.MutationResult<Create_Careers_ContactMutation>;
export type Create_Careers_ContactMutationOptions = Apollo.BaseMutationOptions<
  Create_Careers_ContactMutation,
  Create_Careers_ContactMutationVariables
>;
export const ClientDetailsDocument = gql`
  query clientDetails {
    client {
      clientDetails {
        ipAddress
      }
    }
  }
`;

/**
 * __useClientDetailsQuery__
 *
 * To run a query within a React component, call `useClientDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<ClientDetailsQuery, ClientDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientDetailsQuery, ClientDetailsQueryVariables>(ClientDetailsDocument, options);
}
export function useClientDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClientDetailsQuery, ClientDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientDetailsQuery, ClientDetailsQueryVariables>(ClientDetailsDocument, options);
}
export type ClientDetailsQueryHookResult = ReturnType<typeof useClientDetailsQuery>;
export type ClientDetailsLazyQueryHookResult = ReturnType<typeof useClientDetailsLazyQuery>;
export type ClientDetailsQueryResult = Apollo.QueryResult<ClientDetailsQuery, ClientDetailsQueryVariables>;
export const Create_Arrange_A_ViewingDocument = gql`
  mutation CREATE_ARRANGE_A_VIEWING($data: ArrangeViewingInput!) {
    lead {
      createArrangeViewing(arrangeViewing: $data) {
        errorMessage
        success
      }
    }
  }
`;
export type Create_Arrange_A_ViewingMutationFn = Apollo.MutationFunction<
  Create_Arrange_A_ViewingMutation,
  Create_Arrange_A_ViewingMutationVariables
>;

/**
 * __useCreate_Arrange_A_ViewingMutation__
 *
 * To run a mutation, you first call `useCreate_Arrange_A_ViewingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Arrange_A_ViewingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArrangeAViewingMutation, { data, loading, error }] = useCreate_Arrange_A_ViewingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreate_Arrange_A_ViewingMutation(
  baseOptions?: Apollo.MutationHookOptions<Create_Arrange_A_ViewingMutation, Create_Arrange_A_ViewingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Create_Arrange_A_ViewingMutation, Create_Arrange_A_ViewingMutationVariables>(
    Create_Arrange_A_ViewingDocument,
    options
  );
}
export type Create_Arrange_A_ViewingMutationHookResult = ReturnType<typeof useCreate_Arrange_A_ViewingMutation>;
export type Create_Arrange_A_ViewingMutationResult = Apollo.MutationResult<Create_Arrange_A_ViewingMutation>;
export type Create_Arrange_A_ViewingMutationOptions = Apollo.BaseMutationOptions<
  Create_Arrange_A_ViewingMutation,
  Create_Arrange_A_ViewingMutationVariables
>;
export const Get_Single_Branch_CardDocument = gql`
  query GET_SINGLE_BRANCH_CARD($branchId: ID) {
    branch {
      branch(id: $branchId) {
        id
        remoteId: id
        name
        relativeUrl
        googleMapLinkResales
        googleMapLinkRental
        googleMapsSalesOverride
        googleMapsLettingsOverride
        isWhatsAppEnabled
        isWhatsAppLettingsEnabled
        mediaSet {
          name
          media {
            role
            media {
              filePath
              width
              height
              filename
              mimeType
              alternativeText
            }
          }
        }
        businessProfile {
          salesContactDetails {
            ...BranchContactDetailsCF
          }
          lettingsContactDetails {
            ...BranchContactDetailsCF
          }
        }
      }
    }
  }
  ${BranchContactDetailsCfFragmentDoc}
`;

/**
 * __useGet_Single_Branch_CardQuery__
 *
 * To run a query within a React component, call `useGet_Single_Branch_CardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Single_Branch_CardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Single_Branch_CardQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useGet_Single_Branch_CardQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Single_Branch_CardQuery, Get_Single_Branch_CardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Single_Branch_CardQuery, Get_Single_Branch_CardQueryVariables>(
    Get_Single_Branch_CardDocument,
    options
  );
}
export function useGet_Single_Branch_CardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Single_Branch_CardQuery, Get_Single_Branch_CardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Single_Branch_CardQuery, Get_Single_Branch_CardQueryVariables>(
    Get_Single_Branch_CardDocument,
    options
  );
}
export type Get_Single_Branch_CardQueryHookResult = ReturnType<typeof useGet_Single_Branch_CardQuery>;
export type Get_Single_Branch_CardLazyQueryHookResult = ReturnType<typeof useGet_Single_Branch_CardLazyQuery>;
export type Get_Single_Branch_CardQueryResult = Apollo.QueryResult<
  Get_Single_Branch_CardQuery,
  Get_Single_Branch_CardQueryVariables
>;
export const Create_Corporate_Relocation_QuoteDocument = gql`
  mutation CREATE_CORPORATE_RELOCATION_QUOTE($formSubmission: FormSubmissionInput!) {
    form {
      createFormSubmission(formSubmission: $formSubmission) {
        errorMessage
        success
      }
    }
  }
`;
export type Create_Corporate_Relocation_QuoteMutationFn = Apollo.MutationFunction<
  Create_Corporate_Relocation_QuoteMutation,
  Create_Corporate_Relocation_QuoteMutationVariables
>;

/**
 * __useCreate_Corporate_Relocation_QuoteMutation__
 *
 * To run a mutation, you first call `useCreate_Corporate_Relocation_QuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Corporate_Relocation_QuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCorporateRelocationQuoteMutation, { data, loading, error }] = useCreate_Corporate_Relocation_QuoteMutation({
 *   variables: {
 *      formSubmission: // value for 'formSubmission'
 *   },
 * });
 */
export function useCreate_Corporate_Relocation_QuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Create_Corporate_Relocation_QuoteMutation,
    Create_Corporate_Relocation_QuoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Create_Corporate_Relocation_QuoteMutation,
    Create_Corporate_Relocation_QuoteMutationVariables
  >(Create_Corporate_Relocation_QuoteDocument, options);
}
export type Create_Corporate_Relocation_QuoteMutationHookResult = ReturnType<
  typeof useCreate_Corporate_Relocation_QuoteMutation
>;
export type Create_Corporate_Relocation_QuoteMutationResult =
  Apollo.MutationResult<Create_Corporate_Relocation_QuoteMutation>;
export type Create_Corporate_Relocation_QuoteMutationOptions = Apollo.BaseMutationOptions<
  Create_Corporate_Relocation_QuoteMutation,
  Create_Corporate_Relocation_QuoteMutationVariables
>;
export const Create_Financial_ServicesDocument = gql`
  mutation CREATE_FINANCIAL_SERVICES($formSubmission: FormSubmissionInput!) {
    form {
      createFormSubmission(formSubmission: $formSubmission) {
        errorMessage
        success
      }
    }
  }
`;
export type Create_Financial_ServicesMutationFn = Apollo.MutationFunction<
  Create_Financial_ServicesMutation,
  Create_Financial_ServicesMutationVariables
>;

/**
 * __useCreate_Financial_ServicesMutation__
 *
 * To run a mutation, you first call `useCreate_Financial_ServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Financial_ServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFinancialServicesMutation, { data, loading, error }] = useCreate_Financial_ServicesMutation({
 *   variables: {
 *      formSubmission: // value for 'formSubmission'
 *   },
 * });
 */
export function useCreate_Financial_ServicesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Create_Financial_ServicesMutation,
    Create_Financial_ServicesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Create_Financial_ServicesMutation, Create_Financial_ServicesMutationVariables>(
    Create_Financial_ServicesDocument,
    options
  );
}
export type Create_Financial_ServicesMutationHookResult = ReturnType<typeof useCreate_Financial_ServicesMutation>;
export type Create_Financial_ServicesMutationResult = Apollo.MutationResult<Create_Financial_ServicesMutation>;
export type Create_Financial_ServicesMutationOptions = Apollo.BaseMutationOptions<
  Create_Financial_ServicesMutation,
  Create_Financial_ServicesMutationVariables
>;
export const Create_Landlord_LocatorDocument = gql`
  mutation CREATE_LANDLORD_LOCATOR($formSubmission: FormSubmissionInput!) {
    form {
      createFormSubmission(formSubmission: $formSubmission) {
        errorMessage
        success
      }
    }
  }
`;
export type Create_Landlord_LocatorMutationFn = Apollo.MutationFunction<
  Create_Landlord_LocatorMutation,
  Create_Landlord_LocatorMutationVariables
>;

/**
 * __useCreate_Landlord_LocatorMutation__
 *
 * To run a mutation, you first call `useCreate_Landlord_LocatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Landlord_LocatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLandlordLocatorMutation, { data, loading, error }] = useCreate_Landlord_LocatorMutation({
 *   variables: {
 *      formSubmission: // value for 'formSubmission'
 *   },
 * });
 */
export function useCreate_Landlord_LocatorMutation(
  baseOptions?: Apollo.MutationHookOptions<Create_Landlord_LocatorMutation, Create_Landlord_LocatorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Create_Landlord_LocatorMutation, Create_Landlord_LocatorMutationVariables>(
    Create_Landlord_LocatorDocument,
    options
  );
}
export type Create_Landlord_LocatorMutationHookResult = ReturnType<typeof useCreate_Landlord_LocatorMutation>;
export type Create_Landlord_LocatorMutationResult = Apollo.MutationResult<Create_Landlord_LocatorMutation>;
export type Create_Landlord_LocatorMutationOptions = Apollo.BaseMutationOptions<
  Create_Landlord_LocatorMutation,
  Create_Landlord_LocatorMutationVariables
>;
export const Create_Lease_Extension_QuoteDocument = gql`
  mutation CREATE_LEASE_EXTENSION_QUOTE($formSubmission: FormSubmissionInput!) {
    form {
      createFormSubmission(formSubmission: $formSubmission) {
        errorMessage
        success
      }
    }
  }
`;
export type Create_Lease_Extension_QuoteMutationFn = Apollo.MutationFunction<
  Create_Lease_Extension_QuoteMutation,
  Create_Lease_Extension_QuoteMutationVariables
>;

/**
 * __useCreate_Lease_Extension_QuoteMutation__
 *
 * To run a mutation, you first call `useCreate_Lease_Extension_QuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Lease_Extension_QuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLeaseExtensionQuoteMutation, { data, loading, error }] = useCreate_Lease_Extension_QuoteMutation({
 *   variables: {
 *      formSubmission: // value for 'formSubmission'
 *   },
 * });
 */
export function useCreate_Lease_Extension_QuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Create_Lease_Extension_QuoteMutation,
    Create_Lease_Extension_QuoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Create_Lease_Extension_QuoteMutation, Create_Lease_Extension_QuoteMutationVariables>(
    Create_Lease_Extension_QuoteDocument,
    options
  );
}
export type Create_Lease_Extension_QuoteMutationHookResult = ReturnType<typeof useCreate_Lease_Extension_QuoteMutation>;
export type Create_Lease_Extension_QuoteMutationResult = Apollo.MutationResult<Create_Lease_Extension_QuoteMutation>;
export type Create_Lease_Extension_QuoteMutationOptions = Apollo.BaseMutationOptions<
  Create_Lease_Extension_QuoteMutation,
  Create_Lease_Extension_QuoteMutationVariables
>;
export const Get_LocationDocument = gql`
  query GET_LOCATION($location: String) {
    location {
      areaGuideFinder(location: $location) {
        name
        id
        ... on RegionType {
          id
          name
          relativeUrl
        }
        ... on AreaType {
          id
          name
          relativeUrl
        }
        ... on PostcodePartType {
          id
          name
          areas {
            relativeUrl
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGet_LocationQuery__
 *
 * To run a query within a React component, call `useGet_LocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_LocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_LocationQuery({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useGet_LocationQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_LocationQuery, Get_LocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_LocationQuery, Get_LocationQueryVariables>(Get_LocationDocument, options);
}
export function useGet_LocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_LocationQuery, Get_LocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_LocationQuery, Get_LocationQueryVariables>(Get_LocationDocument, options);
}
export type Get_LocationQueryHookResult = ReturnType<typeof useGet_LocationQuery>;
export type Get_LocationLazyQueryHookResult = ReturnType<typeof useGet_LocationLazyQuery>;
export type Get_LocationQueryResult = Apollo.QueryResult<Get_LocationQuery, Get_LocationQueryVariables>;
export const MyKfh_LoginDocument = gql`
  mutation myKFH_login($myKfhLogin: MyKfhLoginInput!) {
    myKfh {
      mykfhLogin(myKfhLogin: $myKfhLogin) {
        email
        success
        errorCode
        errorDescription
        tokenType
        accessToken
        expiresIn
        refreshToken
        scope
      }
    }
  }
`;
export type MyKfh_LoginMutationFn = Apollo.MutationFunction<MyKfh_LoginMutation, MyKfh_LoginMutationVariables>;

/**
 * __useMyKfh_LoginMutation__
 *
 * To run a mutation, you first call `useMyKfh_LoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMyKfh_LoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [myKfhLoginMutation, { data, loading, error }] = useMyKfh_LoginMutation({
 *   variables: {
 *      myKfhLogin: // value for 'myKfhLogin'
 *   },
 * });
 */
export function useMyKfh_LoginMutation(
  baseOptions?: Apollo.MutationHookOptions<MyKfh_LoginMutation, MyKfh_LoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MyKfh_LoginMutation, MyKfh_LoginMutationVariables>(MyKfh_LoginDocument, options);
}
export type MyKfh_LoginMutationHookResult = ReturnType<typeof useMyKfh_LoginMutation>;
export type MyKfh_LoginMutationResult = Apollo.MutationResult<MyKfh_LoginMutation>;
export type MyKfh_LoginMutationOptions = Apollo.BaseMutationOptions<MyKfh_LoginMutation, MyKfh_LoginMutationVariables>;
export const My_Kfh_RegisterDocument = gql`
  mutation MY_KFH_REGISTER($myKfhRegister: MyKfhRegisterInput!) {
    myKfh {
      myKfhRegister(myKfhRegister: $myKfhRegister) {
        success
        errorCode
        errorDescription
        status
        user {
          id
          clientApplicationId
          firstName
          lastName
          email
          confirmationToken
          resetPasswordToken
          profile {
            aboutMe
            marketingConsent
            marketingConsentAccount
            marketingConsentShortlist
          }
        }
      }
    }
  }
`;
export type My_Kfh_RegisterMutationFn = Apollo.MutationFunction<
  My_Kfh_RegisterMutation,
  My_Kfh_RegisterMutationVariables
>;

/**
 * __useMy_Kfh_RegisterMutation__
 *
 * To run a mutation, you first call `useMy_Kfh_RegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMy_Kfh_RegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [myKfhRegisterMutation, { data, loading, error }] = useMy_Kfh_RegisterMutation({
 *   variables: {
 *      myKfhRegister: // value for 'myKfhRegister'
 *   },
 * });
 */
export function useMy_Kfh_RegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<My_Kfh_RegisterMutation, My_Kfh_RegisterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<My_Kfh_RegisterMutation, My_Kfh_RegisterMutationVariables>(
    My_Kfh_RegisterDocument,
    options
  );
}
export type My_Kfh_RegisterMutationHookResult = ReturnType<typeof useMy_Kfh_RegisterMutation>;
export type My_Kfh_RegisterMutationResult = Apollo.MutationResult<My_Kfh_RegisterMutation>;
export type My_Kfh_RegisterMutationOptions = Apollo.BaseMutationOptions<
  My_Kfh_RegisterMutation,
  My_Kfh_RegisterMutationVariables
>;
export const My_Kfh_Update_AccountDocument = gql`
  mutation MY_KFH_UPDATE_ACCOUNT($myKfhUpdateAccount: MyKfhUpdateAccountInput!) {
    myKfh {
      myKfhUpdateAccount(myKfhUpdateAccount: $myKfhUpdateAccount) {
        success
        status
        errorCode
        errorDescription
        artirixResponse
        user {
          id
          clientApplicationId
          firstName
          lastName
          email
          confirmationToken
          resetPasswordToken
          profile {
            aboutMe
            marketingConsent
            marketingConsentAccount
            marketingConsentShortlist
          }
        }
      }
    }
  }
`;
export type My_Kfh_Update_AccountMutationFn = Apollo.MutationFunction<
  My_Kfh_Update_AccountMutation,
  My_Kfh_Update_AccountMutationVariables
>;

/**
 * __useMy_Kfh_Update_AccountMutation__
 *
 * To run a mutation, you first call `useMy_Kfh_Update_AccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMy_Kfh_Update_AccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [myKfhUpdateAccountMutation, { data, loading, error }] = useMy_Kfh_Update_AccountMutation({
 *   variables: {
 *      myKfhUpdateAccount: // value for 'myKfhUpdateAccount'
 *   },
 * });
 */
export function useMy_Kfh_Update_AccountMutation(
  baseOptions?: Apollo.MutationHookOptions<My_Kfh_Update_AccountMutation, My_Kfh_Update_AccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<My_Kfh_Update_AccountMutation, My_Kfh_Update_AccountMutationVariables>(
    My_Kfh_Update_AccountDocument,
    options
  );
}
export type My_Kfh_Update_AccountMutationHookResult = ReturnType<typeof useMy_Kfh_Update_AccountMutation>;
export type My_Kfh_Update_AccountMutationResult = Apollo.MutationResult<My_Kfh_Update_AccountMutation>;
export type My_Kfh_Update_AccountMutationOptions = Apollo.BaseMutationOptions<
  My_Kfh_Update_AccountMutation,
  My_Kfh_Update_AccountMutationVariables
>;
export const Mykfh_Update_Saved_SearchDocument = gql`
  mutation MYKFH_UPDATE_SAVED_SEARCH($myKfhUpdateSavedSearch: MyKfhUpdateSavedSearchInput!) {
    myKfh {
      myKfhUpdateSavedSearch(myKfhUpdateSavedSearch: $myKfhUpdateSavedSearch) {
        success
      }
    }
  }
`;
export type Mykfh_Update_Saved_SearchMutationFn = Apollo.MutationFunction<
  Mykfh_Update_Saved_SearchMutation,
  Mykfh_Update_Saved_SearchMutationVariables
>;

/**
 * __useMykfh_Update_Saved_SearchMutation__
 *
 * To run a mutation, you first call `useMykfh_Update_Saved_SearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMykfh_Update_Saved_SearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mykfhUpdateSavedSearchMutation, { data, loading, error }] = useMykfh_Update_Saved_SearchMutation({
 *   variables: {
 *      myKfhUpdateSavedSearch: // value for 'myKfhUpdateSavedSearch'
 *   },
 * });
 */
export function useMykfh_Update_Saved_SearchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Mykfh_Update_Saved_SearchMutation,
    Mykfh_Update_Saved_SearchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Mykfh_Update_Saved_SearchMutation, Mykfh_Update_Saved_SearchMutationVariables>(
    Mykfh_Update_Saved_SearchDocument,
    options
  );
}
export type Mykfh_Update_Saved_SearchMutationHookResult = ReturnType<typeof useMykfh_Update_Saved_SearchMutation>;
export type Mykfh_Update_Saved_SearchMutationResult = Apollo.MutationResult<Mykfh_Update_Saved_SearchMutation>;
export type Mykfh_Update_Saved_SearchMutationOptions = Apollo.BaseMutationOptions<
  Mykfh_Update_Saved_SearchMutation,
  Mykfh_Update_Saved_SearchMutationVariables
>;
export const Mykfh_Create_Saved_SearchDocument = gql`
  mutation MYKFH_CREATE_SAVED_SEARCH($myKfhCreateSavedSearch: MyKfhCreateSavedSearchInput!) {
    myKfh {
      myKfhCreateSavedSearch(myKfhCreateSavedSearch: $myKfhCreateSavedSearch) {
        success
        artirixResponse
        errorCode
        errorDescription
      }
    }
  }
`;
export type Mykfh_Create_Saved_SearchMutationFn = Apollo.MutationFunction<
  Mykfh_Create_Saved_SearchMutation,
  Mykfh_Create_Saved_SearchMutationVariables
>;

/**
 * __useMykfh_Create_Saved_SearchMutation__
 *
 * To run a mutation, you first call `useMykfh_Create_Saved_SearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMykfh_Create_Saved_SearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mykfhCreateSavedSearchMutation, { data, loading, error }] = useMykfh_Create_Saved_SearchMutation({
 *   variables: {
 *      myKfhCreateSavedSearch: // value for 'myKfhCreateSavedSearch'
 *   },
 * });
 */
export function useMykfh_Create_Saved_SearchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Mykfh_Create_Saved_SearchMutation,
    Mykfh_Create_Saved_SearchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Mykfh_Create_Saved_SearchMutation, Mykfh_Create_Saved_SearchMutationVariables>(
    Mykfh_Create_Saved_SearchDocument,
    options
  );
}
export type Mykfh_Create_Saved_SearchMutationHookResult = ReturnType<typeof useMykfh_Create_Saved_SearchMutation>;
export type Mykfh_Create_Saved_SearchMutationResult = Apollo.MutationResult<Mykfh_Create_Saved_SearchMutation>;
export type Mykfh_Create_Saved_SearchMutationOptions = Apollo.BaseMutationOptions<
  Mykfh_Create_Saved_SearchMutation,
  Mykfh_Create_Saved_SearchMutationVariables
>;
export const Mykfh_Delete_Saved_SearchDocument = gql`
  mutation MYKFH_DELETE_SAVED_SEARCH($myKfhDeleteSavedSearch: MyKfhDeleteSavedSearchInput!) {
    myKfh {
      myKfhDeleteSavedSearch(myKfhDeleteSavedSearch: $myKfhDeleteSavedSearch) {
        success
      }
    }
  }
`;
export type Mykfh_Delete_Saved_SearchMutationFn = Apollo.MutationFunction<
  Mykfh_Delete_Saved_SearchMutation,
  Mykfh_Delete_Saved_SearchMutationVariables
>;

/**
 * __useMykfh_Delete_Saved_SearchMutation__
 *
 * To run a mutation, you first call `useMykfh_Delete_Saved_SearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMykfh_Delete_Saved_SearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mykfhDeleteSavedSearchMutation, { data, loading, error }] = useMykfh_Delete_Saved_SearchMutation({
 *   variables: {
 *      myKfhDeleteSavedSearch: // value for 'myKfhDeleteSavedSearch'
 *   },
 * });
 */
export function useMykfh_Delete_Saved_SearchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Mykfh_Delete_Saved_SearchMutation,
    Mykfh_Delete_Saved_SearchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Mykfh_Delete_Saved_SearchMutation, Mykfh_Delete_Saved_SearchMutationVariables>(
    Mykfh_Delete_Saved_SearchDocument,
    options
  );
}
export type Mykfh_Delete_Saved_SearchMutationHookResult = ReturnType<typeof useMykfh_Delete_Saved_SearchMutation>;
export type Mykfh_Delete_Saved_SearchMutationResult = Apollo.MutationResult<Mykfh_Delete_Saved_SearchMutation>;
export type Mykfh_Delete_Saved_SearchMutationOptions = Apollo.BaseMutationOptions<
  Mykfh_Delete_Saved_SearchMutation,
  Mykfh_Delete_Saved_SearchMutationVariables
>;
export const Mykfh_Change_PasswordDocument = gql`
  mutation MYKFH_CHANGE_PASSWORD($myKfhChangePassword: MyKfhChangePasswordInput!) {
    myKfh {
      myKfhChangePassword(myKfhChangePassword: $myKfhChangePassword) {
        success
        errorDescription
        status
      }
    }
  }
`;
export type Mykfh_Change_PasswordMutationFn = Apollo.MutationFunction<
  Mykfh_Change_PasswordMutation,
  Mykfh_Change_PasswordMutationVariables
>;

/**
 * __useMykfh_Change_PasswordMutation__
 *
 * To run a mutation, you first call `useMykfh_Change_PasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMykfh_Change_PasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mykfhChangePasswordMutation, { data, loading, error }] = useMykfh_Change_PasswordMutation({
 *   variables: {
 *      myKfhChangePassword: // value for 'myKfhChangePassword'
 *   },
 * });
 */
export function useMykfh_Change_PasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<Mykfh_Change_PasswordMutation, Mykfh_Change_PasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Mykfh_Change_PasswordMutation, Mykfh_Change_PasswordMutationVariables>(
    Mykfh_Change_PasswordDocument,
    options
  );
}
export type Mykfh_Change_PasswordMutationHookResult = ReturnType<typeof useMykfh_Change_PasswordMutation>;
export type Mykfh_Change_PasswordMutationResult = Apollo.MutationResult<Mykfh_Change_PasswordMutation>;
export type Mykfh_Change_PasswordMutationOptions = Apollo.BaseMutationOptions<
  Mykfh_Change_PasswordMutation,
  Mykfh_Change_PasswordMutationVariables
>;
export const Mykfh_Create_Saved_PropertyDocument = gql`
  mutation MYKFH_CREATE_SAVED_PROPERTY($myKfhCreateSavedProperty: MyKfhCreateSavedPropertyInput!) {
    myKfh {
      myKfhCreateSavedProperty(myKfhCreateSavedProperty: $myKfhCreateSavedProperty) {
        success
        savedProperty {
          id
          uri
          userId
          deliverAlert
        }
      }
    }
  }
`;
export type Mykfh_Create_Saved_PropertyMutationFn = Apollo.MutationFunction<
  Mykfh_Create_Saved_PropertyMutation,
  Mykfh_Create_Saved_PropertyMutationVariables
>;

/**
 * __useMykfh_Create_Saved_PropertyMutation__
 *
 * To run a mutation, you first call `useMykfh_Create_Saved_PropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMykfh_Create_Saved_PropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mykfhCreateSavedPropertyMutation, { data, loading, error }] = useMykfh_Create_Saved_PropertyMutation({
 *   variables: {
 *      myKfhCreateSavedProperty: // value for 'myKfhCreateSavedProperty'
 *   },
 * });
 */
export function useMykfh_Create_Saved_PropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Mykfh_Create_Saved_PropertyMutation,
    Mykfh_Create_Saved_PropertyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Mykfh_Create_Saved_PropertyMutation, Mykfh_Create_Saved_PropertyMutationVariables>(
    Mykfh_Create_Saved_PropertyDocument,
    options
  );
}
export type Mykfh_Create_Saved_PropertyMutationHookResult = ReturnType<typeof useMykfh_Create_Saved_PropertyMutation>;
export type Mykfh_Create_Saved_PropertyMutationResult = Apollo.MutationResult<Mykfh_Create_Saved_PropertyMutation>;
export type Mykfh_Create_Saved_PropertyMutationOptions = Apollo.BaseMutationOptions<
  Mykfh_Create_Saved_PropertyMutation,
  Mykfh_Create_Saved_PropertyMutationVariables
>;
export const Mykfh_Delete_Saved_PropertyDocument = gql`
  mutation MYKFH_DELETE_SAVED_PROPERTY($myKfhDeleteSavedProperty: MyKfhDeleteSavedPropertyInput!) {
    myKfh {
      myKfhDeleteSavedProperty(myKfhDeleteSavedProperty: $myKfhDeleteSavedProperty) {
        success
        artirixResponse
        errorCode
        errorDescription
      }
    }
  }
`;
export type Mykfh_Delete_Saved_PropertyMutationFn = Apollo.MutationFunction<
  Mykfh_Delete_Saved_PropertyMutation,
  Mykfh_Delete_Saved_PropertyMutationVariables
>;

/**
 * __useMykfh_Delete_Saved_PropertyMutation__
 *
 * To run a mutation, you first call `useMykfh_Delete_Saved_PropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMykfh_Delete_Saved_PropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mykfhDeleteSavedPropertyMutation, { data, loading, error }] = useMykfh_Delete_Saved_PropertyMutation({
 *   variables: {
 *      myKfhDeleteSavedProperty: // value for 'myKfhDeleteSavedProperty'
 *   },
 * });
 */
export function useMykfh_Delete_Saved_PropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Mykfh_Delete_Saved_PropertyMutation,
    Mykfh_Delete_Saved_PropertyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Mykfh_Delete_Saved_PropertyMutation, Mykfh_Delete_Saved_PropertyMutationVariables>(
    Mykfh_Delete_Saved_PropertyDocument,
    options
  );
}
export type Mykfh_Delete_Saved_PropertyMutationHookResult = ReturnType<typeof useMykfh_Delete_Saved_PropertyMutation>;
export type Mykfh_Delete_Saved_PropertyMutationResult = Apollo.MutationResult<Mykfh_Delete_Saved_PropertyMutation>;
export type Mykfh_Delete_Saved_PropertyMutationOptions = Apollo.BaseMutationOptions<
  Mykfh_Delete_Saved_PropertyMutation,
  Mykfh_Delete_Saved_PropertyMutationVariables
>;
export const Mykfh_Forgot_PasswordDocument = gql`
  mutation MYKFH_FORGOT_PASSWORD($myKfhForgotPassword: MyKfhForgotPasswordInput!) {
    myKfh {
      myKfhForgotPassword(myKfhForgotPassword: $myKfhForgotPassword) {
        success
        errorDescription
        status
      }
    }
  }
`;
export type Mykfh_Forgot_PasswordMutationFn = Apollo.MutationFunction<
  Mykfh_Forgot_PasswordMutation,
  Mykfh_Forgot_PasswordMutationVariables
>;

/**
 * __useMykfh_Forgot_PasswordMutation__
 *
 * To run a mutation, you first call `useMykfh_Forgot_PasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMykfh_Forgot_PasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mykfhForgotPasswordMutation, { data, loading, error }] = useMykfh_Forgot_PasswordMutation({
 *   variables: {
 *      myKfhForgotPassword: // value for 'myKfhForgotPassword'
 *   },
 * });
 */
export function useMykfh_Forgot_PasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<Mykfh_Forgot_PasswordMutation, Mykfh_Forgot_PasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Mykfh_Forgot_PasswordMutation, Mykfh_Forgot_PasswordMutationVariables>(
    Mykfh_Forgot_PasswordDocument,
    options
  );
}
export type Mykfh_Forgot_PasswordMutationHookResult = ReturnType<typeof useMykfh_Forgot_PasswordMutation>;
export type Mykfh_Forgot_PasswordMutationResult = Apollo.MutationResult<Mykfh_Forgot_PasswordMutation>;
export type Mykfh_Forgot_PasswordMutationOptions = Apollo.BaseMutationOptions<
  Mykfh_Forgot_PasswordMutation,
  Mykfh_Forgot_PasswordMutationVariables
>;
export const Mykfh_Reset_PasswordDocument = gql`
  mutation MYKFH_RESET_PASSWORD($myKfhResetPassword: MyKfhResetPasswordInput!) {
    myKfh {
      myKfhResetPassword(myKfhResetPassword: $myKfhResetPassword) {
        success
        errorDescription
        status
      }
    }
  }
`;
export type Mykfh_Reset_PasswordMutationFn = Apollo.MutationFunction<
  Mykfh_Reset_PasswordMutation,
  Mykfh_Reset_PasswordMutationVariables
>;

/**
 * __useMykfh_Reset_PasswordMutation__
 *
 * To run a mutation, you first call `useMykfh_Reset_PasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMykfh_Reset_PasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mykfhResetPasswordMutation, { data, loading, error }] = useMykfh_Reset_PasswordMutation({
 *   variables: {
 *      myKfhResetPassword: // value for 'myKfhResetPassword'
 *   },
 * });
 */
export function useMykfh_Reset_PasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<Mykfh_Reset_PasswordMutation, Mykfh_Reset_PasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Mykfh_Reset_PasswordMutation, Mykfh_Reset_PasswordMutationVariables>(
    Mykfh_Reset_PasswordDocument,
    options
  );
}
export type Mykfh_Reset_PasswordMutationHookResult = ReturnType<typeof useMykfh_Reset_PasswordMutation>;
export type Mykfh_Reset_PasswordMutationResult = Apollo.MutationResult<Mykfh_Reset_PasswordMutation>;
export type Mykfh_Reset_PasswordMutationOptions = Apollo.BaseMutationOptions<
  Mykfh_Reset_PasswordMutation,
  Mykfh_Reset_PasswordMutationVariables
>;
export const Mykfh_Update_Saved_PropertyDocument = gql`
  mutation MYKFH_UPDATE_SAVED_PROPERTY($myKfhUpdateSavedProperty: MyKfhUpdateSavedPropertyInput!) {
    myKfh {
      myKfhUpdateSavedProperty(myKfhUpdateSavedProperty: $myKfhUpdateSavedProperty) {
        success
        savedProperty {
          id
          uri
          userId
          deliverAlert
        }
      }
    }
  }
`;
export type Mykfh_Update_Saved_PropertyMutationFn = Apollo.MutationFunction<
  Mykfh_Update_Saved_PropertyMutation,
  Mykfh_Update_Saved_PropertyMutationVariables
>;

/**
 * __useMykfh_Update_Saved_PropertyMutation__
 *
 * To run a mutation, you first call `useMykfh_Update_Saved_PropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMykfh_Update_Saved_PropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mykfhUpdateSavedPropertyMutation, { data, loading, error }] = useMykfh_Update_Saved_PropertyMutation({
 *   variables: {
 *      myKfhUpdateSavedProperty: // value for 'myKfhUpdateSavedProperty'
 *   },
 * });
 */
export function useMykfh_Update_Saved_PropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Mykfh_Update_Saved_PropertyMutation,
    Mykfh_Update_Saved_PropertyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Mykfh_Update_Saved_PropertyMutation, Mykfh_Update_Saved_PropertyMutationVariables>(
    Mykfh_Update_Saved_PropertyDocument,
    options
  );
}
export type Mykfh_Update_Saved_PropertyMutationHookResult = ReturnType<typeof useMykfh_Update_Saved_PropertyMutation>;
export type Mykfh_Update_Saved_PropertyMutationResult = Apollo.MutationResult<Mykfh_Update_Saved_PropertyMutation>;
export type Mykfh_Update_Saved_PropertyMutationOptions = Apollo.BaseMutationOptions<
  Mykfh_Update_Saved_PropertyMutation,
  Mykfh_Update_Saved_PropertyMutationVariables
>;
export const Mykfh_Delete_AccountDocument = gql`
  mutation MYKFH_DELETE_ACCOUNT($myKfhDeleteAccount: MyKfhDeleteAccountInput!) {
    myKfh {
      myKfhDeleteAccount(myKfhDeleteAccount: $myKfhDeleteAccount) {
        success
      }
    }
  }
`;
export type Mykfh_Delete_AccountMutationFn = Apollo.MutationFunction<
  Mykfh_Delete_AccountMutation,
  Mykfh_Delete_AccountMutationVariables
>;

/**
 * __useMykfh_Delete_AccountMutation__
 *
 * To run a mutation, you first call `useMykfh_Delete_AccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMykfh_Delete_AccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mykfhDeleteAccountMutation, { data, loading, error }] = useMykfh_Delete_AccountMutation({
 *   variables: {
 *      myKfhDeleteAccount: // value for 'myKfhDeleteAccount'
 *   },
 * });
 */
export function useMykfh_Delete_AccountMutation(
  baseOptions?: Apollo.MutationHookOptions<Mykfh_Delete_AccountMutation, Mykfh_Delete_AccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Mykfh_Delete_AccountMutation, Mykfh_Delete_AccountMutationVariables>(
    Mykfh_Delete_AccountDocument,
    options
  );
}
export type Mykfh_Delete_AccountMutationHookResult = ReturnType<typeof useMykfh_Delete_AccountMutation>;
export type Mykfh_Delete_AccountMutationResult = Apollo.MutationResult<Mykfh_Delete_AccountMutation>;
export type Mykfh_Delete_AccountMutationOptions = Apollo.BaseMutationOptions<
  Mykfh_Delete_AccountMutation,
  Mykfh_Delete_AccountMutationVariables
>;
export const Mykfh_Resend_Account_ActivationDocument = gql`
  mutation MYKFH_RESEND_ACCOUNT_ACTIVATION {
    myKfh {
      myKfhResendAccountActivation {
        success
        artirixResponse
        errorCode
        errorDescription
      }
    }
  }
`;
export type Mykfh_Resend_Account_ActivationMutationFn = Apollo.MutationFunction<
  Mykfh_Resend_Account_ActivationMutation,
  Mykfh_Resend_Account_ActivationMutationVariables
>;

/**
 * __useMykfh_Resend_Account_ActivationMutation__
 *
 * To run a mutation, you first call `useMykfh_Resend_Account_ActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMykfh_Resend_Account_ActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mykfhResendAccountActivationMutation, { data, loading, error }] = useMykfh_Resend_Account_ActivationMutation({
 *   variables: {
 *   },
 * });
 */
export function useMykfh_Resend_Account_ActivationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Mykfh_Resend_Account_ActivationMutation,
    Mykfh_Resend_Account_ActivationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Mykfh_Resend_Account_ActivationMutation, Mykfh_Resend_Account_ActivationMutationVariables>(
    Mykfh_Resend_Account_ActivationDocument,
    options
  );
}
export type Mykfh_Resend_Account_ActivationMutationHookResult = ReturnType<
  typeof useMykfh_Resend_Account_ActivationMutation
>;
export type Mykfh_Resend_Account_ActivationMutationResult =
  Apollo.MutationResult<Mykfh_Resend_Account_ActivationMutation>;
export type Mykfh_Resend_Account_ActivationMutationOptions = Apollo.BaseMutationOptions<
  Mykfh_Resend_Account_ActivationMutation,
  Mykfh_Resend_Account_ActivationMutationVariables
>;
export const Mykfh_Account_ActivationDocument = gql`
  mutation MYKFH_ACCOUNT_ACTIVATION($myKfhAccountActivation: MyKfhAccountActivationInput!) {
    myKfh {
      myKfhAccountActivation(myKfhAccountActivation: $myKfhAccountActivation) {
        success
        user {
          id
          firstName
          lastName
          email
        }
        errorDescription
        errorCode
        artirixResponse
      }
    }
  }
`;
export type Mykfh_Account_ActivationMutationFn = Apollo.MutationFunction<
  Mykfh_Account_ActivationMutation,
  Mykfh_Account_ActivationMutationVariables
>;

/**
 * __useMykfh_Account_ActivationMutation__
 *
 * To run a mutation, you first call `useMykfh_Account_ActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMykfh_Account_ActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mykfhAccountActivationMutation, { data, loading, error }] = useMykfh_Account_ActivationMutation({
 *   variables: {
 *      myKfhAccountActivation: // value for 'myKfhAccountActivation'
 *   },
 * });
 */
export function useMykfh_Account_ActivationMutation(
  baseOptions?: Apollo.MutationHookOptions<Mykfh_Account_ActivationMutation, Mykfh_Account_ActivationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Mykfh_Account_ActivationMutation, Mykfh_Account_ActivationMutationVariables>(
    Mykfh_Account_ActivationDocument,
    options
  );
}
export type Mykfh_Account_ActivationMutationHookResult = ReturnType<typeof useMykfh_Account_ActivationMutation>;
export type Mykfh_Account_ActivationMutationResult = Apollo.MutationResult<Mykfh_Account_ActivationMutation>;
export type Mykfh_Account_ActivationMutationOptions = Apollo.BaseMutationOptions<
  Mykfh_Account_ActivationMutation,
  Mykfh_Account_ActivationMutationVariables
>;
export const Mykfh_Read_AccountDocument = gql`
  query MYKFH_READ_ACCOUNT {
    myKfh {
      myKfhReadAccount {
        success
        errorCode
        errorDescription
        status
        user {
          id
          firstName
          lastName
          email
          profile {
            aboutMe
            marketingConsent
            marketingConsentAccount
            marketingConsentShortlist
          }
        }
      }
    }
  }
`;

/**
 * __useMykfh_Read_AccountQuery__
 *
 * To run a query within a React component, call `useMykfh_Read_AccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMykfh_Read_AccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMykfh_Read_AccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMykfh_Read_AccountQuery(
  baseOptions?: Apollo.QueryHookOptions<Mykfh_Read_AccountQuery, Mykfh_Read_AccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Mykfh_Read_AccountQuery, Mykfh_Read_AccountQueryVariables>(
    Mykfh_Read_AccountDocument,
    options
  );
}
export function useMykfh_Read_AccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Mykfh_Read_AccountQuery, Mykfh_Read_AccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Mykfh_Read_AccountQuery, Mykfh_Read_AccountQueryVariables>(
    Mykfh_Read_AccountDocument,
    options
  );
}
export type Mykfh_Read_AccountQueryHookResult = ReturnType<typeof useMykfh_Read_AccountQuery>;
export type Mykfh_Read_AccountLazyQueryHookResult = ReturnType<typeof useMykfh_Read_AccountLazyQuery>;
export type Mykfh_Read_AccountQueryResult = Apollo.QueryResult<
  Mykfh_Read_AccountQuery,
  Mykfh_Read_AccountQueryVariables
>;
export const My_Kfh_Read_Saved_SearchesDocument = gql`
  query MY_KFH_READ_SAVED_SEARCHES {
    myKfh {
      myKfhReadSavedSearches {
        success
        total
        savedSearches {
          name
          userId
          id
          updated
          created
          url
          apiPath
          alertSettings {
            deliveryDay
            deliveryTime
            deliveryFrequency
          }
          payload {
            locations
            schools
            summaryAlert
            stations
            category
            type
            instantAlert
          }
          querystrings {
            key
            value
          }
        }
      }
    }
  }
`;

/**
 * __useMy_Kfh_Read_Saved_SearchesQuery__
 *
 * To run a query within a React component, call `useMy_Kfh_Read_Saved_SearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMy_Kfh_Read_Saved_SearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMy_Kfh_Read_Saved_SearchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMy_Kfh_Read_Saved_SearchesQuery(
  baseOptions?: Apollo.QueryHookOptions<My_Kfh_Read_Saved_SearchesQuery, My_Kfh_Read_Saved_SearchesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<My_Kfh_Read_Saved_SearchesQuery, My_Kfh_Read_Saved_SearchesQueryVariables>(
    My_Kfh_Read_Saved_SearchesDocument,
    options
  );
}
export function useMy_Kfh_Read_Saved_SearchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<My_Kfh_Read_Saved_SearchesQuery, My_Kfh_Read_Saved_SearchesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<My_Kfh_Read_Saved_SearchesQuery, My_Kfh_Read_Saved_SearchesQueryVariables>(
    My_Kfh_Read_Saved_SearchesDocument,
    options
  );
}
export type My_Kfh_Read_Saved_SearchesQueryHookResult = ReturnType<typeof useMy_Kfh_Read_Saved_SearchesQuery>;
export type My_Kfh_Read_Saved_SearchesLazyQueryHookResult = ReturnType<typeof useMy_Kfh_Read_Saved_SearchesLazyQuery>;
export type My_Kfh_Read_Saved_SearchesQueryResult = Apollo.QueryResult<
  My_Kfh_Read_Saved_SearchesQuery,
  My_Kfh_Read_Saved_SearchesQueryVariables
>;
export const My_Kfh_Read_Tab_CountDocument = gql`
  query MY_KFH_READ_TAB_COUNT {
    myKfh {
      myKfhReadTabCount {
        savedSearchesCount
        savedPropertiesCount
      }
    }
  }
`;

/**
 * __useMy_Kfh_Read_Tab_CountQuery__
 *
 * To run a query within a React component, call `useMy_Kfh_Read_Tab_CountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMy_Kfh_Read_Tab_CountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMy_Kfh_Read_Tab_CountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMy_Kfh_Read_Tab_CountQuery(
  baseOptions?: Apollo.QueryHookOptions<My_Kfh_Read_Tab_CountQuery, My_Kfh_Read_Tab_CountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<My_Kfh_Read_Tab_CountQuery, My_Kfh_Read_Tab_CountQueryVariables>(
    My_Kfh_Read_Tab_CountDocument,
    options
  );
}
export function useMy_Kfh_Read_Tab_CountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<My_Kfh_Read_Tab_CountQuery, My_Kfh_Read_Tab_CountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<My_Kfh_Read_Tab_CountQuery, My_Kfh_Read_Tab_CountQueryVariables>(
    My_Kfh_Read_Tab_CountDocument,
    options
  );
}
export type My_Kfh_Read_Tab_CountQueryHookResult = ReturnType<typeof useMy_Kfh_Read_Tab_CountQuery>;
export type My_Kfh_Read_Tab_CountLazyQueryHookResult = ReturnType<typeof useMy_Kfh_Read_Tab_CountLazyQuery>;
export type My_Kfh_Read_Tab_CountQueryResult = Apollo.QueryResult<
  My_Kfh_Read_Tab_CountQuery,
  My_Kfh_Read_Tab_CountQueryVariables
>;
export const My_Kfh_Read_Saved_PropertiesDocument = gql`
  query MY_KFH_READ_SAVED_PROPERTIES {
    myKfh {
      myKfhReadSavedProperties {
        success
        total
        savedProperties {
          deliverAlert
          uri
          id
          property {
            seo {
              h1
            }
            uri
            available
            id
            relativeUrl
            businessId
            primaryCategory
            aliasUrl
            commercial
            rentalPrice
            salesPrice
            sellingState
            pricePrefix
            rentalPricePerWeek
            propertyType
            propertySubType
            bedrooms
            propertyStyle
            tenure
            furnished
            bathrooms
            receptions
            roomMetrics
            roomImperials
            summary
            riverside
            managed
            zeroDeposit
            helpToBuy
            propertyAge
            helpToBuy
            availableLongLet
            availableShortLet
            forSaleAndToLet
            openDay
            virtualViewings
            branch {
              businessProfile {
                businessName
              }
            }
            address {
              streetname
              town
              outwardCode
            }
            images {
              photographs {
                imagePath
                largestImagePath
                width
                height
                filename
                mimeType
              }
            }
            ... on DevelopmentResalePropertyType {
              id
              images {
                developmentImages {
                  imagePath
                  largestImagePath
                  width
                  height
                  filename
                  mimeType
                }
              }
              developerName
              developmentMaxPrice
              developmentMinPrice
              plots {
                plotNumber
                propertyType
                bedrooms
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useMy_Kfh_Read_Saved_PropertiesQuery__
 *
 * To run a query within a React component, call `useMy_Kfh_Read_Saved_PropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMy_Kfh_Read_Saved_PropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMy_Kfh_Read_Saved_PropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMy_Kfh_Read_Saved_PropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<My_Kfh_Read_Saved_PropertiesQuery, My_Kfh_Read_Saved_PropertiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<My_Kfh_Read_Saved_PropertiesQuery, My_Kfh_Read_Saved_PropertiesQueryVariables>(
    My_Kfh_Read_Saved_PropertiesDocument,
    options
  );
}
export function useMy_Kfh_Read_Saved_PropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    My_Kfh_Read_Saved_PropertiesQuery,
    My_Kfh_Read_Saved_PropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<My_Kfh_Read_Saved_PropertiesQuery, My_Kfh_Read_Saved_PropertiesQueryVariables>(
    My_Kfh_Read_Saved_PropertiesDocument,
    options
  );
}
export type My_Kfh_Read_Saved_PropertiesQueryHookResult = ReturnType<typeof useMy_Kfh_Read_Saved_PropertiesQuery>;
export type My_Kfh_Read_Saved_PropertiesLazyQueryHookResult = ReturnType<
  typeof useMy_Kfh_Read_Saved_PropertiesLazyQuery
>;
export type My_Kfh_Read_Saved_PropertiesQueryResult = Apollo.QueryResult<
  My_Kfh_Read_Saved_PropertiesQuery,
  My_Kfh_Read_Saved_PropertiesQueryVariables
>;
export const My_Kfh_Read_Saved_Properties_IdsDocument = gql`
  query MY_KFH_READ_SAVED_PROPERTIES_IDS {
    myKfh {
      myKfhReadSavedProperties {
        success
        total
        savedProperties {
          deliverAlert
          uri
          id
          property {
            id
            relativeUrl
          }
        }
      }
    }
  }
`;

/**
 * __useMy_Kfh_Read_Saved_Properties_IdsQuery__
 *
 * To run a query within a React component, call `useMy_Kfh_Read_Saved_Properties_IdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMy_Kfh_Read_Saved_Properties_IdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMy_Kfh_Read_Saved_Properties_IdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMy_Kfh_Read_Saved_Properties_IdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    My_Kfh_Read_Saved_Properties_IdsQuery,
    My_Kfh_Read_Saved_Properties_IdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<My_Kfh_Read_Saved_Properties_IdsQuery, My_Kfh_Read_Saved_Properties_IdsQueryVariables>(
    My_Kfh_Read_Saved_Properties_IdsDocument,
    options
  );
}
export function useMy_Kfh_Read_Saved_Properties_IdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    My_Kfh_Read_Saved_Properties_IdsQuery,
    My_Kfh_Read_Saved_Properties_IdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<My_Kfh_Read_Saved_Properties_IdsQuery, My_Kfh_Read_Saved_Properties_IdsQueryVariables>(
    My_Kfh_Read_Saved_Properties_IdsDocument,
    options
  );
}
export type My_Kfh_Read_Saved_Properties_IdsQueryHookResult = ReturnType<
  typeof useMy_Kfh_Read_Saved_Properties_IdsQuery
>;
export type My_Kfh_Read_Saved_Properties_IdsLazyQueryHookResult = ReturnType<
  typeof useMy_Kfh_Read_Saved_Properties_IdsLazyQuery
>;
export type My_Kfh_Read_Saved_Properties_IdsQueryResult = Apollo.QueryResult<
  My_Kfh_Read_Saved_Properties_IdsQuery,
  My_Kfh_Read_Saved_Properties_IdsQueryVariables
>;
export const Create_New_HomesDocument = gql`
  mutation CREATE_NEW_HOMES($formSubmission: FormSubmissionInput!) {
    form {
      createFormSubmission(formSubmission: $formSubmission) {
        errorMessage
        success
      }
    }
  }
`;
export type Create_New_HomesMutationFn = Apollo.MutationFunction<
  Create_New_HomesMutation,
  Create_New_HomesMutationVariables
>;

/**
 * __useCreate_New_HomesMutation__
 *
 * To run a mutation, you first call `useCreate_New_HomesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_New_HomesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewHomesMutation, { data, loading, error }] = useCreate_New_HomesMutation({
 *   variables: {
 *      formSubmission: // value for 'formSubmission'
 *   },
 * });
 */
export function useCreate_New_HomesMutation(
  baseOptions?: Apollo.MutationHookOptions<Create_New_HomesMutation, Create_New_HomesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Create_New_HomesMutation, Create_New_HomesMutationVariables>(
    Create_New_HomesDocument,
    options
  );
}
export type Create_New_HomesMutationHookResult = ReturnType<typeof useCreate_New_HomesMutation>;
export type Create_New_HomesMutationResult = Apollo.MutationResult<Create_New_HomesMutation>;
export type Create_New_HomesMutationOptions = Apollo.BaseMutationOptions<
  Create_New_HomesMutation,
  Create_New_HomesMutationVariables
>;
export const SearchPropertiesDocument = gql`
  query SearchProperties(
    $age: [String]
    $areas: [String]
    $bathrooms: [String]
    $bedrooms: [String]
    $bedroomsMinimum: Int
    $category: SearchCategory!
    $currencyid: Int
    $first: Int
    $lastBuildDateTime: DateTime
    $multiSearch: String
    $newhomes: Boolean
    $onlynewhomes: Boolean
    $page: Int
    $priceHighest: Int
    $priceLowest: Int
    $propertyStyle: [String]
    $propertyType: [String]
    $receptions: [String]
    $riverside: Boolean
    $kfh: Boolean
    $longlet: Boolean
    $shortlet: Boolean
    $tenure: [String]
    $furnishState: [String]
    $type: TypeOfSearch
    $unavailable: Boolean
    $underoffer: Boolean
    $sort: SortSearch
    $commercialArea: String
    $commercialPropertyType: String
    $commercialSize: Int
    $commercialSizemax: Int
    $lat: Float
    $long: Float
    $nearme: Boolean
    $radius: Float
  ) {
    properties {
      propertySearch(
        bedrooms: $bedroomsMinimum
        category: $category
        currencyid: $currencyid
        fage: $age
        farea: $areas
        fbathroom: $bathrooms
        fbedroom: $bedrooms
        freception: $receptions
        fstyle: $propertyStyle
        ftenure: $tenure
        ffurnish: $furnishState
        ftype: $propertyType
        lastBuildDateTime: $lastBuildDateTime
        multisearch: $multiSearch
        newhomes: $newhomes
        onlynewhomes: $onlynewhomes
        price: $priceLowest
        pricemax: $priceHighest
        riverside: $riverside
        kfh: $kfh
        longlet: $longlet
        shortlet: $shortlet
        type: $type
        unavailable: $unavailable
        underoffer: $underoffer
        sort: $sort
        commercialArea: $commercialArea
        commercialPropertyType: $commercialPropertyType
        commercialSize: $commercialSize
        commercialSizemax: $commercialSizemax
        lat: $lat
        long: $long
        nearme: $nearme
        radius: $radius
      ) {
        propertyEndpointApi
        fallBackSearch {
          fallbackSearch
          fallbackSearchReason
        }
        multiSearch {
          displayName
          multiSearch
        }
        canonicalSearch {
          url
        }
        seo {
          title
          metaDescription
          h1
        }
        banners {
          banner
          id
          position
        }
        searchFacets {
          areas {
            facet
            value
            count
          }
          bedrooms {
            facet
            value
            count
          }
          propertyType {
            facet
            value
            count
          }
          propertyStyle {
            facet
            value
            count
          }
          bathrooms {
            facet
            value
            count
          }
          receptions {
            facet
            value
            count
          }
          riverside {
            facet
            value
            count
          }
          tenure {
            facet
            value
            count
          }
          furnishState {
            facet
            value
            count
          }
          class {
            facet
            value
            count
          }
        }
        propertyEndpointApi
        properties(first: $first, page: $page) {
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
            startCursor
          }
          edges {
            cursor
            node {
              __typename
              uri
              available
              id
              fullAddress
              relativeUrl
              remoteId: id
              businessId
              primaryCategory
              aliasUrl
              commercial
              rentalPrice
              salesPrice
              sellingState
              pricePrefix
              rentalPricePerWeek
              propertyType
              propertySubType
              bedrooms
              tenure
              propertyStyle
              furnished
              bathrooms
              receptions
              roomMetrics
              roomImperials
              description
              riverside
              summary
              managed
              zeroDeposit
              helpToBuy
              propertyAge
              sharedOwnership
              availableLongLet
              availableShortLet
              forSaleAndToLet
              openDay
              virtualViewings
              branch {
                id
                remoteId: id
                businessProfile {
                  businessName
                }
              }
              address {
                streetname
                town
                outwardCode
              }
              ... on ResidentialRentalPropertyType {
                whatsApp {
                  whatsAppEnabled
                  whatsAppFullUrl
                }
              }
              ... on PlotResalePropertyType {
                whatsApp {
                  whatsAppEnabled
                  whatsAppFullUrl
                }
              }
              ... on DevelopmentResalePropertyType {
                whatsApp {
                  whatsAppEnabled
                  whatsAppFullUrl
                }
                developmentName
                developmentMaxPrice
                developmentMinPrice
                plots {
                  plotNumber
                  propertyType
                }
                images {
                  photographs {
                    imagePath
                    largestImagePath
                    width
                    height
                    filename
                    mimeType
                  }
                }
              }
              images {
                photographs {
                  imagePath
                  largestImagePath
                  width
                  height
                  filename
                  mimeType
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSearchPropertiesQuery__
 *
 * To run a query within a React component, call `useSearchPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPropertiesQuery({
 *   variables: {
 *      age: // value for 'age'
 *      areas: // value for 'areas'
 *      bathrooms: // value for 'bathrooms'
 *      bedrooms: // value for 'bedrooms'
 *      bedroomsMinimum: // value for 'bedroomsMinimum'
 *      category: // value for 'category'
 *      currencyid: // value for 'currencyid'
 *      first: // value for 'first'
 *      lastBuildDateTime: // value for 'lastBuildDateTime'
 *      multiSearch: // value for 'multiSearch'
 *      newhomes: // value for 'newhomes'
 *      onlynewhomes: // value for 'onlynewhomes'
 *      page: // value for 'page'
 *      priceHighest: // value for 'priceHighest'
 *      priceLowest: // value for 'priceLowest'
 *      propertyStyle: // value for 'propertyStyle'
 *      propertyType: // value for 'propertyType'
 *      receptions: // value for 'receptions'
 *      riverside: // value for 'riverside'
 *      kfh: // value for 'kfh'
 *      longlet: // value for 'longlet'
 *      shortlet: // value for 'shortlet'
 *      tenure: // value for 'tenure'
 *      furnishState: // value for 'furnishState'
 *      type: // value for 'type'
 *      unavailable: // value for 'unavailable'
 *      underoffer: // value for 'underoffer'
 *      sort: // value for 'sort'
 *      commercialArea: // value for 'commercialArea'
 *      commercialPropertyType: // value for 'commercialPropertyType'
 *      commercialSize: // value for 'commercialSize'
 *      commercialSizemax: // value for 'commercialSizemax'
 *      lat: // value for 'lat'
 *      long: // value for 'long'
 *      nearme: // value for 'nearme'
 *      radius: // value for 'radius'
 *   },
 * });
 */
export function useSearchPropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<SearchPropertiesQuery, SearchPropertiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchPropertiesQuery, SearchPropertiesQueryVariables>(SearchPropertiesDocument, options);
}
export function useSearchPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchPropertiesQuery, SearchPropertiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchPropertiesQuery, SearchPropertiesQueryVariables>(SearchPropertiesDocument, options);
}
export type SearchPropertiesQueryHookResult = ReturnType<typeof useSearchPropertiesQuery>;
export type SearchPropertiesLazyQueryHookResult = ReturnType<typeof useSearchPropertiesLazyQuery>;
export type SearchPropertiesQueryResult = Apollo.QueryResult<SearchPropertiesQuery, SearchPropertiesQueryVariables>;
export const SearchPropertiesSeoDocument = gql`
  query SearchPropertiesSEO(
    $age: [String]
    $areas: [String]
    $bathrooms: [String]
    $bedrooms: [String]
    $bedroomsMinimum: Int
    $category: SearchCategory!
    $currencyid: Int
    $lastBuildDateTime: DateTime
    $multiSearch: String
    $newhomes: Boolean
    $onlynewhomes: Boolean
    $priceHighest: Int
    $priceLowest: Int
    $propertyStyle: [String]
    $propertyType: [String]
    $receptions: [String]
    $riverside: Boolean
    $kfh: Boolean
    $longlet: Boolean
    $shortlet: Boolean
    $tenure: [String]
    $furnishState: [String]
    $type: TypeOfSearch
    $unavailable: Boolean
    $underoffer: Boolean
    $sort: SortSearch
    $commercialArea: String
    $commercialPropertyType: String
    $commercialSize: Int
    $commercialSizemax: Int
    $lat: Float
    $long: Float
    $nearme: Boolean
    $radius: Float
  ) {
    properties {
      propertySearch(
        bedrooms: $bedroomsMinimum
        category: $category
        currencyid: $currencyid
        fage: $age
        farea: $areas
        fbathroom: $bathrooms
        fbedroom: $bedrooms
        freception: $receptions
        fstyle: $propertyStyle
        ftenure: $tenure
        ffurnish: $furnishState
        ftype: $propertyType
        lastBuildDateTime: $lastBuildDateTime
        multisearch: $multiSearch
        newhomes: $newhomes
        onlynewhomes: $onlynewhomes
        price: $priceLowest
        pricemax: $priceHighest
        riverside: $riverside
        kfh: $kfh
        longlet: $longlet
        shortlet: $shortlet
        type: $type
        unavailable: $unavailable
        underoffer: $underoffer
        sort: $sort
        commercialArea: $commercialArea
        commercialPropertyType: $commercialPropertyType
        commercialSize: $commercialSize
        commercialSizemax: $commercialSizemax
        lat: $lat
        long: $long
        nearme: $nearme
        radius: $radius
      ) {
        fallBackSearch {
          fallbackSearch
        }
        canonicalSearch {
          url
        }
        seo {
          title
          metaDescription
        }
      }
    }
  }
`;

/**
 * __useSearchPropertiesSeoQuery__
 *
 * To run a query within a React component, call `useSearchPropertiesSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPropertiesSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPropertiesSeoQuery({
 *   variables: {
 *      age: // value for 'age'
 *      areas: // value for 'areas'
 *      bathrooms: // value for 'bathrooms'
 *      bedrooms: // value for 'bedrooms'
 *      bedroomsMinimum: // value for 'bedroomsMinimum'
 *      category: // value for 'category'
 *      currencyid: // value for 'currencyid'
 *      lastBuildDateTime: // value for 'lastBuildDateTime'
 *      multiSearch: // value for 'multiSearch'
 *      newhomes: // value for 'newhomes'
 *      onlynewhomes: // value for 'onlynewhomes'
 *      priceHighest: // value for 'priceHighest'
 *      priceLowest: // value for 'priceLowest'
 *      propertyStyle: // value for 'propertyStyle'
 *      propertyType: // value for 'propertyType'
 *      receptions: // value for 'receptions'
 *      riverside: // value for 'riverside'
 *      kfh: // value for 'kfh'
 *      longlet: // value for 'longlet'
 *      shortlet: // value for 'shortlet'
 *      tenure: // value for 'tenure'
 *      furnishState: // value for 'furnishState'
 *      type: // value for 'type'
 *      unavailable: // value for 'unavailable'
 *      underoffer: // value for 'underoffer'
 *      sort: // value for 'sort'
 *      commercialArea: // value for 'commercialArea'
 *      commercialPropertyType: // value for 'commercialPropertyType'
 *      commercialSize: // value for 'commercialSize'
 *      commercialSizemax: // value for 'commercialSizemax'
 *      lat: // value for 'lat'
 *      long: // value for 'long'
 *      nearme: // value for 'nearme'
 *      radius: // value for 'radius'
 *   },
 * });
 */
export function useSearchPropertiesSeoQuery(
  baseOptions: Apollo.QueryHookOptions<SearchPropertiesSeoQuery, SearchPropertiesSeoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchPropertiesSeoQuery, SearchPropertiesSeoQueryVariables>(
    SearchPropertiesSeoDocument,
    options
  );
}
export function useSearchPropertiesSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchPropertiesSeoQuery, SearchPropertiesSeoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchPropertiesSeoQuery, SearchPropertiesSeoQueryVariables>(
    SearchPropertiesSeoDocument,
    options
  );
}
export type SearchPropertiesSeoQueryHookResult = ReturnType<typeof useSearchPropertiesSeoQuery>;
export type SearchPropertiesSeoLazyQueryHookResult = ReturnType<typeof useSearchPropertiesSeoLazyQuery>;
export type SearchPropertiesSeoQueryResult = Apollo.QueryResult<
  SearchPropertiesSeoQuery,
  SearchPropertiesSeoQueryVariables
>;
export const SearchPropertiesMapDocument = gql`
  query SearchPropertiesMap(
    $age: [String]
    $areas: [String]
    $bathrooms: [String]
    $bedrooms: [String]
    $bedroomsMinimum: Int
    $category: SearchCategory!
    $currencyid: Int
    $first: Int
    $lastBuildDateTime: DateTime
    $multiSearch: String
    $newhomes: Boolean
    $onlynewhomes: Boolean
    $page: Int
    $priceHighest: Int
    $priceLowest: Int
    $propertyStyle: [String]
    $propertyType: [String]
    $receptions: [String]
    $riverside: Boolean
    $kfh: Boolean
    $longlet: Boolean
    $shortlet: Boolean
    $tenure: [String]
    $furnishState: [String]
    $type: TypeOfSearch
    $unavailable: Boolean
    $underoffer: Boolean
    $sort: SortSearch
    $commercialArea: String
    $commercialPropertyType: String
    $commercialSize: Int
    $commercialSizemax: Int
    $lat: Float
    $long: Float
    $nearme: Boolean
    $radius: Float
  ) {
    properties {
      propertySearch(
        bedrooms: $bedroomsMinimum
        category: $category
        currencyid: $currencyid
        fage: $age
        farea: $areas
        fbathroom: $bathrooms
        fbedroom: $bedrooms
        freception: $receptions
        fstyle: $propertyStyle
        ftenure: $tenure
        ffurnish: $furnishState
        ftype: $propertyType
        lastBuildDateTime: $lastBuildDateTime
        multisearch: $multiSearch
        newhomes: $newhomes
        onlynewhomes: $onlynewhomes
        price: $priceLowest
        pricemax: $priceHighest
        riverside: $riverside
        kfh: $kfh
        longlet: $longlet
        shortlet: $shortlet
        type: $type
        unavailable: $unavailable
        underoffer: $underoffer
        sort: $sort
        commercialArea: $commercialArea
        commercialPropertyType: $commercialPropertyType
        commercialSize: $commercialSize
        commercialSizemax: $commercialSizemax
        lat: $lat
        long: $long
        nearme: $nearme
        radius: $radius
        map: true
      ) {
        branches {
          id
          salesLat
          salesLon
          lettingsLat
          lettingsLon
        }
        properties(first: $first, page: $page) {
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
            startCursor
          }
          edges {
            cursor
            node {
              __typename
              available
              id
              remoteId: id
              businessId
              primaryCategory
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSearchPropertiesMapQuery__
 *
 * To run a query within a React component, call `useSearchPropertiesMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPropertiesMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPropertiesMapQuery({
 *   variables: {
 *      age: // value for 'age'
 *      areas: // value for 'areas'
 *      bathrooms: // value for 'bathrooms'
 *      bedrooms: // value for 'bedrooms'
 *      bedroomsMinimum: // value for 'bedroomsMinimum'
 *      category: // value for 'category'
 *      currencyid: // value for 'currencyid'
 *      first: // value for 'first'
 *      lastBuildDateTime: // value for 'lastBuildDateTime'
 *      multiSearch: // value for 'multiSearch'
 *      newhomes: // value for 'newhomes'
 *      onlynewhomes: // value for 'onlynewhomes'
 *      page: // value for 'page'
 *      priceHighest: // value for 'priceHighest'
 *      priceLowest: // value for 'priceLowest'
 *      propertyStyle: // value for 'propertyStyle'
 *      propertyType: // value for 'propertyType'
 *      receptions: // value for 'receptions'
 *      riverside: // value for 'riverside'
 *      kfh: // value for 'kfh'
 *      longlet: // value for 'longlet'
 *      shortlet: // value for 'shortlet'
 *      tenure: // value for 'tenure'
 *      furnishState: // value for 'furnishState'
 *      type: // value for 'type'
 *      unavailable: // value for 'unavailable'
 *      underoffer: // value for 'underoffer'
 *      sort: // value for 'sort'
 *      commercialArea: // value for 'commercialArea'
 *      commercialPropertyType: // value for 'commercialPropertyType'
 *      commercialSize: // value for 'commercialSize'
 *      commercialSizemax: // value for 'commercialSizemax'
 *      lat: // value for 'lat'
 *      long: // value for 'long'
 *      nearme: // value for 'nearme'
 *      radius: // value for 'radius'
 *   },
 * });
 */
export function useSearchPropertiesMapQuery(
  baseOptions: Apollo.QueryHookOptions<SearchPropertiesMapQuery, SearchPropertiesMapQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchPropertiesMapQuery, SearchPropertiesMapQueryVariables>(
    SearchPropertiesMapDocument,
    options
  );
}
export function useSearchPropertiesMapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchPropertiesMapQuery, SearchPropertiesMapQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchPropertiesMapQuery, SearchPropertiesMapQueryVariables>(
    SearchPropertiesMapDocument,
    options
  );
}
export type SearchPropertiesMapQueryHookResult = ReturnType<typeof useSearchPropertiesMapQuery>;
export type SearchPropertiesMapLazyQueryHookResult = ReturnType<typeof useSearchPropertiesMapLazyQuery>;
export type SearchPropertiesMapQueryResult = Apollo.QueryResult<
  SearchPropertiesMapQuery,
  SearchPropertiesMapQueryVariables
>;
export const SearchPropertiesMapNoBranchesDocument = gql`
  query SearchPropertiesMapNoBranches(
    $age: [String]
    $areas: [String]
    $bathrooms: [String]
    $bedrooms: [String]
    $bedroomsMinimum: Int
    $category: SearchCategory!
    $currencyid: Int
    $first: Int
    $lastBuildDateTime: DateTime
    $multiSearch: String
    $newhomes: Boolean
    $onlynewhomes: Boolean
    $page: Int
    $priceHighest: Int
    $priceLowest: Int
    $propertyStyle: [String]
    $propertyType: [String]
    $receptions: [String]
    $riverside: Boolean
    $kfh: Boolean
    $longlet: Boolean
    $shortlet: Boolean
    $tenure: [String]
    $furnishState: [String]
    $type: TypeOfSearch
    $unavailable: Boolean
    $underoffer: Boolean
    $sort: SortSearch
    $commercialArea: String
    $commercialPropertyType: String
    $commercialSize: Int
    $commercialSizemax: Int
    $lat: Float
    $long: Float
    $nearme: Boolean
    $radius: Float
  ) {
    properties {
      propertySearch(
        bedrooms: $bedroomsMinimum
        category: $category
        currencyid: $currencyid
        fage: $age
        farea: $areas
        fbathroom: $bathrooms
        fbedroom: $bedrooms
        freception: $receptions
        fstyle: $propertyStyle
        ftenure: $tenure
        ffurnish: $furnishState
        ftype: $propertyType
        lastBuildDateTime: $lastBuildDateTime
        multisearch: $multiSearch
        newhomes: $newhomes
        onlynewhomes: $onlynewhomes
        price: $priceLowest
        pricemax: $priceHighest
        riverside: $riverside
        kfh: $kfh
        longlet: $longlet
        shortlet: $shortlet
        type: $type
        unavailable: $unavailable
        underoffer: $underoffer
        sort: $sort
        commercialArea: $commercialArea
        commercialPropertyType: $commercialPropertyType
        commercialSize: $commercialSize
        commercialSizemax: $commercialSizemax
        lat: $lat
        long: $long
        nearme: $nearme
        radius: $radius
        map: true
      ) {
        properties(first: $first, page: $page) {
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
            startCursor
          }
          edges {
            cursor
            node {
              __typename
              available
              id
              remoteId: id
              businessId
              primaryCategory
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSearchPropertiesMapNoBranchesQuery__
 *
 * To run a query within a React component, call `useSearchPropertiesMapNoBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPropertiesMapNoBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPropertiesMapNoBranchesQuery({
 *   variables: {
 *      age: // value for 'age'
 *      areas: // value for 'areas'
 *      bathrooms: // value for 'bathrooms'
 *      bedrooms: // value for 'bedrooms'
 *      bedroomsMinimum: // value for 'bedroomsMinimum'
 *      category: // value for 'category'
 *      currencyid: // value for 'currencyid'
 *      first: // value for 'first'
 *      lastBuildDateTime: // value for 'lastBuildDateTime'
 *      multiSearch: // value for 'multiSearch'
 *      newhomes: // value for 'newhomes'
 *      onlynewhomes: // value for 'onlynewhomes'
 *      page: // value for 'page'
 *      priceHighest: // value for 'priceHighest'
 *      priceLowest: // value for 'priceLowest'
 *      propertyStyle: // value for 'propertyStyle'
 *      propertyType: // value for 'propertyType'
 *      receptions: // value for 'receptions'
 *      riverside: // value for 'riverside'
 *      kfh: // value for 'kfh'
 *      longlet: // value for 'longlet'
 *      shortlet: // value for 'shortlet'
 *      tenure: // value for 'tenure'
 *      furnishState: // value for 'furnishState'
 *      type: // value for 'type'
 *      unavailable: // value for 'unavailable'
 *      underoffer: // value for 'underoffer'
 *      sort: // value for 'sort'
 *      commercialArea: // value for 'commercialArea'
 *      commercialPropertyType: // value for 'commercialPropertyType'
 *      commercialSize: // value for 'commercialSize'
 *      commercialSizemax: // value for 'commercialSizemax'
 *      lat: // value for 'lat'
 *      long: // value for 'long'
 *      nearme: // value for 'nearme'
 *      radius: // value for 'radius'
 *   },
 * });
 */
export function useSearchPropertiesMapNoBranchesQuery(
  baseOptions: Apollo.QueryHookOptions<SearchPropertiesMapNoBranchesQuery, SearchPropertiesMapNoBranchesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchPropertiesMapNoBranchesQuery, SearchPropertiesMapNoBranchesQueryVariables>(
    SearchPropertiesMapNoBranchesDocument,
    options
  );
}
export function useSearchPropertiesMapNoBranchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchPropertiesMapNoBranchesQuery,
    SearchPropertiesMapNoBranchesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchPropertiesMapNoBranchesQuery, SearchPropertiesMapNoBranchesQueryVariables>(
    SearchPropertiesMapNoBranchesDocument,
    options
  );
}
export type SearchPropertiesMapNoBranchesQueryHookResult = ReturnType<typeof useSearchPropertiesMapNoBranchesQuery>;
export type SearchPropertiesMapNoBranchesLazyQueryHookResult = ReturnType<
  typeof useSearchPropertiesMapNoBranchesLazyQuery
>;
export type SearchPropertiesMapNoBranchesQueryResult = Apollo.QueryResult<
  SearchPropertiesMapNoBranchesQuery,
  SearchPropertiesMapNoBranchesQueryVariables
>;
export const GetPropertyByIdDocument = gql`
  query GetPropertyById($id: ID!) {
    properties {
      __typename
      property(id: $id) {
        __typename
        available
        id
        uri
        fullAddress
        relativeUrl
        remoteId: id
        businessId
        primaryCategory
        aliasUrl
        commercial
        rentalPrice
        salesPrice
        sellingState
        pricePrefix
        rentalPricePerWeek
        propertyType
        propertySubType
        propertyStyle
        summary
        bedrooms
        tenure
        furnished
        bathrooms
        receptions
        roomMetrics
        roomImperials
        description
        riverside
        managed
        zeroDeposit
        helpToBuy
        propertyAge
        sharedOwnership
        availableLongLet
        availableShortLet
        forSaleAndToLet
        openDay
        virtualViewings
        branch {
          id
          remoteId: id
          businessProfile {
            businessName
          }
        }
        address {
          streetname
          town
          outwardCode
        }
        ... on DevelopmentResalePropertyType {
          developmentName
          developmentMaxPrice
          developmentMinPrice
          plots {
            plotNumber
            propertyType
            bedrooms
          }
          images {
            developmentImages {
              largestImagePath
              width
              height
              filename
              mimeType
            }
            photographs {
              largestImagePath
              width
              height
              filename
              mimeType
            }
          }
        }
        images {
          photographs {
            largestImagePath
            width
            height
            filename
            mimeType
          }
        }
      }
    }
  }
`;

/**
 * __useGetPropertyByIdQuery__
 *
 * To run a query within a React component, call `useGetPropertyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetPropertyByIdQuery, GetPropertyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyByIdQuery, GetPropertyByIdQueryVariables>(GetPropertyByIdDocument, options);
}
export function useGetPropertyByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyByIdQuery, GetPropertyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertyByIdQuery, GetPropertyByIdQueryVariables>(GetPropertyByIdDocument, options);
}
export type GetPropertyByIdQueryHookResult = ReturnType<typeof useGetPropertyByIdQuery>;
export type GetPropertyByIdLazyQueryHookResult = ReturnType<typeof useGetPropertyByIdLazyQuery>;
export type GetPropertyByIdQueryResult = Apollo.QueryResult<GetPropertyByIdQuery, GetPropertyByIdQueryVariables>;
export const AutoCompleteSearchDocument = gql`
  query autoCompleteSearch($search: String!, $category: SearchCategory!, $type: SearchType) {
    propertySearchMeta {
      autoComplete(search: $search, category: $category, searchType: $type) {
        autoCompleteItems {
          displayName
          multiSearch
          ID: multiSearch
        }
      }
    }
  }
`;

/**
 * __useAutoCompleteSearchQuery__
 *
 * To run a query within a React component, call `useAutoCompleteSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutoCompleteSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutoCompleteSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      category: // value for 'category'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAutoCompleteSearchQuery(
  baseOptions: Apollo.QueryHookOptions<AutoCompleteSearchQuery, AutoCompleteSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AutoCompleteSearchQuery, AutoCompleteSearchQueryVariables>(
    AutoCompleteSearchDocument,
    options
  );
}
export function useAutoCompleteSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AutoCompleteSearchQuery, AutoCompleteSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AutoCompleteSearchQuery, AutoCompleteSearchQueryVariables>(
    AutoCompleteSearchDocument,
    options
  );
}
export type AutoCompleteSearchQueryHookResult = ReturnType<typeof useAutoCompleteSearchQuery>;
export type AutoCompleteSearchLazyQueryHookResult = ReturnType<typeof useAutoCompleteSearchLazyQuery>;
export type AutoCompleteSearchQueryResult = Apollo.QueryResult<
  AutoCompleteSearchQuery,
  AutoCompleteSearchQueryVariables
>;
export const PropertyByRefDocument = gql`
  query propertyByRef($ref: ID!) {
    properties {
      propertyByRef(id: $ref) {
        __typename
        relativeUrl
        uri
        available
        id
        remoteId: id
        businessId
        primaryCategory
        aliasUrl
        address {
          streetname
          town
          outwardCode
        }
        latitude
        longitude
        commercial
        roomImperials
        roomMetrics
        rentDetails
        rentalPricePerWeek
        rentalPrice
        salesPrice
        pricePrefix
        sellingState
        openDay
        openDayDateEnd
        openDayDateStart
        furnished
        propertyAge
        forSaleAndToLet
        availableLongLet
        availableShortLet
        managed
        zeroDeposit
        helpToBuy
        sharedOwnership
        propertyType
        propertySubType
        riverside
        tenure
        bedrooms
        receptions
        bathrooms
        description
        salesDetails
        termsAndConditions
        businessRates
        location
        propertyStyle
        additionalDescription
        features {
          description
        }
        propertyBrochure
        virtualViewings
        virtualViewingsUrl
        firstImage {
          largestImagePath
          width
          height
          filename
          mimeType
        }
        images {
          photographsSmall: photographs {
            largestImagePath
            width
            height
            filename
            mimeType
          }
          photographs {
            largestImagePath
            width
            height
            filename
            mimeType
          }
          epc {
            largestImagePath
            width
            height
            filename
            mimeType
          }
          floorplans {
            largestImagePath
            width
            height
            filename
            mimeType
          }
          floorplansSmall: floorplans {
            largestImagePath
            width
            height
            filename
            mimeType
          }
        }
        stations {
          name
          trainStationType
          distance
        }
        specifications {
          detail
          category
        }
        area {
          id
          guideExcerpt
          relativeUrl
          name
          region {
            name
            mediaSet {
              media {
                media {
                  filePath
                  width
                  height
                  filename
                  mimeType
                }
              }
            }
          }
          mediaSet {
            media {
              media {
                filePath
                width
                height
                filename
                mimeType
              }
            }
          }
        }
        branch {
          id
          name
          remoteId: id
          relativeUrl
          isSales
          isLettings
          salesLat
          salesLon
          lettingsLat
          lettingsLon
          openingHours
          googleMapLinkRental
          googleMapLinkResales
          mediaSet {
            media {
              media {
                filePath
                width
                height
                filename
                mimeType
              }
              role
            }
          }
          businessProfile {
            businessName
            salesContactDetails {
              ...ContactDetailsLive
            }
            lettingsContactDetails {
              ...ContactDetailsLive
            }
          }
          lettingsBranchImage {
            filePath
            width
            height
            filename
            mimeType
          }
          salesBranchImage {
            filePath
            width
            height
            filename
            mimeType
          }
        }
        ... on ResidentialRentalPropertyType {
          whatsApp {
            whatsAppEnabled
            whatsAppFullUrl
          }
        }
        ... on DevelopmentResalePropertyType {
          developmentName
          developmentMaxPrice
          developmentMinPrice
          plots {
            plotNumber
            floorLevel
            propertyType
            sellingState
            salesPrice
            bedrooms
            relativeUrl
            available
          }
          images {
            developmentImages {
              largestImagePath
              width
              height
              filename
              mimeType
            }
          }
        }
        ... on PlotResalePropertyType {
          development {
            relativeUrl
            developmentName
            description
          }
          relatedPlots {
            plotNumber
            floorLevel
            propertyType
            sellingState
            salesPrice
            bedrooms
            relativeUrl
            available
          }
        }
        seo {
          ...SEOLive
        }
      }
    }
  }
  ${ContactDetailsLiveFragmentDoc}
  ${SeoLiveFragmentDoc}
`;

/**
 * __usePropertyByRefQuery__
 *
 * To run a query within a React component, call `usePropertyByRefQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyByRefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyByRefQuery({
 *   variables: {
 *      ref: // value for 'ref'
 *   },
 * });
 */
export function usePropertyByRefQuery(
  baseOptions: Apollo.QueryHookOptions<PropertyByRefQuery, PropertyByRefQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PropertyByRefQuery, PropertyByRefQueryVariables>(PropertyByRefDocument, options);
}
export function usePropertyByRefLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PropertyByRefQuery, PropertyByRefQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PropertyByRefQuery, PropertyByRefQueryVariables>(PropertyByRefDocument, options);
}
export type PropertyByRefQueryHookResult = ReturnType<typeof usePropertyByRefQuery>;
export type PropertyByRefLazyQueryHookResult = ReturnType<typeof usePropertyByRefLazyQuery>;
export type PropertyByRefQueryResult = Apollo.QueryResult<PropertyByRefQuery, PropertyByRefQueryVariables>;
export const Create_Property_Management_ReviewDocument = gql`
  mutation CREATE_PROPERTY_MANAGEMENT_REVIEW($formSubmission: FormSubmissionInput!) {
    form {
      createFormSubmission(formSubmission: $formSubmission) {
        errorMessage
        success
      }
    }
  }
`;
export type Create_Property_Management_ReviewMutationFn = Apollo.MutationFunction<
  Create_Property_Management_ReviewMutation,
  Create_Property_Management_ReviewMutationVariables
>;

/**
 * __useCreate_Property_Management_ReviewMutation__
 *
 * To run a mutation, you first call `useCreate_Property_Management_ReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Property_Management_ReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPropertyManagementReviewMutation, { data, loading, error }] = useCreate_Property_Management_ReviewMutation({
 *   variables: {
 *      formSubmission: // value for 'formSubmission'
 *   },
 * });
 */
export function useCreate_Property_Management_ReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Create_Property_Management_ReviewMutation,
    Create_Property_Management_ReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Create_Property_Management_ReviewMutation,
    Create_Property_Management_ReviewMutationVariables
  >(Create_Property_Management_ReviewDocument, options);
}
export type Create_Property_Management_ReviewMutationHookResult = ReturnType<
  typeof useCreate_Property_Management_ReviewMutation
>;
export type Create_Property_Management_ReviewMutationResult =
  Apollo.MutationResult<Create_Property_Management_ReviewMutation>;
export type Create_Property_Management_ReviewMutationOptions = Apollo.BaseMutationOptions<
  Create_Property_Management_ReviewMutation,
  Create_Property_Management_ReviewMutationVariables
>;
export const Create_Property_Solicitors_QuoteDocument = gql`
  mutation CREATE_PROPERTY_SOLICITORS_QUOTE($formSubmission: FormSubmissionInput!) {
    form {
      createFormSubmission(formSubmission: $formSubmission) {
        errorMessage
        success
      }
    }
  }
`;
export type Create_Property_Solicitors_QuoteMutationFn = Apollo.MutationFunction<
  Create_Property_Solicitors_QuoteMutation,
  Create_Property_Solicitors_QuoteMutationVariables
>;

/**
 * __useCreate_Property_Solicitors_QuoteMutation__
 *
 * To run a mutation, you first call `useCreate_Property_Solicitors_QuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Property_Solicitors_QuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPropertySolicitorsQuoteMutation, { data, loading, error }] = useCreate_Property_Solicitors_QuoteMutation({
 *   variables: {
 *      formSubmission: // value for 'formSubmission'
 *   },
 * });
 */
export function useCreate_Property_Solicitors_QuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Create_Property_Solicitors_QuoteMutation,
    Create_Property_Solicitors_QuoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Create_Property_Solicitors_QuoteMutation,
    Create_Property_Solicitors_QuoteMutationVariables
  >(Create_Property_Solicitors_QuoteDocument, options);
}
export type Create_Property_Solicitors_QuoteMutationHookResult = ReturnType<
  typeof useCreate_Property_Solicitors_QuoteMutation
>;
export type Create_Property_Solicitors_QuoteMutationResult =
  Apollo.MutationResult<Create_Property_Solicitors_QuoteMutation>;
export type Create_Property_Solicitors_QuoteMutationOptions = Apollo.BaseMutationOptions<
  Create_Property_Solicitors_QuoteMutation,
  Create_Property_Solicitors_QuoteMutationVariables
>;
export const Create_Property_ValuationDocument = gql`
  mutation CREATE_PROPERTY_VALUATION($propertyValuation: PropertyValuationInput!) {
    lead {
      createPropertyValuation(propertyValuation: $propertyValuation) {
        title
        forename
        surname
        success
        errorMessage
      }
    }
  }
`;
export type Create_Property_ValuationMutationFn = Apollo.MutationFunction<
  Create_Property_ValuationMutation,
  Create_Property_ValuationMutationVariables
>;

/**
 * __useCreate_Property_ValuationMutation__
 *
 * To run a mutation, you first call `useCreate_Property_ValuationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Property_ValuationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPropertyValuationMutation, { data, loading, error }] = useCreate_Property_ValuationMutation({
 *   variables: {
 *      propertyValuation: // value for 'propertyValuation'
 *   },
 * });
 */
export function useCreate_Property_ValuationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Create_Property_ValuationMutation,
    Create_Property_ValuationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Create_Property_ValuationMutation, Create_Property_ValuationMutationVariables>(
    Create_Property_ValuationDocument,
    options
  );
}
export type Create_Property_ValuationMutationHookResult = ReturnType<typeof useCreate_Property_ValuationMutation>;
export type Create_Property_ValuationMutationResult = Apollo.MutationResult<Create_Property_ValuationMutation>;
export type Create_Property_ValuationMutationOptions = Apollo.BaseMutationOptions<
  Create_Property_ValuationMutation,
  Create_Property_ValuationMutationVariables
>;
export const Valuation_Check_PostcodeDocument = gql`
  query VALUATION_CHECK_POSTCODE($postcode: String) {
    branch {
      valuationBranches(postcode: $postcode) {
        errorMessage
        lettingsBranch {
          id
          remoteId: id
          name
          relativeUrl
          googleMapLinkResales
          googleMapLinkRental
          googleMapsSalesOverride
          googleMapsLettingsOverride
          isWhatsAppEnabled
          isWhatsAppLettingsEnabled
          lettingsWhatsApp {
            whatsAppEnabled
            whatsAppFullUrl
            whatsAppMessage
            whatsAppPhoneNumber
          }
          mediaSet {
            name
            media {
              role
              media {
                filePath
                width
                height
                filename
                mimeType
                alternativeText
              }
            }
          }
          lettingsManager {
            forename
            surname
            mediaSet {
              media {
                media {
                  filePath
                }
              }
            }
          }
          businessProfile {
            businessId
            businessName
            lettingsContactDetails {
              ...BranchContactDetails
            }
          }
        }
        salesBranch {
          id
          remoteId: id
          name
          relativeUrl
          googleMapLinkResales
          googleMapLinkRental
          googleMapsSalesOverride
          googleMapsLettingsOverride
          isWhatsAppEnabled
          isWhatsAppLettingsEnabled
          salesWhatsApp {
            whatsAppEnabled
            whatsAppFullUrl
            whatsAppMessage
            whatsAppPhoneNumber
          }
          mediaSet {
            name
            media {
              role
              media {
                filePath
                width
                height
                filename
                mimeType
                alternativeText
              }
            }
          }
          salesManager {
            forename
            surname
            mediaSet {
              media {
                media {
                  filePath
                }
              }
            }
          }
          businessProfile {
            businessId
            businessName
            salesContactDetails {
              ...BranchContactDetails
            }
          }
        }
      }
    }
  }
  ${BranchContactDetailsFragmentDoc}
`;

/**
 * __useValuation_Check_PostcodeQuery__
 *
 * To run a query within a React component, call `useValuation_Check_PostcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useValuation_Check_PostcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValuation_Check_PostcodeQuery({
 *   variables: {
 *      postcode: // value for 'postcode'
 *   },
 * });
 */
export function useValuation_Check_PostcodeQuery(
  baseOptions?: Apollo.QueryHookOptions<Valuation_Check_PostcodeQuery, Valuation_Check_PostcodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Valuation_Check_PostcodeQuery, Valuation_Check_PostcodeQueryVariables>(
    Valuation_Check_PostcodeDocument,
    options
  );
}
export function useValuation_Check_PostcodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Valuation_Check_PostcodeQuery, Valuation_Check_PostcodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Valuation_Check_PostcodeQuery, Valuation_Check_PostcodeQueryVariables>(
    Valuation_Check_PostcodeDocument,
    options
  );
}
export type Valuation_Check_PostcodeQueryHookResult = ReturnType<typeof useValuation_Check_PostcodeQuery>;
export type Valuation_Check_PostcodeLazyQueryHookResult = ReturnType<typeof useValuation_Check_PostcodeLazyQuery>;
export type Valuation_Check_PostcodeQueryResult = Apollo.QueryResult<
  Valuation_Check_PostcodeQuery,
  Valuation_Check_PostcodeQueryVariables
>;
export const Stamp_Duty_CalculatorDocument = gql`
  query STAMP_DUTY_CALCULATOR($effectiveDate: Date, $price: Float, $nonUkResident: Boolean) {
    stampDuty {
      stampDutyCalculator(effectiveDate: $effectiveDate, price: $price, nonUkResident: $nonUkResident) {
        effectiveDate
        price
        nonUkResident
        results {
          name
          totalDue
          totalDueAdditional
          bands {
            upperBand
            type
            rateAdditional
            rate
            order
            name
            lowerBand
            effectiveDate
            dueAdditional
            due
          }
        }
      }
    }
  }
`;

/**
 * __useStamp_Duty_CalculatorQuery__
 *
 * To run a query within a React component, call `useStamp_Duty_CalculatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useStamp_Duty_CalculatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStamp_Duty_CalculatorQuery({
 *   variables: {
 *      effectiveDate: // value for 'effectiveDate'
 *      price: // value for 'price'
 *      nonUkResident: // value for 'nonUkResident'
 *   },
 * });
 */
export function useStamp_Duty_CalculatorQuery(
  baseOptions?: Apollo.QueryHookOptions<Stamp_Duty_CalculatorQuery, Stamp_Duty_CalculatorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Stamp_Duty_CalculatorQuery, Stamp_Duty_CalculatorQueryVariables>(
    Stamp_Duty_CalculatorDocument,
    options
  );
}
export function useStamp_Duty_CalculatorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Stamp_Duty_CalculatorQuery, Stamp_Duty_CalculatorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Stamp_Duty_CalculatorQuery, Stamp_Duty_CalculatorQueryVariables>(
    Stamp_Duty_CalculatorDocument,
    options
  );
}
export type Stamp_Duty_CalculatorQueryHookResult = ReturnType<typeof useStamp_Duty_CalculatorQuery>;
export type Stamp_Duty_CalculatorLazyQueryHookResult = ReturnType<typeof useStamp_Duty_CalculatorLazyQuery>;
export type Stamp_Duty_CalculatorQueryResult = Apollo.QueryResult<
  Stamp_Duty_CalculatorQuery,
  Stamp_Duty_CalculatorQueryVariables
>;
export const Create_SurveyingDocument = gql`
  mutation CREATE_SURVEYING($formSubmission: FormSubmissionInput!) {
    form {
      createFormSubmission(formSubmission: $formSubmission) {
        errorMessage
        success
      }
    }
  }
`;
export type Create_SurveyingMutationFn = Apollo.MutationFunction<
  Create_SurveyingMutation,
  Create_SurveyingMutationVariables
>;

/**
 * __useCreate_SurveyingMutation__
 *
 * To run a mutation, you first call `useCreate_SurveyingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_SurveyingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyingMutation, { data, loading, error }] = useCreate_SurveyingMutation({
 *   variables: {
 *      formSubmission: // value for 'formSubmission'
 *   },
 * });
 */
export function useCreate_SurveyingMutation(
  baseOptions?: Apollo.MutationHookOptions<Create_SurveyingMutation, Create_SurveyingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Create_SurveyingMutation, Create_SurveyingMutationVariables>(
    Create_SurveyingDocument,
    options
  );
}
export type Create_SurveyingMutationHookResult = ReturnType<typeof useCreate_SurveyingMutation>;
export type Create_SurveyingMutationResult = Apollo.MutationResult<Create_SurveyingMutation>;
export type Create_SurveyingMutationOptions = Apollo.BaseMutationOptions<
  Create_SurveyingMutation,
  Create_SurveyingMutationVariables
>;
export const Create_UnsubscribeDocument = gql`
  mutation CREATE_UNSUBSCRIBE($formSubmission: FormSubmissionInput!) {
    form {
      createFormSubmission(formSubmission: $formSubmission) {
        errorMessage
        success
      }
    }
  }
`;
export type Create_UnsubscribeMutationFn = Apollo.MutationFunction<
  Create_UnsubscribeMutation,
  Create_UnsubscribeMutationVariables
>;

/**
 * __useCreate_UnsubscribeMutation__
 *
 * To run a mutation, you first call `useCreate_UnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_UnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnsubscribeMutation, { data, loading, error }] = useCreate_UnsubscribeMutation({
 *   variables: {
 *      formSubmission: // value for 'formSubmission'
 *   },
 * });
 */
export function useCreate_UnsubscribeMutation(
  baseOptions?: Apollo.MutationHookOptions<Create_UnsubscribeMutation, Create_UnsubscribeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Create_UnsubscribeMutation, Create_UnsubscribeMutationVariables>(
    Create_UnsubscribeDocument,
    options
  );
}
export type Create_UnsubscribeMutationHookResult = ReturnType<typeof useCreate_UnsubscribeMutation>;
export type Create_UnsubscribeMutationResult = Apollo.MutationResult<Create_UnsubscribeMutation>;
export type Create_UnsubscribeMutationOptions = Apollo.BaseMutationOptions<
  Create_UnsubscribeMutation,
  Create_UnsubscribeMutationVariables
>;
export const Get_Audience_DataDocument = gql`
  query GET_AUDIENCE_DATA {
    audienceData: kfhContentGlobalSettingsUmbracoType {
      ...AudienceData
    }
  }
  ${AudienceDataFragmentDoc}
`;

/**
 * __useGet_Audience_DataQuery__
 *
 * To run a query within a React component, call `useGet_Audience_DataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Audience_DataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Audience_DataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Audience_DataQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Audience_DataQuery, Get_Audience_DataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Audience_DataQuery, Get_Audience_DataQueryVariables>(Get_Audience_DataDocument, options);
}
export function useGet_Audience_DataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Audience_DataQuery, Get_Audience_DataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Audience_DataQuery, Get_Audience_DataQueryVariables>(
    Get_Audience_DataDocument,
    options
  );
}
export type Get_Audience_DataQueryHookResult = ReturnType<typeof useGet_Audience_DataQuery>;
export type Get_Audience_DataLazyQueryHookResult = ReturnType<typeof useGet_Audience_DataLazyQuery>;
export type Get_Audience_DataQueryResult = Apollo.QueryResult<Get_Audience_DataQuery, Get_Audience_DataQueryVariables>;
export const Get_Bank_HolidaysDocument = gql`
  query GET_BANK_HOLIDAYS {
    allBankHoliday(
      filter: { division: { eq: "England And Wales" }, date: { gte: "2021-12-01" } }
      sort: { date: ASC }
    ) {
      nodes {
        id
        date
      }
    }
  }
`;

/**
 * __useGet_Bank_HolidaysQuery__
 *
 * To run a query within a React component, call `useGet_Bank_HolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Bank_HolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Bank_HolidaysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Bank_HolidaysQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Bank_HolidaysQuery, Get_Bank_HolidaysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Bank_HolidaysQuery, Get_Bank_HolidaysQueryVariables>(Get_Bank_HolidaysDocument, options);
}
export function useGet_Bank_HolidaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Bank_HolidaysQuery, Get_Bank_HolidaysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Bank_HolidaysQuery, Get_Bank_HolidaysQueryVariables>(
    Get_Bank_HolidaysDocument,
    options
  );
}
export type Get_Bank_HolidaysQueryHookResult = ReturnType<typeof useGet_Bank_HolidaysQuery>;
export type Get_Bank_HolidaysLazyQueryHookResult = ReturnType<typeof useGet_Bank_HolidaysLazyQuery>;
export type Get_Bank_HolidaysQueryResult = Apollo.QueryResult<Get_Bank_HolidaysQuery, Get_Bank_HolidaysQueryVariables>;
export const Build_InfoDocument = gql`
  query BUILD_INFO {
    kfhBuildInfo {
      lastBuild
    }
  }
`;

/**
 * __useBuild_InfoQuery__
 *
 * To run a query within a React component, call `useBuild_InfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuild_InfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuild_InfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useBuild_InfoQuery(baseOptions?: Apollo.QueryHookOptions<Build_InfoQuery, Build_InfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Build_InfoQuery, Build_InfoQueryVariables>(Build_InfoDocument, options);
}
export function useBuild_InfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Build_InfoQuery, Build_InfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Build_InfoQuery, Build_InfoQueryVariables>(Build_InfoDocument, options);
}
export type Build_InfoQueryHookResult = ReturnType<typeof useBuild_InfoQuery>;
export type Build_InfoLazyQueryHookResult = ReturnType<typeof useBuild_InfoLazyQuery>;
export type Build_InfoQueryResult = Apollo.QueryResult<Build_InfoQuery, Build_InfoQueryVariables>;
export const Get_CurrenciesDocument = gql`
  query GET_CURRENCIES {
    allKfhCurrencyType {
      edges {
        node {
          code
          rate
          id
          remoteId
          format
          inverseRate
          name
        }
      }
    }
  }
`;

/**
 * __useGet_CurrenciesQuery__
 *
 * To run a query within a React component, call `useGet_CurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_CurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_CurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_CurrenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_CurrenciesQuery, Get_CurrenciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_CurrenciesQuery, Get_CurrenciesQueryVariables>(Get_CurrenciesDocument, options);
}
export function useGet_CurrenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_CurrenciesQuery, Get_CurrenciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_CurrenciesQuery, Get_CurrenciesQueryVariables>(Get_CurrenciesDocument, options);
}
export type Get_CurrenciesQueryHookResult = ReturnType<typeof useGet_CurrenciesQuery>;
export type Get_CurrenciesLazyQueryHookResult = ReturnType<typeof useGet_CurrenciesLazyQuery>;
export type Get_CurrenciesQueryResult = Apollo.QueryResult<Get_CurrenciesQuery, Get_CurrenciesQueryVariables>;
export const GetKfhGlobalSettingsDocument = gql`
  query getKfhGlobalSettings {
    site {
      siteMetadata {
        siteUrl
        title
        googleVerification
        pinterestVerification
      }
    }
    kfhSocialSettings: kfhSocialSettingsType {
      facebookUsernameOrId
      facebookServiceUrl
      facebookAppId
      gravatarServiceUrl
      linkedInUsername
      linkedInServiceUrl
      pintrestScreenName
      slideShareName
      twitterScreenName
      twitterServiceUrl
      twitterDataWidgetId
      youTubeUsername
      youTubeServiceUrl
      openGraphEnabled
      openGraphSiteName
      openGraphArticleAuthorEnabled
      openGraphPublisher
    }
    kfhClientSettings: kfhKfhSettingsType {
      companyName
      companyNameLimited
      addressLine1
      addressLine2
      addressLine3
      county
      postcode
      whereRegistered
      companyNumber
      vatRegistrationNumber
      tradingName
      conveyancingQuoteEmail
      commercialTeamName
      commercialPhone
      commercialEmail
      branchBlanketMessage
      branchBlanketMessageStart
      branchBlanketMessageEnd
      branchSalesJobTitle
      branchLettingsJobTitle
      arrangeViewingCTASendBeeApi
      coronavirusInLockdown
      helpToBuyEnabled
      propetyValuationPositionInSearch
      advertEnabled
      advertLocationLat
      advertLocationLong
      advertRadius
      advertBanner
      advertSearch
      propetyValuationPositionInSearch_2
      advertEnabled_2
      advertLocationLat_2
      advertLocationLong_2
      advertRadius_2
      advertBanner_2
      advertSearch_2
      lettingsDisclaimer
      cookiePopupMessage
      branchAutocompleteIdentifier
      defaultCurrencyId
      currencyDisclaimer
      internalCookieStateIps
    }
  }
`;

/**
 * __useGetKfhGlobalSettingsQuery__
 *
 * To run a query within a React component, call `useGetKfhGlobalSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKfhGlobalSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKfhGlobalSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetKfhGlobalSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetKfhGlobalSettingsQuery, GetKfhGlobalSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetKfhGlobalSettingsQuery, GetKfhGlobalSettingsQueryVariables>(
    GetKfhGlobalSettingsDocument,
    options
  );
}
export function useGetKfhGlobalSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetKfhGlobalSettingsQuery, GetKfhGlobalSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetKfhGlobalSettingsQuery, GetKfhGlobalSettingsQueryVariables>(
    GetKfhGlobalSettingsDocument,
    options
  );
}
export type GetKfhGlobalSettingsQueryHookResult = ReturnType<typeof useGetKfhGlobalSettingsQuery>;
export type GetKfhGlobalSettingsLazyQueryHookResult = ReturnType<typeof useGetKfhGlobalSettingsLazyQuery>;
export type GetKfhGlobalSettingsQueryResult = Apollo.QueryResult<
  GetKfhGlobalSettingsQuery,
  GetKfhGlobalSettingsQueryVariables
>;
export const Get_Basic_Search_MetaDocument = gql`
  query GET_BASIC_SEARCH_META {
    kfhPropertySearchMeta: kfhPropertySearchMetaType {
      resaleLowestPriceSterling {
        id
        name
        value
        selected
      }
      resaleLowestPriceEuro {
        id
        name
        value
        selected
      }
      resaleLowestPriceDollar {
        id
        name
        value
        selected
      }
      resaleHighestPriceSterling {
        id
        name
        value
        selected
      }
      resaleHighestPriceEuro {
        id
        name
        value
        selected
      }
      resaleHighestPriceDollar {
        id
        name
        value
        selected
      }
      rentalLowestPriceSterling {
        id
        name
        value
        selected
      }
      rentalLowestPriceEuro {
        id
        name
        value
        selected
      }
      rentalLowestPriceDollar {
        id
        name
        value
        selected
      }
      rentalHighestPriceSterling {
        id
        name
        value
        selected
      }
      rentalHighestPriceEuro {
        id
        name
        value
        selected
      }
      rentalHighestPriceDollar {
        id
        name
        value
        selected
      }
      rentalLowestWeeklyPriceSterling {
        id
        name
        value
        selected
      }
      rentalLowestWeeklyPriceEuro {
        id
        name
        value
        selected
      }
      rentalLowestWeeklyPriceDollar {
        id
        name
        value
        selected
      }
      rentalHighestWeeklyPriceSterling {
        id
        name
        value
        selected
      }
      rentalHighestWeeklyPriceEuro {
        id
        name
        value
        selected
      }
      rentalHighestWeeklyPriceDollar {
        id
        name
        value
        selected
      }
      bedroomsMinimum {
        id
        name
        value
        selected
      }
      bedroomsMaximum {
        id
        name
        value
        selected
      }
      sortbyOptions {
        id
        name
        value
        selected
        query
      }
      perPage {
        id
        name
        value
        selected
      }
      furnishState {
        id
        name
        value
        selected
      }
      radius {
        id
        name
        value
        selected
      }
      commercialMinimumSize {
        id
        name
        value
        selected
      }
      commercialMaximumSize {
        id
        name
        value
        selected
      }
    }
  }
`;

/**
 * __useGet_Basic_Search_MetaQuery__
 *
 * To run a query within a React component, call `useGet_Basic_Search_MetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Basic_Search_MetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Basic_Search_MetaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Basic_Search_MetaQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Basic_Search_MetaQuery, Get_Basic_Search_MetaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Basic_Search_MetaQuery, Get_Basic_Search_MetaQueryVariables>(
    Get_Basic_Search_MetaDocument,
    options
  );
}
export function useGet_Basic_Search_MetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Basic_Search_MetaQuery, Get_Basic_Search_MetaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Basic_Search_MetaQuery, Get_Basic_Search_MetaQueryVariables>(
    Get_Basic_Search_MetaDocument,
    options
  );
}
export type Get_Basic_Search_MetaQueryHookResult = ReturnType<typeof useGet_Basic_Search_MetaQuery>;
export type Get_Basic_Search_MetaLazyQueryHookResult = ReturnType<typeof useGet_Basic_Search_MetaLazyQuery>;
export type Get_Basic_Search_MetaQueryResult = Apollo.QueryResult<
  Get_Basic_Search_MetaQuery,
  Get_Basic_Search_MetaQueryVariables
>;
export const Get_Basic_Search_SeoDocument = gql`
  query GET_BASIC_SEARCH_SEO {
    allKfhExtraContentSeoType {
      nodes {
        id
        metaCopyright
        h1
        htmlDescription
        metaAuthor
        metaKeywords
        metaDescription
        noFollow
        noIndex
        onlynewhomes
        title
        category
        type
        searchType
        unavailable
        propertyEndpointApi
      }
    }
  }
`;

/**
 * __useGet_Basic_Search_SeoQuery__
 *
 * To run a query within a React component, call `useGet_Basic_Search_SeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Basic_Search_SeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Basic_Search_SeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Basic_Search_SeoQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Basic_Search_SeoQuery, Get_Basic_Search_SeoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Basic_Search_SeoQuery, Get_Basic_Search_SeoQueryVariables>(
    Get_Basic_Search_SeoDocument,
    options
  );
}
export function useGet_Basic_Search_SeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Basic_Search_SeoQuery, Get_Basic_Search_SeoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Basic_Search_SeoQuery, Get_Basic_Search_SeoQueryVariables>(
    Get_Basic_Search_SeoDocument,
    options
  );
}
export type Get_Basic_Search_SeoQueryHookResult = ReturnType<typeof useGet_Basic_Search_SeoQuery>;
export type Get_Basic_Search_SeoLazyQueryHookResult = ReturnType<typeof useGet_Basic_Search_SeoLazyQuery>;
export type Get_Basic_Search_SeoQueryResult = Apollo.QueryResult<
  Get_Basic_Search_SeoQuery,
  Get_Basic_Search_SeoQueryVariables
>;
export const Get_Property_FacetsDocument = gql`
  query GET_PROPERTY_FACETS {
    kfhPropertyFacets {
      residentialRentalFlatProperties {
        searchFacets {
          ...SearchFacets
        }
      }
      residentialRentalHouseProperties {
        searchFacets {
          ...SearchFacets
        }
      }
      residentialRentalProperties {
        searchFacets {
          ...SearchFacets
        }
      }
      residentialRentalPropertiesLet {
        searchFacets {
          ...SearchFacets
        }
      }
      residentialResaleFlatProperties {
        searchFacets {
          ...SearchFacets
        }
      }
      residentialResaleHouseProperties {
        searchFacets {
          ...SearchFacets
        }
      }
      residentialResaleProperties {
        searchFacets {
          ...SearchFacets
        }
      }
      residentialResalePropertiesSold {
        searchFacets {
          ...SearchFacets
        }
      }
      commercialProperties {
        searchFacets {
          ...SearchFacets
        }
      }
      developmentResaleProperties {
        searchFacets {
          ...SearchFacets
        }
      }
      newHomeProperties {
        searchFacets {
          ...SearchFacets
        }
      }
    }
  }
  ${SearchFacetsFragmentDoc}
`;

/**
 * __useGet_Property_FacetsQuery__
 *
 * To run a query within a React component, call `useGet_Property_FacetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Property_FacetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Property_FacetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Property_FacetsQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Property_FacetsQuery, Get_Property_FacetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Property_FacetsQuery, Get_Property_FacetsQueryVariables>(
    Get_Property_FacetsDocument,
    options
  );
}
export function useGet_Property_FacetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Property_FacetsQuery, Get_Property_FacetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Property_FacetsQuery, Get_Property_FacetsQueryVariables>(
    Get_Property_FacetsDocument,
    options
  );
}
export type Get_Property_FacetsQueryHookResult = ReturnType<typeof useGet_Property_FacetsQuery>;
export type Get_Property_FacetsLazyQueryHookResult = ReturnType<typeof useGet_Property_FacetsLazyQuery>;
export type Get_Property_FacetsQueryResult = Apollo.QueryResult<
  Get_Property_FacetsQuery,
  Get_Property_FacetsQueryVariables
>;
export const NotFoundQueryDocument = gql`
  query notFoundQuery {
    notFoundData: kfhContentGlobalSettingsUmbracoType {
      notFound {
        heading
        lead
        linkLists {
          items {
            link {
              name
              url
            }
          }
        }
      }
    }
  }
`;

/**
 * __useNotFoundQueryQuery__
 *
 * To run a query within a React component, call `useNotFoundQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotFoundQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotFoundQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotFoundQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<NotFoundQueryQuery, NotFoundQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotFoundQueryQuery, NotFoundQueryQueryVariables>(NotFoundQueryDocument, options);
}
export function useNotFoundQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotFoundQueryQuery, NotFoundQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotFoundQueryQuery, NotFoundQueryQueryVariables>(NotFoundQueryDocument, options);
}
export type NotFoundQueryQueryHookResult = ReturnType<typeof useNotFoundQueryQuery>;
export type NotFoundQueryLazyQueryHookResult = ReturnType<typeof useNotFoundQueryLazyQuery>;
export type NotFoundQueryQueryResult = Apollo.QueryResult<NotFoundQueryQuery, NotFoundQueryQueryVariables>;
export const GetLocationsAreaMapsDocument = gql`
  query getLocationsAreaMaps {
    regions: allKfhRegionType {
      nodes {
        id
        name
        staticMap {
          ...LocationStaticMap
        }
      }
    }
    areas: allKfhAreaType {
      nodes {
        id
        name
        longitude
        latitude
        polyline
        staticMap {
          ...LocationStaticMap
        }
      }
    }
  }
  ${LocationStaticMapFragmentDoc}
`;

/**
 * __useGetLocationsAreaMapsQuery__
 *
 * To run a query within a React component, call `useGetLocationsAreaMapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsAreaMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsAreaMapsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsAreaMapsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLocationsAreaMapsQuery, GetLocationsAreaMapsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocationsAreaMapsQuery, GetLocationsAreaMapsQueryVariables>(
    GetLocationsAreaMapsDocument,
    options
  );
}
export function useGetLocationsAreaMapsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsAreaMapsQuery, GetLocationsAreaMapsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocationsAreaMapsQuery, GetLocationsAreaMapsQueryVariables>(
    GetLocationsAreaMapsDocument,
    options
  );
}
export type GetLocationsAreaMapsQueryHookResult = ReturnType<typeof useGetLocationsAreaMapsQuery>;
export type GetLocationsAreaMapsLazyQueryHookResult = ReturnType<typeof useGetLocationsAreaMapsLazyQuery>;
export type GetLocationsAreaMapsQueryResult = Apollo.QueryResult<
  GetLocationsAreaMapsQuery,
  GetLocationsAreaMapsQueryVariables
>;
export const BranchPagePageQueryDocument = gql`
  query BranchPagePageQuery($id: String!, $businessId: Int!, $areaRentalPropertyIds: [String]) {
    branch: kfhBranchType(id: { eq: $id }) {
      ...BranchDetails
      area {
        properties(ids: $areaRentalPropertyIds) {
          ...PropertyListingProperties
        }
      }
    }
    settings: kfhKfhSettingsType {
      branchBlanketMessage
      branchBlanketMessageEnd
      branchBlanketMessageStart
    }
    resaleProperties: allKfhResidentialResalePropertyType(
      limit: 6
      filter: { businessId: { eq: $businessId }, available: { eq: true } }
      sort: { createdDate: DESC }
    ) {
      edges {
        node {
          ...PropertyListingProperties
        }
      }
    }
    rentalProperties: allKfhResidentialRentalPropertyType(
      limit: 6
      filter: { businessId: { eq: $businessId }, available: { eq: true }, sellingState: { ne: "Under offer" } }
      sort: { createdDate: DESC }
    ) {
      edges {
        node {
          ...PropertyListingProperties
        }
      }
    }
  }
  ${BranchDetailsFragmentDoc}
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useBranchPagePageQueryQuery__
 *
 * To run a query within a React component, call `useBranchPagePageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchPagePageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchPagePageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      businessId: // value for 'businessId'
 *      areaRentalPropertyIds: // value for 'areaRentalPropertyIds'
 *   },
 * });
 */
export function useBranchPagePageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<BranchPagePageQueryQuery, BranchPagePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BranchPagePageQueryQuery, BranchPagePageQueryQueryVariables>(
    BranchPagePageQueryDocument,
    options
  );
}
export function useBranchPagePageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BranchPagePageQueryQuery, BranchPagePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BranchPagePageQueryQuery, BranchPagePageQueryQueryVariables>(
    BranchPagePageQueryDocument,
    options
  );
}
export type BranchPagePageQueryQueryHookResult = ReturnType<typeof useBranchPagePageQueryQuery>;
export type BranchPagePageQueryLazyQueryHookResult = ReturnType<typeof useBranchPagePageQueryLazyQuery>;
export type BranchPagePageQueryQueryResult = Apollo.QueryResult<
  BranchPagePageQueryQuery,
  BranchPagePageQueryQueryVariables
>;
export const JobPagePageQueryDocument = gql`
  query JobPagePageQuery($id: String!) {
    job: kfhJobType(id: { eq: $id }) {
      id
      name
      location
      content
      employmentType
      absoluteUrl
      seo {
        ...SEO
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useJobPagePageQueryQuery__
 *
 * To run a query within a React component, call `useJobPagePageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPagePageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPagePageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobPagePageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<JobPagePageQueryQuery, JobPagePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JobPagePageQueryQuery, JobPagePageQueryQueryVariables>(JobPagePageQueryDocument, options);
}
export function useJobPagePageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobPagePageQueryQuery, JobPagePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JobPagePageQueryQuery, JobPagePageQueryQueryVariables>(JobPagePageQueryDocument, options);
}
export type JobPagePageQueryQueryHookResult = ReturnType<typeof useJobPagePageQueryQuery>;
export type JobPagePageQueryLazyQueryHookResult = ReturnType<typeof useJobPagePageQueryLazyQuery>;
export type JobPagePageQueryQueryResult = Apollo.QueryResult<JobPagePageQueryQuery, JobPagePageQueryQueryVariables>;
export const AreaPagePageQueryDocument = gql`
  query AreaPagePageQuery($id: String!) {
    resaleProperties: allKfhResidentialResalePropertyType(
      limit: 6
      filter: { area: { id: { eq: $id } }, available: { eq: true } }
      sort: { createdDate: DESC }
    ) {
      edges {
        node {
          ...PropertyListingProperties
        }
      }
    }
    rentalProperties: allKfhResidentialRentalPropertyType(
      limit: 6
      filter: { area: { id: { eq: $id } }, available: { eq: true }, sellingState: { ne: "Under offer" } }
      sort: { createdDate: DESC }
    ) {
      edges {
        node {
          ...PropertyListingProperties
        }
      }
    }
    area: kfhAreaType(id: { eq: $id }) {
      __typename
      id
      name
      relativeUrl
      artirixMultiSearch
      artirixDisplayName
      guide
      latitude
      longitude
      staticMap {
        ...LocationStaticMap
      }
      coordinates {
        lat: latitude
        lng: longitude
      }
      mediaSet {
        media {
          media {
            ...LocationGalleryImage
          }
        }
      }
      locationSoldData {
        soldData {
          monthlyStatistics {
            average
          }
        }
      }
      propertiesForSale {
        properties {
          totalCount
        }
      }
      propertiesForRent {
        properties {
          totalCount
        }
      }
      relatedBoroughs {
        edges {
          node {
            id
            name
            relativeUrl
          }
        }
      }
      relatedAreas {
        edges {
          node {
            id
            relativeUrl
            name
          }
        }
      }
      branch {
        name
        relativeUrl
        businessProfile {
          lettingsContactDetails {
            email
            telephone
          }
          salesContactDetails {
            email
            telephone
          }
        }
      }
      region {
        name
        relativeUrl
      }
      seoPages {
        ... on KFH_KfhSeoAreaGuideType {
          id
          h1
          htmlDescription
          title
          slug
          remoteTypeName
          priority
          onSiteMap
          noIndex
          metaKeywords
          noFollow
          metaDescription
          metaCopyright
          metaAuthor
        }
      }
    }
  }
  ${PropertyListingPropertiesFragmentDoc}
  ${LocationStaticMapFragmentDoc}
  ${LocationGalleryImageFragmentDoc}
`;

/**
 * __useAreaPagePageQueryQuery__
 *
 * To run a query within a React component, call `useAreaPagePageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaPagePageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaPagePageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAreaPagePageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<AreaPagePageQueryQuery, AreaPagePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AreaPagePageQueryQuery, AreaPagePageQueryQueryVariables>(AreaPagePageQueryDocument, options);
}
export function useAreaPagePageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AreaPagePageQueryQuery, AreaPagePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AreaPagePageQueryQuery, AreaPagePageQueryQueryVariables>(
    AreaPagePageQueryDocument,
    options
  );
}
export type AreaPagePageQueryQueryHookResult = ReturnType<typeof useAreaPagePageQueryQuery>;
export type AreaPagePageQueryLazyQueryHookResult = ReturnType<typeof useAreaPagePageQueryLazyQuery>;
export type AreaPagePageQueryQueryResult = Apollo.QueryResult<AreaPagePageQueryQuery, AreaPagePageQueryQueryVariables>;
export const AreaCouncilTaxPageQueryDocument = gql`
  query AreaCouncilTaxPageQuery($id: String!) {
    area: kfhAreaType(id: { eq: $id }) {
      id
      name
      relativeUrl
      artirixMultiSearch
      artirixDisplayName
      region {
        name
        relativeUrl
      }
      relatedBoroughs {
        edges {
          node {
            name
            councilTaxModel {
              previousTaxYear
              currentTaxYear
              rateChange
              direction
              councilTaxBands {
                name
                tax
                year
              }
              taxBands {
                band
                direction
                lastYear
                rateChange
                thisYear
              }
            }
          }
        }
      }
      seoPages {
        ... on KFH_KfhSeoAreaGuideCouncilTaxType {
          id
          h1
          htmlDescription
          metaAuthor
          metaCopyright
          metaDescription
          metaKeywords
          noIndex
          onSiteMap
          priority
          remoteTypeName
          slug
          title
          noFollow
        }
      }
    }
  }
`;

/**
 * __useAreaCouncilTaxPageQueryQuery__
 *
 * To run a query within a React component, call `useAreaCouncilTaxPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaCouncilTaxPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaCouncilTaxPageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAreaCouncilTaxPageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<AreaCouncilTaxPageQueryQuery, AreaCouncilTaxPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AreaCouncilTaxPageQueryQuery, AreaCouncilTaxPageQueryQueryVariables>(
    AreaCouncilTaxPageQueryDocument,
    options
  );
}
export function useAreaCouncilTaxPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AreaCouncilTaxPageQueryQuery, AreaCouncilTaxPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AreaCouncilTaxPageQueryQuery, AreaCouncilTaxPageQueryQueryVariables>(
    AreaCouncilTaxPageQueryDocument,
    options
  );
}
export type AreaCouncilTaxPageQueryQueryHookResult = ReturnType<typeof useAreaCouncilTaxPageQueryQuery>;
export type AreaCouncilTaxPageQueryLazyQueryHookResult = ReturnType<typeof useAreaCouncilTaxPageQueryLazyQuery>;
export type AreaCouncilTaxPageQueryQueryResult = Apollo.QueryResult<
  AreaCouncilTaxPageQueryQuery,
  AreaCouncilTaxPageQueryQueryVariables
>;
export const AreaSchoolsPageQueryDocument = gql`
  query AreaSchoolsPageQuery($id: String!) {
    area: kfhAreaType(id: { eq: $id }) {
      name
      relativeUrl
      artirixMultiSearch
      artirixDisplayName
      coordinates {
        lat: latitude
        lng: longitude
      }
      locationSchoolData {
        schoolData {
          id
          educationPhase
          name
          schoolAddress {
            streetname
            town
            postcode
          }
          telephone
          schoolUrl
          establishmentType
          religiousCharacter
          gender
          minAge
          maxAge
          latitude
          longitude
        }
      }
      seoPages {
        ... on KFH_KfhSeoAreaGuideSchoolType {
          id
          h1
          htmlDescription
          metaAuthor
          metaCopyright
          metaDescription
          metaKeywords
          noFollow
          noIndex
          onSiteMap
          priority
          remoteTypeName
          slug
          title
        }
      }
    }
  }
`;

/**
 * __useAreaSchoolsPageQueryQuery__
 *
 * To run a query within a React component, call `useAreaSchoolsPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaSchoolsPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaSchoolsPageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAreaSchoolsPageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<AreaSchoolsPageQueryQuery, AreaSchoolsPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AreaSchoolsPageQueryQuery, AreaSchoolsPageQueryQueryVariables>(
    AreaSchoolsPageQueryDocument,
    options
  );
}
export function useAreaSchoolsPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AreaSchoolsPageQueryQuery, AreaSchoolsPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AreaSchoolsPageQueryQuery, AreaSchoolsPageQueryQueryVariables>(
    AreaSchoolsPageQueryDocument,
    options
  );
}
export type AreaSchoolsPageQueryQueryHookResult = ReturnType<typeof useAreaSchoolsPageQueryQuery>;
export type AreaSchoolsPageQueryLazyQueryHookResult = ReturnType<typeof useAreaSchoolsPageQueryLazyQuery>;
export type AreaSchoolsPageQueryQueryResult = Apollo.QueryResult<
  AreaSchoolsPageQueryQuery,
  AreaSchoolsPageQueryQueryVariables
>;
export const AreaSoldDataPageQueryDocument = gql`
  query AreaSoldDataPageQuery($id: String!) {
    area: kfhAreaType(id: { eq: $id }) {
      id
      name
      relativeUrl
      artirixMultiSearch
      artirixDisplayName
      locationSoldDataChange {
        soldDataChanges {
          averages {
            year
            priceChange
            percentageChange
            direction
            average
          }
        }
      }
      locationSoldData {
        soldData {
          yearlyAverage {
            flatAverage
            average
            houseAverage
            year
          }
          monthlyStatistics {
            average
            detachedAverage
            flatAverage
            flatSold
            houseAverage
            houseSold
            period
            semiDetachedAverage
            summary
            terraceAverage
          }
          summary
          summary2
          lastYearMonthlyStatistics {
            averageMeanPrice
            flatMeanPrice
            houseMeanPrice
            monthYear
          }
        }
      }
      region {
        name
        locationSoldDataChange {
          soldDataChanges {
            averages {
              year
              priceChange
              percentageChange
              direction
              average
            }
          }
        }
        relatedAreas {
          edges {
            node {
              id
              name
              relativeUrl
              locationSoldDataChange {
                soldDataChanges {
                  averages {
                    year
                    priceChange
                    percentageChange
                    direction
                    average
                  }
                }
              }
            }
          }
        }
      }
      seoPages {
        remoteTypeName
        ... on KFH_KfhSeoAreaGuideSoldType {
          id
          h1
          htmlDescription
          metaAuthor
          metaCopyright
          noFollow
          metaKeywords
          metaDescription
          noIndex
          onSiteMap
          priority
          remoteTypeName
          slug
          title
        }
      }
    }
  }
`;

/**
 * __useAreaSoldDataPageQueryQuery__
 *
 * To run a query within a React component, call `useAreaSoldDataPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaSoldDataPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaSoldDataPageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAreaSoldDataPageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<AreaSoldDataPageQueryQuery, AreaSoldDataPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AreaSoldDataPageQueryQuery, AreaSoldDataPageQueryQueryVariables>(
    AreaSoldDataPageQueryDocument,
    options
  );
}
export function useAreaSoldDataPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AreaSoldDataPageQueryQuery, AreaSoldDataPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AreaSoldDataPageQueryQuery, AreaSoldDataPageQueryQueryVariables>(
    AreaSoldDataPageQueryDocument,
    options
  );
}
export type AreaSoldDataPageQueryQueryHookResult = ReturnType<typeof useAreaSoldDataPageQueryQuery>;
export type AreaSoldDataPageQueryLazyQueryHookResult = ReturnType<typeof useAreaSoldDataPageQueryLazyQuery>;
export type AreaSoldDataPageQueryQueryResult = Apollo.QueryResult<
  AreaSoldDataPageQueryQuery,
  AreaSoldDataPageQueryQueryVariables
>;
export const AreaTransportPageQueryDocument = gql`
  query AreaTransportPageQuery($id: String!) {
    area: kfhAreaType(id: { eq: $id }) {
      id
      name
      relativeUrl
      artirixMultiSearch
      artirixDisplayName
      coordinates {
        lat: latitude
        lng: longitude
      }
      stations {
        name
        code
        id
        latitude
        longitude
        zones {
          name
        }
        lines {
          name
        }
      }
      seoPages {
        ... on KFH_KfhSeoAreaGuideTransportType {
          id
          h1
          htmlDescription
          metaAuthor
          metaCopyright
          metaDescription
          metaKeywords
          noIndex
          onSiteMap
          priority
          remoteTypeName
          slug
          title
          noFollow
        }
      }
    }
  }
`;

/**
 * __useAreaTransportPageQueryQuery__
 *
 * To run a query within a React component, call `useAreaTransportPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaTransportPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaTransportPageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAreaTransportPageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<AreaTransportPageQueryQuery, AreaTransportPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AreaTransportPageQueryQuery, AreaTransportPageQueryQueryVariables>(
    AreaTransportPageQueryDocument,
    options
  );
}
export function useAreaTransportPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AreaTransportPageQueryQuery, AreaTransportPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AreaTransportPageQueryQuery, AreaTransportPageQueryQueryVariables>(
    AreaTransportPageQueryDocument,
    options
  );
}
export type AreaTransportPageQueryQueryHookResult = ReturnType<typeof useAreaTransportPageQueryQuery>;
export type AreaTransportPageQueryLazyQueryHookResult = ReturnType<typeof useAreaTransportPageQueryLazyQuery>;
export type AreaTransportPageQueryQueryResult = Apollo.QueryResult<
  AreaTransportPageQueryQuery,
  AreaTransportPageQueryQueryVariables
>;
export const RegionPagePageQueryDocument = gql`
  query RegionPagePageQuery($id: String!) {
    resaleProperties: allKfhResidentialResalePropertyType(
      limit: 6
      filter: { area: { region: { id: { eq: $id } } }, available: { eq: true } }
      sort: { createdDate: DESC }
    ) {
      edges {
        node {
          ...PropertyListingProperties
        }
      }
    }
    rentalProperties: allKfhResidentialRentalPropertyType(
      limit: 6
      filter: { area: { region: { id: { eq: $id } } }, available: { eq: true }, sellingState: { ne: "Under offer" } }
      sort: { createdDate: DESC }
    ) {
      edges {
        node {
          ...PropertyListingProperties
        }
      }
    }
    region: kfhRegionType(id: { eq: $id }) {
      __typename
      id
      name
      relativeUrl
      guide
      shortDescription
      artirixMultiSearch
      artirixDisplayName
      latitude
      longitude
      staticMap {
        ...LocationStaticMap
      }
      polyline
      coordinates {
        lat: latitude
        lng: longitude
      }
      mediaSet {
        media {
          media {
            ...LocationGalleryImage
          }
        }
      }
      locationSoldDataChange {
        soldDataChanges {
          averages {
            average
            direction
            percentageChange
          }
        }
      }
      propertiesForSale {
        properties {
          totalCount
        }
      }
      propertiesForRent {
        properties {
          totalCount
        }
      }
      relatedBoroughs {
        edges {
          node {
            id
            name
            relativeUrl
          }
        }
      }
      relatedAreas {
        edges {
          node {
            id
            name
            relativeUrl
          }
        }
      }
      seoPages {
        ... on KFH_KfhSeoAreaGuideType {
          id
          h1
          htmlDescription
          metaAuthor
          metaCopyright
          metaDescription
          metaKeywords
          noIndex
          noFollow
          onSiteMap
          remoteTypeName
          priority
          slug
          title
        }
      }
    }
  }
  ${PropertyListingPropertiesFragmentDoc}
  ${LocationStaticMapFragmentDoc}
  ${LocationGalleryImageFragmentDoc}
`;

/**
 * __useRegionPagePageQueryQuery__
 *
 * To run a query within a React component, call `useRegionPagePageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionPagePageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionPagePageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegionPagePageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<RegionPagePageQueryQuery, RegionPagePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegionPagePageQueryQuery, RegionPagePageQueryQueryVariables>(
    RegionPagePageQueryDocument,
    options
  );
}
export function useRegionPagePageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RegionPagePageQueryQuery, RegionPagePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegionPagePageQueryQuery, RegionPagePageQueryQueryVariables>(
    RegionPagePageQueryDocument,
    options
  );
}
export type RegionPagePageQueryQueryHookResult = ReturnType<typeof useRegionPagePageQueryQuery>;
export type RegionPagePageQueryLazyQueryHookResult = ReturnType<typeof useRegionPagePageQueryLazyQuery>;
export type RegionPagePageQueryQueryResult = Apollo.QueryResult<
  RegionPagePageQueryQuery,
  RegionPagePageQueryQueryVariables
>;
export const RegionCouncilTaxPageQueryDocument = gql`
  query RegionCouncilTaxPageQuery($id: String!) {
    region: kfhRegionType(id: { eq: $id }) {
      id
      name
      relativeUrl
      artirixMultiSearch
      artirixDisplayName
      relatedBoroughs {
        edges {
          node {
            name
            councilTaxModel {
              previousTaxYear
              currentTaxYear
              rateChange
              direction
              councilTaxBands {
                name
                tax
                year
              }
              taxBands {
                band
                direction
                lastYear
                rateChange
                thisYear
              }
            }
          }
        }
      }
      seoPages {
        ... on KFH_KfhSeoAreaGuideCouncilTaxType {
          id
          h1
          htmlDescription
          metaAuthor
          metaCopyright
          metaDescription
          metaKeywords
          noIndex
          onSiteMap
          priority
          remoteTypeName
          slug
          title
          noFollow
        }
      }
    }
  }
`;

/**
 * __useRegionCouncilTaxPageQueryQuery__
 *
 * To run a query within a React component, call `useRegionCouncilTaxPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionCouncilTaxPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionCouncilTaxPageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegionCouncilTaxPageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<RegionCouncilTaxPageQueryQuery, RegionCouncilTaxPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegionCouncilTaxPageQueryQuery, RegionCouncilTaxPageQueryQueryVariables>(
    RegionCouncilTaxPageQueryDocument,
    options
  );
}
export function useRegionCouncilTaxPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RegionCouncilTaxPageQueryQuery, RegionCouncilTaxPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegionCouncilTaxPageQueryQuery, RegionCouncilTaxPageQueryQueryVariables>(
    RegionCouncilTaxPageQueryDocument,
    options
  );
}
export type RegionCouncilTaxPageQueryQueryHookResult = ReturnType<typeof useRegionCouncilTaxPageQueryQuery>;
export type RegionCouncilTaxPageQueryLazyQueryHookResult = ReturnType<typeof useRegionCouncilTaxPageQueryLazyQuery>;
export type RegionCouncilTaxPageQueryQueryResult = Apollo.QueryResult<
  RegionCouncilTaxPageQueryQuery,
  RegionCouncilTaxPageQueryQueryVariables
>;
export const RegionSoldDataPageQueryDocument = gql`
  query RegionSoldDataPageQuery($id: String!) {
    region: kfhRegionType(id: { eq: $id }) {
      id
      name
      relativeUrl
      artirixMultiSearch
      artirixDisplayName
      locationSoldDataChange {
        soldDataChanges {
          averages {
            year
            priceChange
            percentageChange
            direction
            average
          }
        }
      }
      locationSoldData {
        soldData {
          yearlyAverage {
            flatAverage
            average
            houseAverage
            year
          }
          monthlyStatistics {
            average
            detachedAverage
            flatAverage
            flatSold
            houseAverage
            houseSold
            period
            semiDetachedAverage
            summary
            terraceAverage
          }
          summary
          summary2
          lastYearMonthlyStatistics {
            averageMeanPrice
            flatMeanPrice
            houseMeanPrice
            monthYear
          }
        }
      }
      relatedAreas {
        edges {
          node {
            id
            name
            relativeUrl
            locationSoldDataChange {
              soldDataChanges {
                averages {
                  year
                  priceChange
                  percentageChange
                  direction
                  average
                }
              }
            }
          }
        }
      }
      seoPages {
        ... on KFH_KfhSeoAreaGuideSoldType {
          id
          h1
          htmlDescription
          metaAuthor
          metaCopyright
          title
          slug
          remoteTypeName
          onSiteMap
          noIndex
          noFollow
          metaDescription
          metaKeywords
          priority
        }
      }
    }
  }
`;

/**
 * __useRegionSoldDataPageQueryQuery__
 *
 * To run a query within a React component, call `useRegionSoldDataPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionSoldDataPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionSoldDataPageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegionSoldDataPageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<RegionSoldDataPageQueryQuery, RegionSoldDataPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegionSoldDataPageQueryQuery, RegionSoldDataPageQueryQueryVariables>(
    RegionSoldDataPageQueryDocument,
    options
  );
}
export function useRegionSoldDataPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RegionSoldDataPageQueryQuery, RegionSoldDataPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegionSoldDataPageQueryQuery, RegionSoldDataPageQueryQueryVariables>(
    RegionSoldDataPageQueryDocument,
    options
  );
}
export type RegionSoldDataPageQueryQueryHookResult = ReturnType<typeof useRegionSoldDataPageQueryQuery>;
export type RegionSoldDataPageQueryLazyQueryHookResult = ReturnType<typeof useRegionSoldDataPageQueryLazyQuery>;
export type RegionSoldDataPageQueryQueryResult = Apollo.QueryResult<
  RegionSoldDataPageQueryQuery,
  RegionSoldDataPageQueryQueryVariables
>;
export const CareerListingPageQueryDocument = gql`
  query CareerListingPageQuery($id: String!) {
    page: kfhContentPageUmbracoType(id: { eq: $id }) {
      layout {
        ... on KFH_ContentCareerListingType {
          title
        }
      }
      seoSettings {
        ...SEO
      }
      feed {
        featuredImage {
          ...PageSocialImage
        }
      }
      classification {
        audiences
        services
      }
    }
    jobs: allKfhJobType {
      totalCount
    }
    jobCategories: allKfhJobCategoryType(sort: { order: ASC }) {
      edges {
        node {
          name
          description
          jobs {
            edges {
              node {
                id
                name
                location
                employmentType
                relativeUrl
              }
            }
            totalCount
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
  ${PageSocialImageFragmentDoc}
`;

/**
 * __useCareerListingPageQueryQuery__
 *
 * To run a query within a React component, call `useCareerListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareerListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareerListingPageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCareerListingPageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<CareerListingPageQueryQuery, CareerListingPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareerListingPageQueryQuery, CareerListingPageQueryQueryVariables>(
    CareerListingPageQueryDocument,
    options
  );
}
export function useCareerListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CareerListingPageQueryQuery, CareerListingPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareerListingPageQueryQuery, CareerListingPageQueryQueryVariables>(
    CareerListingPageQueryDocument,
    options
  );
}
export type CareerListingPageQueryQueryHookResult = ReturnType<typeof useCareerListingPageQueryQuery>;
export type CareerListingPageQueryLazyQueryHookResult = ReturnType<typeof useCareerListingPageQueryLazyQuery>;
export type CareerListingPageQueryQueryResult = Apollo.QueryResult<
  CareerListingPageQueryQuery,
  CareerListingPageQueryQueryVariables
>;
export const CareersNewsQueryDocument = gql`
  query CareersNewsQuery($id: String!) {
    page: kfhContentPageUmbracoType(id: { eq: $id }) {
      layout {
        ... on KFH_ContentCareersNewsType {
          title
          regions {
            ...ComponentRegions
          }
          previousPage {
            pageName
            relativeUrl
          }
          nextPage {
            relativeUrl
            pageName
          }
        }
      }
      publishDate
      parentPages {
        relativeUrl
        pageName
      }
      seoSettings {
        ...SEO
      }
      feed {
        featuredImage {
          ...PageSocialImage
        }
      }
      classification {
        audiences
        services
      }
    }
  }
  ${ComponentRegionsFragmentDoc}
  ${SeoFragmentDoc}
  ${PageSocialImageFragmentDoc}
`;

/**
 * __useCareersNewsQueryQuery__
 *
 * To run a query within a React component, call `useCareersNewsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareersNewsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareersNewsQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCareersNewsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<CareersNewsQueryQuery, CareersNewsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareersNewsQueryQuery, CareersNewsQueryQueryVariables>(CareersNewsQueryDocument, options);
}
export function useCareersNewsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CareersNewsQueryQuery, CareersNewsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareersNewsQueryQuery, CareersNewsQueryQueryVariables>(CareersNewsQueryDocument, options);
}
export type CareersNewsQueryQueryHookResult = ReturnType<typeof useCareersNewsQueryQuery>;
export type CareersNewsQueryLazyQueryHookResult = ReturnType<typeof useCareersNewsQueryLazyQuery>;
export type CareersNewsQueryQueryResult = Apollo.QueryResult<CareersNewsQueryQuery, CareersNewsQueryQueryVariables>;
export const CareersNewsListingPageQueryDocument = gql`
  query CareersNewsListingPageQuery($ids: [String], $pageId: String) {
    page: kfhContentPageUmbracoType(id: { eq: $pageId }) {
      remoteTypeName
      remoteId
      pageName
      relativeUrl
      parentId
      id
      seoSettings {
        ...SEO
      }
      feed {
        featuredImage {
          ...PageSocialImage
        }
      }
      layout {
        ... on KFH_ContentCareersNewsListingType {
          title
          leadText
        }
      }
      classification {
        audiences
        services
      }
      listingPages(ids: $ids) {
        pageName
        id
        relativeUrl
        feed {
          featuredImage {
            localFile {
              gatsbyImage(
                width: 452
                height: 340
                layout: CONSTRAINED
                placeholder: NONE
                quality: 80
                backgroundColor: "#F5F5F5"
              )
            }
          }
        }
        publishDate
        layout {
          ... on KFH_ContentNewsArticleType {
            title
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
  ${PageSocialImageFragmentDoc}
`;

/**
 * __useCareersNewsListingPageQueryQuery__
 *
 * To run a query within a React component, call `useCareersNewsListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareersNewsListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareersNewsListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function useCareersNewsListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<CareersNewsListingPageQueryQuery, CareersNewsListingPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareersNewsListingPageQueryQuery, CareersNewsListingPageQueryQueryVariables>(
    CareersNewsListingPageQueryDocument,
    options
  );
}
export function useCareersNewsListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CareersNewsListingPageQueryQuery, CareersNewsListingPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareersNewsListingPageQueryQuery, CareersNewsListingPageQueryQueryVariables>(
    CareersNewsListingPageQueryDocument,
    options
  );
}
export type CareersNewsListingPageQueryQueryHookResult = ReturnType<typeof useCareersNewsListingPageQueryQuery>;
export type CareersNewsListingPageQueryLazyQueryHookResult = ReturnType<typeof useCareersNewsListingPageQueryLazyQuery>;
export type CareersNewsListingPageQueryQueryResult = Apollo.QueryResult<
  CareersNewsListingPageQueryQuery,
  CareersNewsListingPageQueryQueryVariables
>;
export const CaseStudyQueryDocument = gql`
  query CaseStudyQuery($id: String!) {
    page: kfhContentPageUmbracoType(id: { eq: $id }) {
      pageName
      seoSettings {
        ...SEO
      }
      feed {
        featuredImage {
          ...PageSocialImage
        }
      }
      classification {
        audiences
        services
      }
      layout {
        ... on KFH_ContentCaseStudyType {
          pageHeader {
            ...PageHeader
          }
          regions {
            ...ComponentRegions
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
  ${PageSocialImageFragmentDoc}
  ${PageHeaderFragmentDoc}
  ${ComponentRegionsFragmentDoc}
`;

/**
 * __useCaseStudyQueryQuery__
 *
 * To run a query within a React component, call `useCaseStudyQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseStudyQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseStudyQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseStudyQueryQuery(
  baseOptions: Apollo.QueryHookOptions<CaseStudyQueryQuery, CaseStudyQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CaseStudyQueryQuery, CaseStudyQueryQueryVariables>(CaseStudyQueryDocument, options);
}
export function useCaseStudyQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CaseStudyQueryQuery, CaseStudyQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CaseStudyQueryQuery, CaseStudyQueryQueryVariables>(CaseStudyQueryDocument, options);
}
export type CaseStudyQueryQueryHookResult = ReturnType<typeof useCaseStudyQueryQuery>;
export type CaseStudyQueryLazyQueryHookResult = ReturnType<typeof useCaseStudyQueryLazyQuery>;
export type CaseStudyQueryQueryResult = Apollo.QueryResult<CaseStudyQueryQuery, CaseStudyQueryQueryVariables>;
export const LondonPropertyMattersMagazineQueryDocument = gql`
  query LondonPropertyMattersMagazineQuery($id: String!) {
    page: kfhContentPageUmbracoType(id: { eq: $id }) {
      layout {
        ... on KFH_ContentLondonPropertyMattersMagazineType {
          title
          issueNumber
          snippetCode
          regions {
            ...ComponentRegions
          }
          previousPage {
            pageName
            relativeUrl
          }
          nextPage {
            relativeUrl
            pageName
          }
        }
      }
      classification {
        audiences
        services
      }
      publishDate
      parentPages {
        relativeUrl
        pageName
      }
      seoSettings {
        ...SEO
      }
      feed {
        featuredImage {
          ...PageSocialImage
        }
      }
    }
  }
  ${ComponentRegionsFragmentDoc}
  ${SeoFragmentDoc}
  ${PageSocialImageFragmentDoc}
`;

/**
 * __useLondonPropertyMattersMagazineQueryQuery__
 *
 * To run a query within a React component, call `useLondonPropertyMattersMagazineQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLondonPropertyMattersMagazineQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLondonPropertyMattersMagazineQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLondonPropertyMattersMagazineQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LondonPropertyMattersMagazineQueryQuery,
    LondonPropertyMattersMagazineQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LondonPropertyMattersMagazineQueryQuery, LondonPropertyMattersMagazineQueryQueryVariables>(
    LondonPropertyMattersMagazineQueryDocument,
    options
  );
}
export function useLondonPropertyMattersMagazineQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LondonPropertyMattersMagazineQueryQuery,
    LondonPropertyMattersMagazineQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LondonPropertyMattersMagazineQueryQuery, LondonPropertyMattersMagazineQueryQueryVariables>(
    LondonPropertyMattersMagazineQueryDocument,
    options
  );
}
export type LondonPropertyMattersMagazineQueryQueryHookResult = ReturnType<
  typeof useLondonPropertyMattersMagazineQueryQuery
>;
export type LondonPropertyMattersMagazineQueryLazyQueryHookResult = ReturnType<
  typeof useLondonPropertyMattersMagazineQueryLazyQuery
>;
export type LondonPropertyMattersMagazineQueryQueryResult = Apollo.QueryResult<
  LondonPropertyMattersMagazineQueryQuery,
  LondonPropertyMattersMagazineQueryQueryVariables
>;
export const LondonPropertyMattersMagazineListingPageQueryDocument = gql`
  query LondonPropertyMattersMagazineListingPageQuery($ids: [String], $pageId: String) {
    page: kfhContentPageUmbracoType(id: { eq: $pageId }) {
      remoteTypeName
      remoteId
      pageName
      relativeUrl
      parentId
      id
      seoSettings {
        ...SEO
      }
      feed {
        featuredImage {
          ...PageSocialImage
        }
      }
      classification {
        audiences
        services
      }
      layout {
        ... on KFH_ContentLondonPropertyMattersMagazineListingType {
          title
          leadText
        }
      }
      listingPages(ids: $ids) {
        pageName
        id
        relativeUrl
        feed {
          featuredImage {
            localFile {
              gatsbyImage(
                width: 452
                height: 340
                layout: CONSTRAINED
                placeholder: NONE
                quality: 80
                backgroundColor: "#F5F5F5"
              )
            }
          }
        }
        publishDate
        layout {
          ... on KFH_ContentLondonPropertyMattersMagazineType {
            title
            issueNumber
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
  ${PageSocialImageFragmentDoc}
`;

/**
 * __useLondonPropertyMattersMagazineListingPageQueryQuery__
 *
 * To run a query within a React component, call `useLondonPropertyMattersMagazineListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLondonPropertyMattersMagazineListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLondonPropertyMattersMagazineListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function useLondonPropertyMattersMagazineListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LondonPropertyMattersMagazineListingPageQueryQuery,
    LondonPropertyMattersMagazineListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LondonPropertyMattersMagazineListingPageQueryQuery,
    LondonPropertyMattersMagazineListingPageQueryQueryVariables
  >(LondonPropertyMattersMagazineListingPageQueryDocument, options);
}
export function useLondonPropertyMattersMagazineListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LondonPropertyMattersMagazineListingPageQueryQuery,
    LondonPropertyMattersMagazineListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LondonPropertyMattersMagazineListingPageQueryQuery,
    LondonPropertyMattersMagazineListingPageQueryQueryVariables
  >(LondonPropertyMattersMagazineListingPageQueryDocument, options);
}
export type LondonPropertyMattersMagazineListingPageQueryQueryHookResult = ReturnType<
  typeof useLondonPropertyMattersMagazineListingPageQueryQuery
>;
export type LondonPropertyMattersMagazineListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useLondonPropertyMattersMagazineListingPageQueryLazyQuery
>;
export type LondonPropertyMattersMagazineListingPageQueryQueryResult = Apollo.QueryResult<
  LondonPropertyMattersMagazineListingPageQueryQuery,
  LondonPropertyMattersMagazineListingPageQueryQueryVariables
>;
export const MagazineArticlePageQueryDocument = gql`
  query MagazineArticlePageQuery($id: String!) {
    page: kfhContentPageUmbracoType(id: { eq: $id }) {
      pageName
      seoSettings {
        ...SEO
      }
      feed {
        featuredImage {
          ...PageSocialImage
        }
      }
      classification {
        audiences
        services
      }
      layout {
        ... on KFH_ContentMagazineArticleType {
          pageHeader {
            ...PageHeader
          }
          regions {
            ...ComponentRegions
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
  ${PageSocialImageFragmentDoc}
  ${PageHeaderFragmentDoc}
  ${ComponentRegionsFragmentDoc}
`;

/**
 * __useMagazineArticlePageQueryQuery__
 *
 * To run a query within a React component, call `useMagazineArticlePageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMagazineArticlePageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMagazineArticlePageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMagazineArticlePageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<MagazineArticlePageQueryQuery, MagazineArticlePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MagazineArticlePageQueryQuery, MagazineArticlePageQueryQueryVariables>(
    MagazineArticlePageQueryDocument,
    options
  );
}
export function useMagazineArticlePageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MagazineArticlePageQueryQuery, MagazineArticlePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MagazineArticlePageQueryQuery, MagazineArticlePageQueryQueryVariables>(
    MagazineArticlePageQueryDocument,
    options
  );
}
export type MagazineArticlePageQueryQueryHookResult = ReturnType<typeof useMagazineArticlePageQueryQuery>;
export type MagazineArticlePageQueryLazyQueryHookResult = ReturnType<typeof useMagazineArticlePageQueryLazyQuery>;
export type MagazineArticlePageQueryQueryResult = Apollo.QueryResult<
  MagazineArticlePageQueryQuery,
  MagazineArticlePageQueryQueryVariables
>;
export const MagazineListingPageQueryDocument = gql`
  query MagazineListingPageQuery($ids: [String], $pageId: String) {
    page: kfhContentPageUmbracoType(id: { eq: $pageId }) {
      remoteTypeName
      remoteId
      pageName
      relativeUrl
      parentId
      id
      seoSettings {
        ...SEO
      }
      feed {
        featuredImage {
          ...PageSocialImage
        }
      }
      classification {
        audiences
        services
      }
      layout {
        ... on KFH_ContentMagazineListingType {
          title
          leadText
        }
      }
      listingPages(ids: $ids) {
        pageName
        id
        relativeUrl
        feed {
          featuredImage {
            localFile {
              gatsbyImage(
                width: 452
                height: 340
                layout: CONSTRAINED
                placeholder: NONE
                quality: 80
                backgroundColor: "#F5F5F5"
              )
            }
          }
        }
        publishDate
      }
    }
  }
  ${SeoFragmentDoc}
  ${PageSocialImageFragmentDoc}
`;

/**
 * __useMagazineListingPageQueryQuery__
 *
 * To run a query within a React component, call `useMagazineListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMagazineListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMagazineListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function useMagazineListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<MagazineListingPageQueryQuery, MagazineListingPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MagazineListingPageQueryQuery, MagazineListingPageQueryQueryVariables>(
    MagazineListingPageQueryDocument,
    options
  );
}
export function useMagazineListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MagazineListingPageQueryQuery, MagazineListingPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MagazineListingPageQueryQuery, MagazineListingPageQueryQueryVariables>(
    MagazineListingPageQueryDocument,
    options
  );
}
export type MagazineListingPageQueryQueryHookResult = ReturnType<typeof useMagazineListingPageQueryQuery>;
export type MagazineListingPageQueryLazyQueryHookResult = ReturnType<typeof useMagazineListingPageQueryLazyQuery>;
export type MagazineListingPageQueryQueryResult = Apollo.QueryResult<
  MagazineListingPageQueryQuery,
  MagazineListingPageQueryQueryVariables
>;
export const MarketReportPageQueryDocument = gql`
  query MarketReportPageQuery($id: String!) {
    page: kfhContentPageUmbracoType(id: { eq: $id }) {
      layout {
        ... on KFH_ContentMarketReportType {
          title
          leadText
          year
          season
          pdfDownloadLink
          coverImage {
            localFile {
              gatsbyImage(width: 736, layout: FULL_WIDTH, placeholder: NONE, quality: 80, backgroundColor: "#F5F5F5")
            }
            altText
          }
          regions {
            ...ComponentRegions
          }
          previousPage {
            pageName
            relativeUrl
          }
          nextPage {
            relativeUrl
            pageName
          }
        }
      }
      parentPages {
        relativeUrl
        pageName
      }
      seoSettings {
        ...SEO
      }
      feed {
        featuredImage {
          ...PageSocialImage
        }
      }
      classification {
        audiences
        services
      }
    }
  }
  ${ComponentRegionsFragmentDoc}
  ${SeoFragmentDoc}
  ${PageSocialImageFragmentDoc}
`;

/**
 * __useMarketReportPageQueryQuery__
 *
 * To run a query within a React component, call `useMarketReportPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketReportPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketReportPageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarketReportPageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<MarketReportPageQueryQuery, MarketReportPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MarketReportPageQueryQuery, MarketReportPageQueryQueryVariables>(
    MarketReportPageQueryDocument,
    options
  );
}
export function useMarketReportPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MarketReportPageQueryQuery, MarketReportPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MarketReportPageQueryQuery, MarketReportPageQueryQueryVariables>(
    MarketReportPageQueryDocument,
    options
  );
}
export type MarketReportPageQueryQueryHookResult = ReturnType<typeof useMarketReportPageQueryQuery>;
export type MarketReportPageQueryLazyQueryHookResult = ReturnType<typeof useMarketReportPageQueryLazyQuery>;
export type MarketReportPageQueryQueryResult = Apollo.QueryResult<
  MarketReportPageQueryQuery,
  MarketReportPageQueryQueryVariables
>;
export const MarketReportListingPageQueryDocument = gql`
  query MarketReportListingPageQuery($ids: [String], $pageId: String) {
    page: kfhContentPageUmbracoType(id: { eq: $pageId }) {
      pageName
      seoSettings {
        ...SEO
      }
      feed {
        featuredImage {
          ...PageSocialImage
        }
      }
      classification {
        audiences
        services
      }
      layout {
        ... on KFH_ContentMarketReportListingType {
          title
          leadText
        }
      }
      listingPages(ids: $ids) {
        pageName
        id
        relativeUrl
        layout {
          ... on KFH_ContentMarketReportType {
            title
            leadText
            season
            year
            coverImage {
              localFile {
                gatsbyImage(
                  width: 452
                  height: 340
                  layout: CONSTRAINED
                  placeholder: NONE
                  quality: 80
                  backgroundColor: "#F5F5F5"
                )
              }
            }
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
  ${PageSocialImageFragmentDoc}
`;

/**
 * __useMarketReportListingPageQueryQuery__
 *
 * To run a query within a React component, call `useMarketReportListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketReportListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketReportListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function useMarketReportListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<MarketReportListingPageQueryQuery, MarketReportListingPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MarketReportListingPageQueryQuery, MarketReportListingPageQueryQueryVariables>(
    MarketReportListingPageQueryDocument,
    options
  );
}
export function useMarketReportListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketReportListingPageQueryQuery,
    MarketReportListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MarketReportListingPageQueryQuery, MarketReportListingPageQueryQueryVariables>(
    MarketReportListingPageQueryDocument,
    options
  );
}
export type MarketReportListingPageQueryQueryHookResult = ReturnType<typeof useMarketReportListingPageQueryQuery>;
export type MarketReportListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useMarketReportListingPageQueryLazyQuery
>;
export type MarketReportListingPageQueryQueryResult = Apollo.QueryResult<
  MarketReportListingPageQueryQuery,
  MarketReportListingPageQueryQueryVariables
>;
export const NewsArticlePageQueryDocument = gql`
  query NewsArticlePageQuery($id: String!) {
    page: kfhContentPageUmbracoType(id: { eq: $id }) {
      layout {
        ... on KFH_ContentNewsArticleType {
          title
          pageHeader {
            ...PageHeader
          }
          regions {
            ...ComponentRegions
          }
          wideRegions {
            ...ComponentRegions
          }
          previousPage {
            pageName
            relativeUrl
          }
          nextPage {
            relativeUrl
            pageName
          }
        }
      }
      publishDate
      parentPages {
        relativeUrl
        pageName
      }
      seoSettings {
        ...SEO
      }
      feed {
        featuredImage {
          ...PageSocialImage
        }
      }
      classification {
        audiences
        services
      }
    }
  }
  ${PageHeaderFragmentDoc}
  ${ComponentRegionsFragmentDoc}
  ${SeoFragmentDoc}
  ${PageSocialImageFragmentDoc}
`;

/**
 * __useNewsArticlePageQueryQuery__
 *
 * To run a query within a React component, call `useNewsArticlePageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsArticlePageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsArticlePageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNewsArticlePageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<NewsArticlePageQueryQuery, NewsArticlePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewsArticlePageQueryQuery, NewsArticlePageQueryQueryVariables>(
    NewsArticlePageQueryDocument,
    options
  );
}
export function useNewsArticlePageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NewsArticlePageQueryQuery, NewsArticlePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewsArticlePageQueryQuery, NewsArticlePageQueryQueryVariables>(
    NewsArticlePageQueryDocument,
    options
  );
}
export type NewsArticlePageQueryQueryHookResult = ReturnType<typeof useNewsArticlePageQueryQuery>;
export type NewsArticlePageQueryLazyQueryHookResult = ReturnType<typeof useNewsArticlePageQueryLazyQuery>;
export type NewsArticlePageQueryQueryResult = Apollo.QueryResult<
  NewsArticlePageQueryQuery,
  NewsArticlePageQueryQueryVariables
>;
export const NewsListingPageQueryDocument = gql`
  query NewsListingPageQuery($ids: [String], $pageId: String) {
    page: kfhContentPageUmbracoType(id: { eq: $pageId }) {
      remoteTypeName
      remoteId
      pageName
      relativeUrl
      parentId
      id
      seoSettings {
        ...SEO
      }
      feed {
        featuredImage {
          ...PageSocialImage
        }
      }
      classification {
        audiences
        services
      }
      layout {
        ... on KFH_ContentNewsListingType {
          title
          leadText
        }
      }
      listingPages(ids: $ids) {
        pageName
        id
        relativeUrl
        feed {
          featuredImage {
            localFile {
              gatsbyImage(
                width: 452
                height: 340
                layout: CONSTRAINED
                placeholder: NONE
                quality: 80
                backgroundColor: "#F5F5F5"
              )
            }
          }
        }
        publishDate
        layout {
          ... on KFH_ContentNewsArticleType {
            title
            category
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
  ${PageSocialImageFragmentDoc}
`;

/**
 * __useNewsListingPageQueryQuery__
 *
 * To run a query within a React component, call `useNewsListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function useNewsListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<NewsListingPageQueryQuery, NewsListingPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewsListingPageQueryQuery, NewsListingPageQueryQueryVariables>(
    NewsListingPageQueryDocument,
    options
  );
}
export function useNewsListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NewsListingPageQueryQuery, NewsListingPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewsListingPageQueryQuery, NewsListingPageQueryQueryVariables>(
    NewsListingPageQueryDocument,
    options
  );
}
export type NewsListingPageQueryQueryHookResult = ReturnType<typeof useNewsListingPageQueryQuery>;
export type NewsListingPageQueryLazyQueryHookResult = ReturnType<typeof useNewsListingPageQueryLazyQuery>;
export type NewsListingPageQueryQueryResult = Apollo.QueryResult<
  NewsListingPageQueryQuery,
  NewsListingPageQueryQueryVariables
>;
export const PortfolioQueryDocument = gql`
  query PortfolioQuery($id: String!) {
    page: kfhContentPageUmbracoType(id: { eq: $id }) {
      pageName
      seoSettings {
        ...SEO
      }
      feed {
        featuredImage {
          ...PageSocialImage
        }
      }
      classification {
        audiences
        services
      }
      layout {
        ... on KFH_ContentPortfolioType {
          pageHeader {
            ...PageHeader
          }
          regions {
            ...ComponentRegions
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
  ${PageSocialImageFragmentDoc}
  ${PageHeaderFragmentDoc}
  ${ComponentRegionsFragmentDoc}
`;

/**
 * __usePortfolioQueryQuery__
 *
 * To run a query within a React component, call `usePortfolioQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePortfolioQueryQuery(
  baseOptions: Apollo.QueryHookOptions<PortfolioQueryQuery, PortfolioQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PortfolioQueryQuery, PortfolioQueryQueryVariables>(PortfolioQueryDocument, options);
}
export function usePortfolioQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PortfolioQueryQuery, PortfolioQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PortfolioQueryQuery, PortfolioQueryQueryVariables>(PortfolioQueryDocument, options);
}
export type PortfolioQueryQueryHookResult = ReturnType<typeof usePortfolioQueryQuery>;
export type PortfolioQueryLazyQueryHookResult = ReturnType<typeof usePortfolioQueryLazyQuery>;
export type PortfolioQueryQueryResult = Apollo.QueryResult<PortfolioQueryQuery, PortfolioQueryQueryVariables>;
export const ResourceArticlePageQueryDocument = gql`
  query ResourceArticlePageQuery($id: String!) {
    page: kfhContentPageUmbracoType(id: { eq: $id }) {
      pageName
      id
      layout {
        ... on KFH_ContentResourceArticleType {
          title
          leadText
          regions {
            ...ComponentRegions
          }
          resourceHub {
            pageName
            relativeUrl
            remoteChildren {
              pageName
              relativeUrl
              id
              layout {
                remoteTypeName
              }
              remoteChildren {
                id
                pageName
                relativeUrl
                layout {
                  remoteTypeName
                }
              }
            }
          }
        }
      }
      parentPages {
        relativeUrl
        pageName
      }
      seoSettings {
        ...SEO
      }
      feed {
        featuredImage {
          ...PageSocialImage
        }
      }
      classification {
        audiences
        services
      }
    }

    stampDutyTestPage: kfhContentPageUmbracoType(pageName: { eq: "Stamp Duty calculator test" }) {
      pageName
      id
      relativeUrl
      layout {
        ... on KFH_ContentResourceArticleType {
          title
          leadText
          regions {
            ...ComponentRegions
          }
        }
      }
      parentPages {
        relativeUrl
        pageName
      }
      seoSettings {
        ...SEO
      }
      classification {
        audiences
        services
      }
    }
  }
  ${ComponentRegionsFragmentDoc}
  ${SeoFragmentDoc}
  ${PageSocialImageFragmentDoc}
`;

/**
 * __useResourceArticlePageQueryQuery__
 *
 * To run a query within a React component, call `useResourceArticlePageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceArticlePageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceArticlePageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceArticlePageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<ResourceArticlePageQueryQuery, ResourceArticlePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourceArticlePageQueryQuery, ResourceArticlePageQueryQueryVariables>(
    ResourceArticlePageQueryDocument,
    options
  );
}
export function useResourceArticlePageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ResourceArticlePageQueryQuery, ResourceArticlePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResourceArticlePageQueryQuery, ResourceArticlePageQueryQueryVariables>(
    ResourceArticlePageQueryDocument,
    options
  );
}
export type ResourceArticlePageQueryQueryHookResult = ReturnType<typeof useResourceArticlePageQueryQuery>;
export type ResourceArticlePageQueryLazyQueryHookResult = ReturnType<typeof useResourceArticlePageQueryLazyQuery>;
export type ResourceArticlePageQueryQueryResult = Apollo.QueryResult<
  ResourceArticlePageQueryQuery,
  ResourceArticlePageQueryQueryVariables
>;
export const ServicePageQueryDocument = gql`
  query ServicePageQuery($id: String!) {
    page: kfhContentPageUmbracoType(id: { eq: $id }) {
      pageName
      seoSettings {
        ...SEO
      }
      feed {
        featuredImage {
          ...PageSocialImage
        }
      }
      classification {
        audiences
        services
      }
      layout {
        ... on KFH_ContentServicePageType {
          pageHeader {
            ...PageHeader
          }
          regions {
            ...ComponentRegions
          }
        }
      }
      linkedPages {
        pageName
        relativeUrl
        feed {
          shortDescription
          shortTitle
          featuredImage {
            localFile {
              gatsbyImage(
                width: 520
                height: 544
                layout: CONSTRAINED
                cropFocus: CENTER
                placeholder: NONE
                quality: 80
                backgroundColor: "#F5F5F5"
              )
            }
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
  ${PageSocialImageFragmentDoc}
  ${PageHeaderFragmentDoc}
  ${ComponentRegionsFragmentDoc}
`;

/**
 * __useServicePageQueryQuery__
 *
 * To run a query within a React component, call `useServicePageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicePageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicePageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServicePageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<ServicePageQueryQuery, ServicePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServicePageQueryQuery, ServicePageQueryQueryVariables>(ServicePageQueryDocument, options);
}
export function useServicePageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ServicePageQueryQuery, ServicePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServicePageQueryQuery, ServicePageQueryQueryVariables>(ServicePageQueryDocument, options);
}
export type ServicePageQueryQueryHookResult = ReturnType<typeof useServicePageQueryQuery>;
export type ServicePageQueryLazyQueryHookResult = ReturnType<typeof useServicePageQueryLazyQuery>;
export type ServicePageQueryQueryResult = Apollo.QueryResult<ServicePageQueryQuery, ServicePageQueryQueryVariables>;
export const StandardNarrowPageQueryDocument = gql`
  query StandardNarrowPageQuery($id: String!) {
    page: kfhContentPageUmbracoType(id: { eq: $id }) {
      pageName
      seoSettings {
        ...SEO
      }
      feed {
        featuredImage {
          ...PageSocialImage
        }
      }
      classification {
        audiences
        services
      }
      layout {
        ... on KFH_ContentStandardNarrowType {
          pageHeader {
            ...PageHeader
          }
          regions {
            ...ComponentRegions
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
  ${PageSocialImageFragmentDoc}
  ${PageHeaderFragmentDoc}
  ${ComponentRegionsFragmentDoc}
`;

/**
 * __useStandardNarrowPageQueryQuery__
 *
 * To run a query within a React component, call `useStandardNarrowPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardNarrowPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardNarrowPageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStandardNarrowPageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<StandardNarrowPageQueryQuery, StandardNarrowPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StandardNarrowPageQueryQuery, StandardNarrowPageQueryQueryVariables>(
    StandardNarrowPageQueryDocument,
    options
  );
}
export function useStandardNarrowPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StandardNarrowPageQueryQuery, StandardNarrowPageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StandardNarrowPageQueryQuery, StandardNarrowPageQueryQueryVariables>(
    StandardNarrowPageQueryDocument,
    options
  );
}
export type StandardNarrowPageQueryQueryHookResult = ReturnType<typeof useStandardNarrowPageQueryQuery>;
export type StandardNarrowPageQueryLazyQueryHookResult = ReturnType<typeof useStandardNarrowPageQueryLazyQuery>;
export type StandardNarrowPageQueryQueryResult = Apollo.QueryResult<
  StandardNarrowPageQueryQuery,
  StandardNarrowPageQueryQueryVariables
>;
export const StandardWidePageQueryDocument = gql`
  query StandardWidePageQuery($id: String!) {
    page: kfhContentPageUmbracoType(id: { eq: $id }) {
      pageName
      seoSettings {
        ...SEO
      }
      feed {
        featuredImage {
          ...PageSocialImage
        }
      }
      classification {
        audiences
        services
      }
      layout {
        ... on KFH_ContentStandardWideType {
          pageHeader {
            ...PageHeader
          }
          regions {
            ...ComponentRegions
          }
        }
      }
    }
    buyersTestPage: kfhContentPageUmbracoType(pageName: { eq: "buyers-test" }) {
      pageName
      seoSettings {
        ...SEO
      }
      layout {
        ... on KFH_ContentStandardWideType {
          pageHeader {
            ...PageHeader
          }
          regions {
            ...ComponentRegions
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
  ${PageSocialImageFragmentDoc}
  ${PageHeaderFragmentDoc}
  ${ComponentRegionsFragmentDoc}
`;

/**
 * __useStandardWidePageQueryQuery__
 *
 * To run a query within a React component, call `useStandardWidePageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardWidePageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardWidePageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStandardWidePageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<StandardWidePageQueryQuery, StandardWidePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StandardWidePageQueryQuery, StandardWidePageQueryQueryVariables>(
    StandardWidePageQueryDocument,
    options
  );
}
export function useStandardWidePageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StandardWidePageQueryQuery, StandardWidePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StandardWidePageQueryQuery, StandardWidePageQueryQueryVariables>(
    StandardWidePageQueryDocument,
    options
  );
}
export type StandardWidePageQueryQueryHookResult = ReturnType<typeof useStandardWidePageQueryQuery>;
export type StandardWidePageQueryLazyQueryHookResult = ReturnType<typeof useStandardWidePageQueryLazyQuery>;
export type StandardWidePageQueryQueryResult = Apollo.QueryResult<
  StandardWidePageQueryQuery,
  StandardWidePageQueryQueryVariables
>;
export const CommercialPropertiesListingPageQueryDocument = gql`
  query CommercialPropertiesListingPageQuery($ids: [String]) {
    properties: allKfhCommercialResalePropertyType(filter: { id: { in: $ids } }) {
      nodes {
        ...PropertyListingProperties
      }
    }
    propertiesRental: allKfhCommercialRentalPropertyType(filter: { id: { in: $ids } }) {
      nodes {
        ...PropertyListingProperties
      }
    }
  }
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useCommercialPropertiesListingPageQueryQuery__
 *
 * To run a query within a React component, call `useCommercialPropertiesListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommercialPropertiesListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommercialPropertiesListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCommercialPropertiesListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CommercialPropertiesListingPageQueryQuery,
    CommercialPropertiesListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CommercialPropertiesListingPageQueryQuery, CommercialPropertiesListingPageQueryQueryVariables>(
    CommercialPropertiesListingPageQueryDocument,
    options
  );
}
export function useCommercialPropertiesListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CommercialPropertiesListingPageQueryQuery,
    CommercialPropertiesListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CommercialPropertiesListingPageQueryQuery,
    CommercialPropertiesListingPageQueryQueryVariables
  >(CommercialPropertiesListingPageQueryDocument, options);
}
export type CommercialPropertiesListingPageQueryQueryHookResult = ReturnType<
  typeof useCommercialPropertiesListingPageQueryQuery
>;
export type CommercialPropertiesListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useCommercialPropertiesListingPageQueryLazyQuery
>;
export type CommercialPropertiesListingPageQueryQueryResult = Apollo.QueryResult<
  CommercialPropertiesListingPageQueryQuery,
  CommercialPropertiesListingPageQueryQueryVariables
>;
export const CommercialRentalPropertiesListingPageQueryDocument = gql`
  query CommercialRentalPropertiesListingPageQuery($ids: [String]) {
    properties: allKfhCommercialResalePropertyType(filter: { id: { in: $ids } }) {
      nodes {
        ...PropertyListingProperties
      }
    }
    propertiesRental: allKfhCommercialRentalPropertyType(filter: { id: { in: $ids } }) {
      nodes {
        ...PropertyListingProperties
      }
    }
  }
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useCommercialRentalPropertiesListingPageQueryQuery__
 *
 * To run a query within a React component, call `useCommercialRentalPropertiesListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommercialRentalPropertiesListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommercialRentalPropertiesListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCommercialRentalPropertiesListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CommercialRentalPropertiesListingPageQueryQuery,
    CommercialRentalPropertiesListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CommercialRentalPropertiesListingPageQueryQuery,
    CommercialRentalPropertiesListingPageQueryQueryVariables
  >(CommercialRentalPropertiesListingPageQueryDocument, options);
}
export function useCommercialRentalPropertiesListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CommercialRentalPropertiesListingPageQueryQuery,
    CommercialRentalPropertiesListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CommercialRentalPropertiesListingPageQueryQuery,
    CommercialRentalPropertiesListingPageQueryQueryVariables
  >(CommercialRentalPropertiesListingPageQueryDocument, options);
}
export type CommercialRentalPropertiesListingPageQueryQueryHookResult = ReturnType<
  typeof useCommercialRentalPropertiesListingPageQueryQuery
>;
export type CommercialRentalPropertiesListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useCommercialRentalPropertiesListingPageQueryLazyQuery
>;
export type CommercialRentalPropertiesListingPageQueryQueryResult = Apollo.QueryResult<
  CommercialRentalPropertiesListingPageQueryQuery,
  CommercialRentalPropertiesListingPageQueryQueryVariables
>;
export const DevelopmentPropertiesListingPageQueryDocument = gql`
  query DevelopmentPropertiesListingPageQuery($ids: [String]) {
    properties: allKfhDevelopmentResalePropertyType(filter: { id: { in: $ids } }) {
      edges {
        node {
          ...DevelopmentPropertiesListingProperties
        }
      }
    }
  }
  ${DevelopmentPropertiesListingPropertiesFragmentDoc}
`;

/**
 * __useDevelopmentPropertiesListingPageQueryQuery__
 *
 * To run a query within a React component, call `useDevelopmentPropertiesListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevelopmentPropertiesListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevelopmentPropertiesListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDevelopmentPropertiesListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DevelopmentPropertiesListingPageQueryQuery,
    DevelopmentPropertiesListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DevelopmentPropertiesListingPageQueryQuery,
    DevelopmentPropertiesListingPageQueryQueryVariables
  >(DevelopmentPropertiesListingPageQueryDocument, options);
}
export function useDevelopmentPropertiesListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DevelopmentPropertiesListingPageQueryQuery,
    DevelopmentPropertiesListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DevelopmentPropertiesListingPageQueryQuery,
    DevelopmentPropertiesListingPageQueryQueryVariables
  >(DevelopmentPropertiesListingPageQueryDocument, options);
}
export type DevelopmentPropertiesListingPageQueryQueryHookResult = ReturnType<
  typeof useDevelopmentPropertiesListingPageQueryQuery
>;
export type DevelopmentPropertiesListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useDevelopmentPropertiesListingPageQueryLazyQuery
>;
export type DevelopmentPropertiesListingPageQueryQueryResult = Apollo.QueryResult<
  DevelopmentPropertiesListingPageQueryQuery,
  DevelopmentPropertiesListingPageQueryQueryVariables
>;
export const NewHomePropertyListingPageQueryDocument = gql`
  query NewHomePropertyListingPageQuery($ids: [String]) {
    properties: allKfhPropertyTypeInterface(filter: { id: { in: $ids } }) {
      edges {
        node {
          ...PropertyListingProperties
        }
      }
    }
  }
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useNewHomePropertyListingPageQueryQuery__
 *
 * To run a query within a React component, call `useNewHomePropertyListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewHomePropertyListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewHomePropertyListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useNewHomePropertyListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NewHomePropertyListingPageQueryQuery,
    NewHomePropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewHomePropertyListingPageQueryQuery, NewHomePropertyListingPageQueryQueryVariables>(
    NewHomePropertyListingPageQueryDocument,
    options
  );
}
export function useNewHomePropertyListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewHomePropertyListingPageQueryQuery,
    NewHomePropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewHomePropertyListingPageQueryQuery, NewHomePropertyListingPageQueryQueryVariables>(
    NewHomePropertyListingPageQueryDocument,
    options
  );
}
export type NewHomePropertyListingPageQueryQueryHookResult = ReturnType<typeof useNewHomePropertyListingPageQueryQuery>;
export type NewHomePropertyListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useNewHomePropertyListingPageQueryLazyQuery
>;
export type NewHomePropertyListingPageQueryQueryResult = Apollo.QueryResult<
  NewHomePropertyListingPageQueryQuery,
  NewHomePropertyListingPageQueryQueryVariables
>;
export const PropertyPagePageQueryDocument = gql`
  query PropertyPagePageQuery($id: String!) {
    property: kfhPropertyTypeInterface(id: { eq: $id }) {
      ...PropertyDetails
    }
  }
  ${PropertyDetailsFragmentDoc}
`;

/**
 * __usePropertyPagePageQueryQuery__
 *
 * To run a query within a React component, call `usePropertyPagePageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyPagePageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyPagePageQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertyPagePageQueryQuery(
  baseOptions: Apollo.QueryHookOptions<PropertyPagePageQueryQuery, PropertyPagePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PropertyPagePageQueryQuery, PropertyPagePageQueryQueryVariables>(
    PropertyPagePageQueryDocument,
    options
  );
}
export function usePropertyPagePageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PropertyPagePageQueryQuery, PropertyPagePageQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PropertyPagePageQueryQuery, PropertyPagePageQueryQueryVariables>(
    PropertyPagePageQueryDocument,
    options
  );
}
export type PropertyPagePageQueryQueryHookResult = ReturnType<typeof usePropertyPagePageQueryQuery>;
export type PropertyPagePageQueryLazyQueryHookResult = ReturnType<typeof usePropertyPagePageQueryLazyQuery>;
export type PropertyPagePageQueryQueryResult = Apollo.QueryResult<
  PropertyPagePageQueryQuery,
  PropertyPagePageQueryQueryVariables
>;
export const AreaFlatsForRentPropertyListingPageQueryDocument = gql`
  query AreaFlatsForRentPropertyListingPageQuery($ids: [String], $locationId: String) {
    location: kfhAreaType(id: { eq: $locationId }) {
      artirixMultiSearch
      artirixDisplayName
      name
      listingMeta: flatsForRent {
        propertyEndpointApi
        searchFacets {
          remoteTypeName
          ...SearchFacets
        }
        seo {
          ...SEO
        }
        banners {
          ...PropertyListingBanner
        }
      }
      properties(ids: $ids) {
        ...PropertyListingProperties
      }
    }
  }
  ${SearchFacetsFragmentDoc}
  ${SeoFragmentDoc}
  ${PropertyListingBannerFragmentDoc}
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useAreaFlatsForRentPropertyListingPageQueryQuery__
 *
 * To run a query within a React component, call `useAreaFlatsForRentPropertyListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaFlatsForRentPropertyListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaFlatsForRentPropertyListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useAreaFlatsForRentPropertyListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AreaFlatsForRentPropertyListingPageQueryQuery,
    AreaFlatsForRentPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AreaFlatsForRentPropertyListingPageQueryQuery,
    AreaFlatsForRentPropertyListingPageQueryQueryVariables
  >(AreaFlatsForRentPropertyListingPageQueryDocument, options);
}
export function useAreaFlatsForRentPropertyListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AreaFlatsForRentPropertyListingPageQueryQuery,
    AreaFlatsForRentPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AreaFlatsForRentPropertyListingPageQueryQuery,
    AreaFlatsForRentPropertyListingPageQueryQueryVariables
  >(AreaFlatsForRentPropertyListingPageQueryDocument, options);
}
export type AreaFlatsForRentPropertyListingPageQueryQueryHookResult = ReturnType<
  typeof useAreaFlatsForRentPropertyListingPageQueryQuery
>;
export type AreaFlatsForRentPropertyListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useAreaFlatsForRentPropertyListingPageQueryLazyQuery
>;
export type AreaFlatsForRentPropertyListingPageQueryQueryResult = Apollo.QueryResult<
  AreaFlatsForRentPropertyListingPageQueryQuery,
  AreaFlatsForRentPropertyListingPageQueryQueryVariables
>;
export const AreaFlatsForSalePropertyListingPageQueryDocument = gql`
  query AreaFlatsForSalePropertyListingPageQuery($ids: [String], $locationId: String) {
    location: kfhAreaType(id: { eq: $locationId }) {
      artirixMultiSearch
      artirixDisplayName
      name
      listingMeta: flatsForSale {
        propertyEndpointApi
        searchFacets {
          remoteTypeName
          ...SearchFacets
        }
        seo {
          ...SEO
        }
        banners {
          ...PropertyListingBanner
        }
      }
      properties(ids: $ids) {
        ...PropertyListingProperties
      }
    }
  }
  ${SearchFacetsFragmentDoc}
  ${SeoFragmentDoc}
  ${PropertyListingBannerFragmentDoc}
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useAreaFlatsForSalePropertyListingPageQueryQuery__
 *
 * To run a query within a React component, call `useAreaFlatsForSalePropertyListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaFlatsForSalePropertyListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaFlatsForSalePropertyListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useAreaFlatsForSalePropertyListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AreaFlatsForSalePropertyListingPageQueryQuery,
    AreaFlatsForSalePropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AreaFlatsForSalePropertyListingPageQueryQuery,
    AreaFlatsForSalePropertyListingPageQueryQueryVariables
  >(AreaFlatsForSalePropertyListingPageQueryDocument, options);
}
export function useAreaFlatsForSalePropertyListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AreaFlatsForSalePropertyListingPageQueryQuery,
    AreaFlatsForSalePropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AreaFlatsForSalePropertyListingPageQueryQuery,
    AreaFlatsForSalePropertyListingPageQueryQueryVariables
  >(AreaFlatsForSalePropertyListingPageQueryDocument, options);
}
export type AreaFlatsForSalePropertyListingPageQueryQueryHookResult = ReturnType<
  typeof useAreaFlatsForSalePropertyListingPageQueryQuery
>;
export type AreaFlatsForSalePropertyListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useAreaFlatsForSalePropertyListingPageQueryLazyQuery
>;
export type AreaFlatsForSalePropertyListingPageQueryQueryResult = Apollo.QueryResult<
  AreaFlatsForSalePropertyListingPageQueryQuery,
  AreaFlatsForSalePropertyListingPageQueryQueryVariables
>;
export const AreaHousesForRentPropertyListingPageQueryDocument = gql`
  query AreaHousesForRentPropertyListingPageQuery($ids: [String], $locationId: String) {
    location: kfhAreaType(id: { eq: $locationId }) {
      artirixMultiSearch
      artirixDisplayName
      name
      listingMeta: housesForRent {
        propertyEndpointApi
        searchFacets {
          remoteTypeName
          ...SearchFacets
        }
        seo {
          ...SEO
        }
        banners {
          ...PropertyListingBanner
        }
      }
      properties(ids: $ids) {
        ...PropertyListingProperties
      }
    }
  }
  ${SearchFacetsFragmentDoc}
  ${SeoFragmentDoc}
  ${PropertyListingBannerFragmentDoc}
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useAreaHousesForRentPropertyListingPageQueryQuery__
 *
 * To run a query within a React component, call `useAreaHousesForRentPropertyListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaHousesForRentPropertyListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaHousesForRentPropertyListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useAreaHousesForRentPropertyListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AreaHousesForRentPropertyListingPageQueryQuery,
    AreaHousesForRentPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AreaHousesForRentPropertyListingPageQueryQuery,
    AreaHousesForRentPropertyListingPageQueryQueryVariables
  >(AreaHousesForRentPropertyListingPageQueryDocument, options);
}
export function useAreaHousesForRentPropertyListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AreaHousesForRentPropertyListingPageQueryQuery,
    AreaHousesForRentPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AreaHousesForRentPropertyListingPageQueryQuery,
    AreaHousesForRentPropertyListingPageQueryQueryVariables
  >(AreaHousesForRentPropertyListingPageQueryDocument, options);
}
export type AreaHousesForRentPropertyListingPageQueryQueryHookResult = ReturnType<
  typeof useAreaHousesForRentPropertyListingPageQueryQuery
>;
export type AreaHousesForRentPropertyListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useAreaHousesForRentPropertyListingPageQueryLazyQuery
>;
export type AreaHousesForRentPropertyListingPageQueryQueryResult = Apollo.QueryResult<
  AreaHousesForRentPropertyListingPageQueryQuery,
  AreaHousesForRentPropertyListingPageQueryQueryVariables
>;
export const AreaHousesForSalePropertyListingPageQueryDocument = gql`
  query AreaHousesForSalePropertyListingPageQuery($ids: [String], $locationId: String) {
    location: kfhAreaType(id: { eq: $locationId }) {
      artirixMultiSearch
      artirixDisplayName
      name
      listingMeta: housesForSale {
        propertyEndpointApi
        searchFacets {
          remoteTypeName
          ...SearchFacets
        }
        seo {
          ...SEO
        }
        banners {
          ...PropertyListingBanner
        }
      }
      properties(ids: $ids) {
        ...PropertyListingProperties
      }
    }
  }
  ${SearchFacetsFragmentDoc}
  ${SeoFragmentDoc}
  ${PropertyListingBannerFragmentDoc}
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useAreaHousesForSalePropertyListingPageQueryQuery__
 *
 * To run a query within a React component, call `useAreaHousesForSalePropertyListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaHousesForSalePropertyListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaHousesForSalePropertyListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useAreaHousesForSalePropertyListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AreaHousesForSalePropertyListingPageQueryQuery,
    AreaHousesForSalePropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AreaHousesForSalePropertyListingPageQueryQuery,
    AreaHousesForSalePropertyListingPageQueryQueryVariables
  >(AreaHousesForSalePropertyListingPageQueryDocument, options);
}
export function useAreaHousesForSalePropertyListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AreaHousesForSalePropertyListingPageQueryQuery,
    AreaHousesForSalePropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AreaHousesForSalePropertyListingPageQueryQuery,
    AreaHousesForSalePropertyListingPageQueryQueryVariables
  >(AreaHousesForSalePropertyListingPageQueryDocument, options);
}
export type AreaHousesForSalePropertyListingPageQueryQueryHookResult = ReturnType<
  typeof useAreaHousesForSalePropertyListingPageQueryQuery
>;
export type AreaHousesForSalePropertyListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useAreaHousesForSalePropertyListingPageQueryLazyQuery
>;
export type AreaHousesForSalePropertyListingPageQueryQueryResult = Apollo.QueryResult<
  AreaHousesForSalePropertyListingPageQueryQuery,
  AreaHousesForSalePropertyListingPageQueryQueryVariables
>;
export const AreaPropertiesForRentPropertyListingPageQueryDocument = gql`
  query AreaPropertiesForRentPropertyListingPageQuery($ids: [String], $locationId: String) {
    location: kfhAreaType(id: { eq: $locationId }) {
      artirixMultiSearch
      artirixDisplayName
      name
      listingMeta: propertiesForRent {
        propertyEndpointApi
        searchFacets {
          remoteTypeName
          ...SearchFacets
        }
        seo {
          ...SEO
        }
        banners {
          ...PropertyListingBanner
        }
      }
      properties(ids: $ids) {
        ...PropertyListingProperties
      }
    }
  }
  ${SearchFacetsFragmentDoc}
  ${SeoFragmentDoc}
  ${PropertyListingBannerFragmentDoc}
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useAreaPropertiesForRentPropertyListingPageQueryQuery__
 *
 * To run a query within a React component, call `useAreaPropertiesForRentPropertyListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaPropertiesForRentPropertyListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaPropertiesForRentPropertyListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useAreaPropertiesForRentPropertyListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AreaPropertiesForRentPropertyListingPageQueryQuery,
    AreaPropertiesForRentPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AreaPropertiesForRentPropertyListingPageQueryQuery,
    AreaPropertiesForRentPropertyListingPageQueryQueryVariables
  >(AreaPropertiesForRentPropertyListingPageQueryDocument, options);
}
export function useAreaPropertiesForRentPropertyListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AreaPropertiesForRentPropertyListingPageQueryQuery,
    AreaPropertiesForRentPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AreaPropertiesForRentPropertyListingPageQueryQuery,
    AreaPropertiesForRentPropertyListingPageQueryQueryVariables
  >(AreaPropertiesForRentPropertyListingPageQueryDocument, options);
}
export type AreaPropertiesForRentPropertyListingPageQueryQueryHookResult = ReturnType<
  typeof useAreaPropertiesForRentPropertyListingPageQueryQuery
>;
export type AreaPropertiesForRentPropertyListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useAreaPropertiesForRentPropertyListingPageQueryLazyQuery
>;
export type AreaPropertiesForRentPropertyListingPageQueryQueryResult = Apollo.QueryResult<
  AreaPropertiesForRentPropertyListingPageQueryQuery,
  AreaPropertiesForRentPropertyListingPageQueryQueryVariables
>;
export const AreaPropertiesForSalePropertyListingPageQueryDocument = gql`
  query AreaPropertiesForSalePropertyListingPageQuery($ids: [String], $locationId: String) {
    location: kfhAreaType(id: { eq: $locationId }) {
      artirixMultiSearch
      artirixDisplayName
      name
      listingMeta: propertiesForSale {
        propertyEndpointApi
        searchFacets {
          remoteTypeName
          ...SearchFacets
        }
        seo {
          ...SEO
        }
        banners {
          ...PropertyListingBanner
        }
      }
      properties(ids: $ids) {
        ...PropertyListingProperties
      }
    }
  }
  ${SearchFacetsFragmentDoc}
  ${SeoFragmentDoc}
  ${PropertyListingBannerFragmentDoc}
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useAreaPropertiesForSalePropertyListingPageQueryQuery__
 *
 * To run a query within a React component, call `useAreaPropertiesForSalePropertyListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaPropertiesForSalePropertyListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaPropertiesForSalePropertyListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useAreaPropertiesForSalePropertyListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AreaPropertiesForSalePropertyListingPageQueryQuery,
    AreaPropertiesForSalePropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AreaPropertiesForSalePropertyListingPageQueryQuery,
    AreaPropertiesForSalePropertyListingPageQueryQueryVariables
  >(AreaPropertiesForSalePropertyListingPageQueryDocument, options);
}
export function useAreaPropertiesForSalePropertyListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AreaPropertiesForSalePropertyListingPageQueryQuery,
    AreaPropertiesForSalePropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AreaPropertiesForSalePropertyListingPageQueryQuery,
    AreaPropertiesForSalePropertyListingPageQueryQueryVariables
  >(AreaPropertiesForSalePropertyListingPageQueryDocument, options);
}
export type AreaPropertiesForSalePropertyListingPageQueryQueryHookResult = ReturnType<
  typeof useAreaPropertiesForSalePropertyListingPageQueryQuery
>;
export type AreaPropertiesForSalePropertyListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useAreaPropertiesForSalePropertyListingPageQueryLazyQuery
>;
export type AreaPropertiesForSalePropertyListingPageQueryQueryResult = Apollo.QueryResult<
  AreaPropertiesForSalePropertyListingPageQueryQuery,
  AreaPropertiesForSalePropertyListingPageQueryQueryVariables
>;
export const AreaPropertiesLetPropertyListingPageQueryDocument = gql`
  query AreaPropertiesLetPropertyListingPageQuery($ids: [String], $locationId: String) {
    location: kfhAreaType(id: { eq: $locationId }) {
      artirixMultiSearch
      artirixDisplayName
      name
      listingMeta: propertiesLet {
        propertyEndpointApi
        searchFacets {
          remoteTypeName
          ...SearchFacets
        }
        seo {
          ...SEO
        }
        banners {
          ...PropertyListingBanner
        }
      }
      properties(ids: $ids) {
        ...PropertyListingProperties
      }
    }
  }
  ${SearchFacetsFragmentDoc}
  ${SeoFragmentDoc}
  ${PropertyListingBannerFragmentDoc}
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useAreaPropertiesLetPropertyListingPageQueryQuery__
 *
 * To run a query within a React component, call `useAreaPropertiesLetPropertyListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaPropertiesLetPropertyListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaPropertiesLetPropertyListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useAreaPropertiesLetPropertyListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AreaPropertiesLetPropertyListingPageQueryQuery,
    AreaPropertiesLetPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AreaPropertiesLetPropertyListingPageQueryQuery,
    AreaPropertiesLetPropertyListingPageQueryQueryVariables
  >(AreaPropertiesLetPropertyListingPageQueryDocument, options);
}
export function useAreaPropertiesLetPropertyListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AreaPropertiesLetPropertyListingPageQueryQuery,
    AreaPropertiesLetPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AreaPropertiesLetPropertyListingPageQueryQuery,
    AreaPropertiesLetPropertyListingPageQueryQueryVariables
  >(AreaPropertiesLetPropertyListingPageQueryDocument, options);
}
export type AreaPropertiesLetPropertyListingPageQueryQueryHookResult = ReturnType<
  typeof useAreaPropertiesLetPropertyListingPageQueryQuery
>;
export type AreaPropertiesLetPropertyListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useAreaPropertiesLetPropertyListingPageQueryLazyQuery
>;
export type AreaPropertiesLetPropertyListingPageQueryQueryResult = Apollo.QueryResult<
  AreaPropertiesLetPropertyListingPageQueryQuery,
  AreaPropertiesLetPropertyListingPageQueryQueryVariables
>;
export const AreaPropertiesSoldPropertyListingPageQueryDocument = gql`
  query AreaPropertiesSoldPropertyListingPageQuery($ids: [String], $locationId: String) {
    location: kfhAreaType(id: { eq: $locationId }) {
      artirixMultiSearch
      artirixDisplayName
      name
      listingMeta: propertiesSold {
        propertyEndpointApi
        searchFacets {
          remoteTypeName
          ...SearchFacets
        }
        seo {
          ...SEO
        }
        banners {
          ...PropertyListingBanner
        }
      }
      properties(ids: $ids) {
        ...PropertyListingProperties
      }
    }
  }
  ${SearchFacetsFragmentDoc}
  ${SeoFragmentDoc}
  ${PropertyListingBannerFragmentDoc}
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useAreaPropertiesSoldPropertyListingPageQueryQuery__
 *
 * To run a query within a React component, call `useAreaPropertiesSoldPropertyListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaPropertiesSoldPropertyListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaPropertiesSoldPropertyListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useAreaPropertiesSoldPropertyListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AreaPropertiesSoldPropertyListingPageQueryQuery,
    AreaPropertiesSoldPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AreaPropertiesSoldPropertyListingPageQueryQuery,
    AreaPropertiesSoldPropertyListingPageQueryQueryVariables
  >(AreaPropertiesSoldPropertyListingPageQueryDocument, options);
}
export function useAreaPropertiesSoldPropertyListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AreaPropertiesSoldPropertyListingPageQueryQuery,
    AreaPropertiesSoldPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AreaPropertiesSoldPropertyListingPageQueryQuery,
    AreaPropertiesSoldPropertyListingPageQueryQueryVariables
  >(AreaPropertiesSoldPropertyListingPageQueryDocument, options);
}
export type AreaPropertiesSoldPropertyListingPageQueryQueryHookResult = ReturnType<
  typeof useAreaPropertiesSoldPropertyListingPageQueryQuery
>;
export type AreaPropertiesSoldPropertyListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useAreaPropertiesSoldPropertyListingPageQueryLazyQuery
>;
export type AreaPropertiesSoldPropertyListingPageQueryQueryResult = Apollo.QueryResult<
  AreaPropertiesSoldPropertyListingPageQueryQuery,
  AreaPropertiesSoldPropertyListingPageQueryQueryVariables
>;
export const RegionFlatsForRentPropertyListingPageQueryDocument = gql`
  query RegionFlatsForRentPropertyListingPageQuery($ids: [String], $locationId: String) {
    location: kfhRegionType(id: { eq: $locationId }) {
      artirixMultiSearch
      artirixDisplayName
      name
      listingMeta: flatsForRent {
        propertyEndpointApi
        searchFacets {
          remoteTypeName
          ...SearchFacets
        }
        seo {
          ...SEO
        }
        banners {
          ...PropertyListingBanner
        }
      }
      properties(ids: $ids) {
        ...PropertyListingProperties
      }
    }
  }
  ${SearchFacetsFragmentDoc}
  ${SeoFragmentDoc}
  ${PropertyListingBannerFragmentDoc}
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useRegionFlatsForRentPropertyListingPageQueryQuery__
 *
 * To run a query within a React component, call `useRegionFlatsForRentPropertyListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionFlatsForRentPropertyListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionFlatsForRentPropertyListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useRegionFlatsForRentPropertyListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegionFlatsForRentPropertyListingPageQueryQuery,
    RegionFlatsForRentPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegionFlatsForRentPropertyListingPageQueryQuery,
    RegionFlatsForRentPropertyListingPageQueryQueryVariables
  >(RegionFlatsForRentPropertyListingPageQueryDocument, options);
}
export function useRegionFlatsForRentPropertyListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegionFlatsForRentPropertyListingPageQueryQuery,
    RegionFlatsForRentPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegionFlatsForRentPropertyListingPageQueryQuery,
    RegionFlatsForRentPropertyListingPageQueryQueryVariables
  >(RegionFlatsForRentPropertyListingPageQueryDocument, options);
}
export type RegionFlatsForRentPropertyListingPageQueryQueryHookResult = ReturnType<
  typeof useRegionFlatsForRentPropertyListingPageQueryQuery
>;
export type RegionFlatsForRentPropertyListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useRegionFlatsForRentPropertyListingPageQueryLazyQuery
>;
export type RegionFlatsForRentPropertyListingPageQueryQueryResult = Apollo.QueryResult<
  RegionFlatsForRentPropertyListingPageQueryQuery,
  RegionFlatsForRentPropertyListingPageQueryQueryVariables
>;
export const RegionFlatsForSalePropertyListingPageQueryDocument = gql`
  query RegionFlatsForSalePropertyListingPageQuery($ids: [String], $locationId: String) {
    location: kfhRegionType(id: { eq: $locationId }) {
      artirixMultiSearch
      artirixDisplayName
      name
      listingMeta: flatsForSale {
        propertyEndpointApi
        searchFacets {
          remoteTypeName
          ...SearchFacets
        }
        seo {
          ...SEO
        }
        banners {
          ...PropertyListingBanner
        }
      }
      properties(ids: $ids) {
        ...PropertyListingProperties
      }
    }
  }
  ${SearchFacetsFragmentDoc}
  ${SeoFragmentDoc}
  ${PropertyListingBannerFragmentDoc}
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useRegionFlatsForSalePropertyListingPageQueryQuery__
 *
 * To run a query within a React component, call `useRegionFlatsForSalePropertyListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionFlatsForSalePropertyListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionFlatsForSalePropertyListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useRegionFlatsForSalePropertyListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegionFlatsForSalePropertyListingPageQueryQuery,
    RegionFlatsForSalePropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegionFlatsForSalePropertyListingPageQueryQuery,
    RegionFlatsForSalePropertyListingPageQueryQueryVariables
  >(RegionFlatsForSalePropertyListingPageQueryDocument, options);
}
export function useRegionFlatsForSalePropertyListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegionFlatsForSalePropertyListingPageQueryQuery,
    RegionFlatsForSalePropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegionFlatsForSalePropertyListingPageQueryQuery,
    RegionFlatsForSalePropertyListingPageQueryQueryVariables
  >(RegionFlatsForSalePropertyListingPageQueryDocument, options);
}
export type RegionFlatsForSalePropertyListingPageQueryQueryHookResult = ReturnType<
  typeof useRegionFlatsForSalePropertyListingPageQueryQuery
>;
export type RegionFlatsForSalePropertyListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useRegionFlatsForSalePropertyListingPageQueryLazyQuery
>;
export type RegionFlatsForSalePropertyListingPageQueryQueryResult = Apollo.QueryResult<
  RegionFlatsForSalePropertyListingPageQueryQuery,
  RegionFlatsForSalePropertyListingPageQueryQueryVariables
>;
export const RegionHousesForRentPropertyListingPageQueryDocument = gql`
  query RegionHousesForRentPropertyListingPageQuery($ids: [String], $locationId: String) {
    location: kfhRegionType(id: { eq: $locationId }) {
      artirixMultiSearch
      artirixDisplayName
      name
      listingMeta: housesForRent {
        propertyEndpointApi
        searchFacets {
          remoteTypeName
          ...SearchFacets
        }
        seo {
          ...SEO
        }
        banners {
          ...PropertyListingBanner
        }
      }
      properties(ids: $ids) {
        ...PropertyListingProperties
      }
    }
  }
  ${SearchFacetsFragmentDoc}
  ${SeoFragmentDoc}
  ${PropertyListingBannerFragmentDoc}
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useRegionHousesForRentPropertyListingPageQueryQuery__
 *
 * To run a query within a React component, call `useRegionHousesForRentPropertyListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionHousesForRentPropertyListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionHousesForRentPropertyListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useRegionHousesForRentPropertyListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegionHousesForRentPropertyListingPageQueryQuery,
    RegionHousesForRentPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegionHousesForRentPropertyListingPageQueryQuery,
    RegionHousesForRentPropertyListingPageQueryQueryVariables
  >(RegionHousesForRentPropertyListingPageQueryDocument, options);
}
export function useRegionHousesForRentPropertyListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegionHousesForRentPropertyListingPageQueryQuery,
    RegionHousesForRentPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegionHousesForRentPropertyListingPageQueryQuery,
    RegionHousesForRentPropertyListingPageQueryQueryVariables
  >(RegionHousesForRentPropertyListingPageQueryDocument, options);
}
export type RegionHousesForRentPropertyListingPageQueryQueryHookResult = ReturnType<
  typeof useRegionHousesForRentPropertyListingPageQueryQuery
>;
export type RegionHousesForRentPropertyListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useRegionHousesForRentPropertyListingPageQueryLazyQuery
>;
export type RegionHousesForRentPropertyListingPageQueryQueryResult = Apollo.QueryResult<
  RegionHousesForRentPropertyListingPageQueryQuery,
  RegionHousesForRentPropertyListingPageQueryQueryVariables
>;
export const RegionHousesForSalePropertyListingPageQueryDocument = gql`
  query RegionHousesForSalePropertyListingPageQuery($ids: [String], $locationId: String) {
    location: kfhRegionType(id: { eq: $locationId }) {
      artirixMultiSearch
      artirixDisplayName
      name
      listingMeta: housesForSale {
        propertyEndpointApi
        searchFacets {
          remoteTypeName
          ...SearchFacets
        }
        seo {
          ...SEO
        }
        banners {
          ...PropertyListingBanner
        }
      }
      properties(ids: $ids) {
        ...PropertyListingProperties
      }
    }
  }
  ${SearchFacetsFragmentDoc}
  ${SeoFragmentDoc}
  ${PropertyListingBannerFragmentDoc}
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useRegionHousesForSalePropertyListingPageQueryQuery__
 *
 * To run a query within a React component, call `useRegionHousesForSalePropertyListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionHousesForSalePropertyListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionHousesForSalePropertyListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useRegionHousesForSalePropertyListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegionHousesForSalePropertyListingPageQueryQuery,
    RegionHousesForSalePropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegionHousesForSalePropertyListingPageQueryQuery,
    RegionHousesForSalePropertyListingPageQueryQueryVariables
  >(RegionHousesForSalePropertyListingPageQueryDocument, options);
}
export function useRegionHousesForSalePropertyListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegionHousesForSalePropertyListingPageQueryQuery,
    RegionHousesForSalePropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegionHousesForSalePropertyListingPageQueryQuery,
    RegionHousesForSalePropertyListingPageQueryQueryVariables
  >(RegionHousesForSalePropertyListingPageQueryDocument, options);
}
export type RegionHousesForSalePropertyListingPageQueryQueryHookResult = ReturnType<
  typeof useRegionHousesForSalePropertyListingPageQueryQuery
>;
export type RegionHousesForSalePropertyListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useRegionHousesForSalePropertyListingPageQueryLazyQuery
>;
export type RegionHousesForSalePropertyListingPageQueryQueryResult = Apollo.QueryResult<
  RegionHousesForSalePropertyListingPageQueryQuery,
  RegionHousesForSalePropertyListingPageQueryQueryVariables
>;
export const RegionPropertiesForRentPropertyListingPageQueryDocument = gql`
  query RegionPropertiesForRentPropertyListingPageQuery($ids: [String], $locationId: String) {
    location: kfhRegionType(id: { eq: $locationId }) {
      artirixMultiSearch
      artirixDisplayName
      name
      listingMeta: propertiesForRent {
        propertyEndpointApi
        searchFacets {
          remoteTypeName
          ...SearchFacets
        }
        seo {
          ...SEO
        }
        banners {
          ...PropertyListingBanner
        }
      }
      properties(ids: $ids) {
        ...PropertyListingProperties
      }
    }
  }
  ${SearchFacetsFragmentDoc}
  ${SeoFragmentDoc}
  ${PropertyListingBannerFragmentDoc}
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useRegionPropertiesForRentPropertyListingPageQueryQuery__
 *
 * To run a query within a React component, call `useRegionPropertiesForRentPropertyListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionPropertiesForRentPropertyListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionPropertiesForRentPropertyListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useRegionPropertiesForRentPropertyListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegionPropertiesForRentPropertyListingPageQueryQuery,
    RegionPropertiesForRentPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegionPropertiesForRentPropertyListingPageQueryQuery,
    RegionPropertiesForRentPropertyListingPageQueryQueryVariables
  >(RegionPropertiesForRentPropertyListingPageQueryDocument, options);
}
export function useRegionPropertiesForRentPropertyListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegionPropertiesForRentPropertyListingPageQueryQuery,
    RegionPropertiesForRentPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegionPropertiesForRentPropertyListingPageQueryQuery,
    RegionPropertiesForRentPropertyListingPageQueryQueryVariables
  >(RegionPropertiesForRentPropertyListingPageQueryDocument, options);
}
export type RegionPropertiesForRentPropertyListingPageQueryQueryHookResult = ReturnType<
  typeof useRegionPropertiesForRentPropertyListingPageQueryQuery
>;
export type RegionPropertiesForRentPropertyListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useRegionPropertiesForRentPropertyListingPageQueryLazyQuery
>;
export type RegionPropertiesForRentPropertyListingPageQueryQueryResult = Apollo.QueryResult<
  RegionPropertiesForRentPropertyListingPageQueryQuery,
  RegionPropertiesForRentPropertyListingPageQueryQueryVariables
>;
export const RegionPropertiesForSalePropertyListingPageQueryDocument = gql`
  query RegionPropertiesForSalePropertyListingPageQuery($ids: [String], $locationId: String) {
    location: kfhRegionType(id: { eq: $locationId }) {
      artirixMultiSearch
      artirixDisplayName
      name
      listingMeta: propertiesForSale {
        propertyEndpointApi
        searchFacets {
          remoteTypeName
          ...SearchFacets
        }
        seo {
          ...SEO
        }
        banners {
          ...PropertyListingBanner
        }
      }
      properties(ids: $ids) {
        ...PropertyListingProperties
      }
    }
  }
  ${SearchFacetsFragmentDoc}
  ${SeoFragmentDoc}
  ${PropertyListingBannerFragmentDoc}
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useRegionPropertiesForSalePropertyListingPageQueryQuery__
 *
 * To run a query within a React component, call `useRegionPropertiesForSalePropertyListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionPropertiesForSalePropertyListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionPropertiesForSalePropertyListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useRegionPropertiesForSalePropertyListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegionPropertiesForSalePropertyListingPageQueryQuery,
    RegionPropertiesForSalePropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegionPropertiesForSalePropertyListingPageQueryQuery,
    RegionPropertiesForSalePropertyListingPageQueryQueryVariables
  >(RegionPropertiesForSalePropertyListingPageQueryDocument, options);
}
export function useRegionPropertiesForSalePropertyListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegionPropertiesForSalePropertyListingPageQueryQuery,
    RegionPropertiesForSalePropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegionPropertiesForSalePropertyListingPageQueryQuery,
    RegionPropertiesForSalePropertyListingPageQueryQueryVariables
  >(RegionPropertiesForSalePropertyListingPageQueryDocument, options);
}
export type RegionPropertiesForSalePropertyListingPageQueryQueryHookResult = ReturnType<
  typeof useRegionPropertiesForSalePropertyListingPageQueryQuery
>;
export type RegionPropertiesForSalePropertyListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useRegionPropertiesForSalePropertyListingPageQueryLazyQuery
>;
export type RegionPropertiesForSalePropertyListingPageQueryQueryResult = Apollo.QueryResult<
  RegionPropertiesForSalePropertyListingPageQueryQuery,
  RegionPropertiesForSalePropertyListingPageQueryQueryVariables
>;
export const RegionPropertiesLetPropertyListingPageQueryDocument = gql`
  query RegionPropertiesLetPropertyListingPageQuery($ids: [String], $locationId: String) {
    location: kfhRegionType(id: { eq: $locationId }) {
      artirixMultiSearch
      artirixDisplayName
      name
      listingMeta: propertiesLet {
        propertyEndpointApi
        searchFacets {
          remoteTypeName
          ...SearchFacets
        }
        seo {
          ...SEO
        }
        banners {
          ...PropertyListingBanner
        }
      }
      properties(ids: $ids) {
        ...PropertyListingProperties
      }
    }
  }
  ${SearchFacetsFragmentDoc}
  ${SeoFragmentDoc}
  ${PropertyListingBannerFragmentDoc}
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useRegionPropertiesLetPropertyListingPageQueryQuery__
 *
 * To run a query within a React component, call `useRegionPropertiesLetPropertyListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionPropertiesLetPropertyListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionPropertiesLetPropertyListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useRegionPropertiesLetPropertyListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegionPropertiesLetPropertyListingPageQueryQuery,
    RegionPropertiesLetPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegionPropertiesLetPropertyListingPageQueryQuery,
    RegionPropertiesLetPropertyListingPageQueryQueryVariables
  >(RegionPropertiesLetPropertyListingPageQueryDocument, options);
}
export function useRegionPropertiesLetPropertyListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegionPropertiesLetPropertyListingPageQueryQuery,
    RegionPropertiesLetPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegionPropertiesLetPropertyListingPageQueryQuery,
    RegionPropertiesLetPropertyListingPageQueryQueryVariables
  >(RegionPropertiesLetPropertyListingPageQueryDocument, options);
}
export type RegionPropertiesLetPropertyListingPageQueryQueryHookResult = ReturnType<
  typeof useRegionPropertiesLetPropertyListingPageQueryQuery
>;
export type RegionPropertiesLetPropertyListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useRegionPropertiesLetPropertyListingPageQueryLazyQuery
>;
export type RegionPropertiesLetPropertyListingPageQueryQueryResult = Apollo.QueryResult<
  RegionPropertiesLetPropertyListingPageQueryQuery,
  RegionPropertiesLetPropertyListingPageQueryQueryVariables
>;
export const RegionPropertiesSoldPropertyListingPageQueryDocument = gql`
  query RegionPropertiesSoldPropertyListingPageQuery($ids: [String], $locationId: String) {
    location: kfhRegionType(id: { eq: $locationId }) {
      artirixMultiSearch
      artirixDisplayName
      name
      listingMeta: propertiesSold {
        propertyEndpointApi
        searchFacets {
          remoteTypeName
          ...SearchFacets
        }
        seo {
          ...SEO
        }
        banners {
          ...PropertyListingBanner
        }
      }
      properties(ids: $ids) {
        ...PropertyListingProperties
      }
    }
  }
  ${SearchFacetsFragmentDoc}
  ${SeoFragmentDoc}
  ${PropertyListingBannerFragmentDoc}
  ${PropertyListingPropertiesFragmentDoc}
`;

/**
 * __useRegionPropertiesSoldPropertyListingPageQueryQuery__
 *
 * To run a query within a React component, call `useRegionPropertiesSoldPropertyListingPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionPropertiesSoldPropertyListingPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionPropertiesSoldPropertyListingPageQueryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useRegionPropertiesSoldPropertyListingPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegionPropertiesSoldPropertyListingPageQueryQuery,
    RegionPropertiesSoldPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegionPropertiesSoldPropertyListingPageQueryQuery,
    RegionPropertiesSoldPropertyListingPageQueryQueryVariables
  >(RegionPropertiesSoldPropertyListingPageQueryDocument, options);
}
export function useRegionPropertiesSoldPropertyListingPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegionPropertiesSoldPropertyListingPageQueryQuery,
    RegionPropertiesSoldPropertyListingPageQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegionPropertiesSoldPropertyListingPageQueryQuery,
    RegionPropertiesSoldPropertyListingPageQueryQueryVariables
  >(RegionPropertiesSoldPropertyListingPageQueryDocument, options);
}
export type RegionPropertiesSoldPropertyListingPageQueryQueryHookResult = ReturnType<
  typeof useRegionPropertiesSoldPropertyListingPageQueryQuery
>;
export type RegionPropertiesSoldPropertyListingPageQueryLazyQueryHookResult = ReturnType<
  typeof useRegionPropertiesSoldPropertyListingPageQueryLazyQuery
>;
export type RegionPropertiesSoldPropertyListingPageQueryQueryResult = Apollo.QueryResult<
  RegionPropertiesSoldPropertyListingPageQueryQuery,
  RegionPropertiesSoldPropertyListingPageQueryQueryVariables
>;
